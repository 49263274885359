import React from 'react';

import AnimatedComponent from '@/components/Animation';
import { IAdvantages } from '@/types/animatedPromo/item';

export interface IProps {
    advantages: IAdvantages[];
}

const AnimatedPromoSlideAdvantages: React.FC<IProps> = ({ advantages }) => (
    <AnimatedComponent>
        <div className="animatedPromoSlideAdvantagesRow">
            {advantages.map(({ title, subtitle }, index) => (
                <div className="animatedPromoSlideAdvantagesItem" key={index}>
                    <div className="animatedPromoSlideAdvantagesTitle" dangerouslySetInnerHTML={{ __html: title }} />
                    <div
                        className="animatedPromoSlideAdvantagesSubtitle"
                        dangerouslySetInnerHTML={{ __html: subtitle }}
                    />
                </div>
            ))}
        </div>
    </AnimatedComponent>
);

export default AnimatedPromoSlideAdvantages;
