import clsx from 'clsx';
import React, { FC, useEffect, useMemo, useState } from 'react';

import AnimationImage from '@/components/blocks/animatedPromo/AnimatedPromoImage/animation';
import AnimatedPromoCircle from '@/components/blocks/animatedPromo/AnimatedPromoImage/circle';
import CustomImage from '@/components/CustomImage';
import { useResize } from '@/hooks/useResize';
import { MOBILE_WIDTH } from '@/utils/constants';

interface IProps {
    animationImage?: string;
    withAnimationImage: boolean;
    image: string;
    mobileImage?: string;
    imageTopMv?: boolean;
    imageBottomMv?: boolean;
    imageBig?: boolean;
    isAnimate: boolean;
    animationType: string;
    animationImageWebm: string;
    isActive: boolean;
    bigSize?: boolean;
    priority?: boolean;
}

const AnimatedPromoImage: FC<IProps> = ({
    image,
    mobileImage,
    imageTopMv = false,
    imageBottomMv = false,
    imageBig = false,
    isAnimate,
    isActive,
    animationType,
    animationImage,
    animationImageWebm,
    withAnimationImage,
    bigSize = false,
    priority = false,
}) => {
    const [wasActive, setWasActive] = useState(false);

    useEffect(() => {
        if (isActive) {
            setWasActive(true);
        }
    }, [isActive]);

    const circles = useMemo(() => {
        if (!isAnimate || !wasActive) return null;

        if (animationType === 'blue') {
            return <AnimatedPromoCircle color="purple" isActive={isActive} />;
        }

        if (animationType === 'red') {
            return (
                <>
                    <AnimatedPromoCircle color="red" isActive={isActive} />
                    <AnimatedPromoCircle color="blue" isActive={isActive} />
                </>
            );
        }

        return null;
    }, [wasActive, isAnimate, animationType, isActive]);

    const width = useResize();

    const getCurrentImage = useMemo(() => {
        if (width < MOBILE_WIDTH) return mobileImage || image;
        return image;
    }, [width, image, mobileImage]);

    return (
        <div
            className={clsx(
                'animatedPromoSliderItemImage',
                imageTopMv && !imageBottomMv && 'animatedPromoSliderItemImageTop',
                imageBottomMv && 'animatedPromoSliderItemImageBottom',
                imageBig && 'animatedPromoSliderItemImageBig'
            )}
        >
            {circles}
            <div className="animatedPromoSliderImage">
                <div
                    className={clsx(
                        'animatedPromoSliderImageWrapper',
                        bigSize && 'animatedPromoSliderImageWrapperLeft'
                    )}
                >
                    {wasActive && (
                        <>
                            <CustomImage
                                sizes="(max-width: 768px) 80vw,
                                        (max-width: 1200px) 40vw,
                                        35vw"
                                src={getCurrentImage}
                                alt={getCurrentImage}
                                fill
                                loading="eager"
                                priority={priority}
                            />
                            {withAnimationImage && (
                                <div className="animatedPromoImageAnimation">
                                    <AnimationImage
                                        isActive={isActive}
                                        animationImage={animationImage}
                                        animationImageWebm={animationImageWebm}
                                        priority={priority}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AnimatedPromoImage;
