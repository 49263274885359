import clsx from 'clsx';
import React from 'react';

import cn from './style.module.sass';

interface IProps {
    customClass?: string;
}

const IconHeaderSearch: React.FC<IProps> = ({ customClass }) => (
    <svg
        className={clsx(cn.icon, customClass)}
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11 11L7.66667 7.66667M8.77778 4.88889C8.77778 7.03666 7.03666 8.77778 4.88889 8.77778C2.74111 8.77778 1 7.03666 1 4.88889C1 2.74111 2.74111 1 4.88889 1C7.03666 1 8.77778 2.74111 8.77778 4.88889Z"
            stroke="#3C459B"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default IconHeaderSearch;
