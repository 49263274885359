import { ICity } from '@/types/city/city';
import { ENVIRONMENT, IS_SANDBOX } from '@/utils/constants';

interface IResult {
    City: string;
    Region: string;
    ufXmlID: string;
    CityID: number;
    Confirmed: boolean;
    Latitude: number;
    Longitude: number;
    Flag?: string;
}
export class Cookie {
    public static setGeoCookie = () => {
        const { city, cityID, region, ufXmlID, latitude, longitude, confirmed } = {
            city: 'Москва',
            ufXmlID: '1',
            region: 'Москва',
            cityID: 75557,
            latitude: 55.7557,
            longitude: 37.617761,
            confirmed: false,
        };
        const floatLatitude = !Number.isInteger(latitude) ? latitude : `${latitude}.0`;
        const floatLongitude = !Number.isInteger(longitude) ? longitude : `${longitude}.0`;
        const domain = ENVIRONMENT === 'prod' && !IS_SANDBOX ? 'domain=.pochtabank.ru' : '';

        const cookieValue = encodeURIComponent(
            `{"Flag":"true", "City":"${city}","Region":"${region}","ufXmlID":"${ufXmlID}","CityID":${cityID},"Latitude":${floatLatitude},"Longitude":${floatLongitude},"Confirmed":${confirmed.toString()}}`
        );

        document.cookie = `PochtaBankGeoData = ${cookieValue}; path=/; expires=Fri, 31 Dec 9999 00:00:00 GMT; ${domain}`;
    };

    public static getCookie = (name: string) => {
        if (typeof window !== 'undefined') {
            const matches = new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`).exec(
                document.cookie
            );
            return matches ? decodeURIComponent(matches[1]) : undefined;
        }

        return undefined;
    };

    public static setNewSiteCookie = () => {
        const currentValue = this.getCookie('oldsite');
        if (currentValue !== 'on') document.cookie = `newsite = on; path=/; max-age=2147483647`;
    };

    public static setOldSiteCookie = () => {
        document.cookie = `newsite = ; path=/; expires = Thu, 01 Jan 1970 00:00:00 GMT`;
        document.cookie = `oldsite = on; path=/; max-age=2147483647`;
    };

    public static getCityCookie = (name = 'PochtaBankGeoData') => {
        const arrayedCookie: string[] = document.cookie.split(';');
        const regex = new RegExp(`${name}=`);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const result: IResult = JSON.parse(
            decodeURIComponent(arrayedCookie.find(item => regex.test(item.trimStart())).split(`${name}=`)[1])
        );

        return {
            city: result.City === 'null' ? null : result.City,
            region: result.Region,
            ufXmlID: result.ufXmlID,
            cityID: result.CityID,
            confirmed: result.Confirmed,
            latitude: result.Latitude,
            longitude: result.Longitude,
            flag: result?.Flag,
        };
    };

    public static hasCityCookie = (name = 'PochtaBankGeoData') => {
        const arrayedCookie = document.cookie.split(';');
        const regex = new RegExp(`${name}=`);
        return arrayedCookie.some(item => regex.test(item.trimStart()));
    };

    public static setCityCookie = (cityObject: ICity, name = 'PochtaBankGeoData', isFetched = false) => {
        const { city, cityID, region, ufXmlID, latitude, longitude, confirmed } = cityObject;
        const floatLatitude = !Number.isInteger(latitude) ? latitude : `${latitude}.0`;
        const floatLongitude = !Number.isInteger(longitude) ? longitude : `${longitude}.0`;
        const correctConfirmed = isFetched ? confirmed : true;
        const domain = ENVIRONMENT === 'prod' && !IS_SANDBOX ? 'domain=.pochtabank.ru' : '';

        const cookieValue = encodeURIComponent(
            `{"City":"${city}","Region":"${region}","ufXmlID":"${ufXmlID}","CityID":${cityID},"Latitude":${floatLatitude},"Longitude":${floatLongitude},"Confirmed":${correctConfirmed.toString()}}`
        );

        document.cookie = `${name} = ${cookieValue}; path=/; expires=Fri, 31 Dec 9999 00:00:00 GMT; ${domain}`;
    };
}
