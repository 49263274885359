import { makeAutoObservable } from 'mobx';

import { ICity, ICityOtherFormat, ICityResponse, ICityStore } from '@/types/city/city';
import { Cookie } from '@/utils/cookie';
import { apiRequest } from '@/utils/request';

class City implements ICityStore {
    toggleCallback: (index: number) => void = undefined;

    showDialog = false;

    letters = [
        { label: 'А', disabled: true },
        { label: 'Б', disabled: true },
        { label: 'В', disabled: true },
        { label: 'Г', disabled: true },
        { label: 'Д', disabled: true },
        { label: 'Е', disabled: true },
        { label: 'Ё', disabled: true },
        { label: 'Ж', disabled: true },
        { label: 'З', disabled: true },
        { label: 'И', disabled: true },
        { label: 'Й', disabled: true },
        { label: 'К', disabled: true },
        { label: 'Л', disabled: true },
        { label: 'М', disabled: true },
        { label: 'Н', disabled: true },
        { label: 'О', disabled: true },
        { label: 'П', disabled: true },
        { label: 'Р', disabled: true },
        { label: 'С', disabled: true },
        { label: 'Т', disabled: true },
        { label: 'У', disabled: true },
        { label: 'Ф', disabled: true },
        { label: 'Х', disabled: true },
        { label: 'Ц', disabled: true },
        { label: 'Ч', disabled: true },
        { label: 'Ш', disabled: true },
        { label: 'Щ', disabled: true },
        { label: 'Ъ', disabled: true },
        { label: 'Ы', disabled: true },
        { label: 'Ь', disabled: true },
        { label: 'Э', disabled: true },
        { label: 'Ю', disabled: true },
        { label: 'Я', disabled: true },
    ];

    currentCity: ICity = {
        city: '',
        ufXmlID: '',
        region: '',
        cityID: 0,
        confirmed: false,
        latitude: 0,
        longitude: 0,
    };

    cities: ICity[] = [];

    popularCities: ICity[] = [];

    searchedCities: ICity[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    fetchUserCity = async () => {
        const moscowCity = {
            city: 'Москва',
            ufXmlID: '1',
            region: 'Москва',
            cityID: 75557,
            latitude: 55.7557,
            longitude: 37.617761,
            confirmed: false,
        };

        if (Cookie.hasCityCookie() && !Cookie.getCityCookie().flag) {
            // Убрать, для релиза в крайнем случае, если каким-то способом не определится из куки
            try {
                const currentCity = Cookie.getCityCookie();

                this.setCurrentCity(currentCity, false, true, () => false, false);

                if (!currentCity.confirmed) this.toggleDialog(true);
                return;
            } catch (error) {
                try {
                    await this.sendUserIP();
                } catch (e) {
                    Cookie.setCityCookie(moscowCity, 'PochtaBankGeoData', true);
                    this.setCurrentCity(moscowCity, false, false, () => false, false);
                }
            }
        }

        this.toggleDialog(true);

        try {
            await this.sendUserIP();
        } catch (e) {
            Cookie.setCityCookie(moscowCity, 'PochtaBankGeoData', true);
            this.setCurrentCity(moscowCity, false, false, () => false, false);
        }
    };

    fetchAllCities = async () => {
        try {
            const {
                data: { data },
            } = await apiRequest.get<ICityResponse>(`/city/regionlist`);
            this.cities = data;
            this.checkLetters(data);
            this.setPopularCity(data);
        } catch (e) {
            console.log('Error:::', e);
        }
    };

    setChooseCityCallback = (callback: (index: number) => void) => {
        this.toggleCallback = callback;
    };

    checkLetters = (cities: ICity[]) => {
        cities.forEach(({ city }) => {
            const letter = city.substr(0, 1).toUpperCase();
            const element = this.letters.find(({ label }) => label === letter);
            if (element) element.disabled = false;
        });
    };

    // @todo убрать костыль с cityId, когда на бэке пофиксят данные
    sendUserIP = async () => {
        const {
            data: { data },
        } = await apiRequest.get<{ data: ICityOtherFormat & { cityId: number } }>('/city');

        const { city, region, cityId: cityID, confirmed, latitude, longitude } = data;

        const cityObject: ICity = {
            city,
            ufXmlID: region.ID,
            region: region.UF_MACROREGION,
            cityID,
            confirmed,
            latitude,
            longitude,
        };

        Cookie.setCityCookie(cityObject, 'PochtaBankGeoData', true);
        this.setCurrentCity(cityObject, false, false, () => false, false);
    };

    parseData = (data: string[]) => data.map(item => JSON.parse(item) as ICity);

    containsUnicodeChars = (value: string) => /^\\[u].*/.test(value);

    filterByFirstLetter = (cities: ICity[], input: string) => {
        this.searchedCities = cities.filter(({ city }) => city.substr(0, 1) === input.toUpperCase());
    };

    filterByRegExp = (cities: ICity[], input: string) => {
        const regex = new RegExp(input, 'i');
        this.searchedCities = cities.filter(({ city }) => regex.test(city));
    };

    searchCityLetter = (input: string) => {
        if (input === '' || this.containsUnicodeChars(input)) {
            this.clear();
            return;
        }

        if (input.length === 1) {
            this.filterByFirstLetter(this.cities, input);
            return;
        }
        this.filterByRegExp(this.cities, input);
    };

    searchCity = async (input: string) => {
        if (input === '' || this.containsUnicodeChars(input)) {
            this.clear();
            return;
        }

        try {
            const {
                data: { data: cities },
            } = await apiRequest.get<ICityResponse>(`/city/search?name=${input}`);

            if (input.length === 1) {
                this.filterByFirstLetter(cities, input);
                return;
            }
            this.filterByRegExp(cities, input);
        } catch (e) {
            console.log(e);
        }
    };

    toggleDialog = (state: boolean) => {
        const header = document.querySelector('header');

        if (!header) return;

        if (state) {
            header.classList.add('withCitySelector');
            this.showDialog = state;
            return;
        }
        header.classList.remove('withCitySelector');
        this.showDialog = state;
    };

    setCurrentCity = (
        city: ICity,
        // eslint-disable-next-line default-param-last
        setCookie = false,
        dialogOff?: boolean,
        callback?: () => void,
        setCheckedCookie = true
    ) => {
        this.currentCity = city;
        if (setCookie) Cookie.setCityCookie(city);
        this.clear();
        if (callback) callback();
        if (dialogOff) this.toggleDialog(false);
    };

    setCityCookie = (city: ICity) => {
        Cookie.setCityCookie(city);
    };

    setPopularCity = (cities: ICity[]) => {
        this.popularCities = cities.filter(({ isPopular }) => isPopular);
    };

    clear = () => {
        this.searchedCities = [];
    };

    get results() {
        return this.searchedCities;
    }
}

export default new City();
