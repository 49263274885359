import { VariantProps } from 'class-variance-authority';
import clsx from 'clsx';
import Image from 'next/image';
import React, { useRef } from 'react';

import { btnStyles } from '@/components/UI/Button/styles';
import { ScrollLink } from '@/components/UI/ScrollLink';
import Feature from '@/domain/feature/Feature';
import useBeingInViewport from '@/hooks/useBeingInViewport';
import { useTabletSmallWidth } from '@/hooks/useMedia';
import { smoothScroll } from '@/utils/smoothScroll';

import cn from './style.module.sass';

export type ButtonVariantProps = VariantProps<typeof btnStyles>;

export interface IButtonProps extends ButtonVariantProps {
    type: 'button' | 'link' | 'anchor' | 'buttonLink' | string;
    href?: string;
    target?: '_blank' | '_self' | '_parent' | '_top';
    label: string;
    img?: string;
    onClick?: () => void;
    customClass?: string;
    buttonType?: 'button' | 'submit' | 'reset';
    smooth?: boolean;
    isViewLink?: boolean;
    withMinimalPadding?: boolean;
    rel?: string;
    imgOrder?: boolean;
}

const icon = (
    <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L5 5L9 1" stroke="white" strokeWidth="1.5" />
    </svg>
);

function Button({
    size,
    label,
    img,
    disable,
    customClass,
    type = 'button',
    href,
    target,
    animation,
    onClick: onClickHandler,
    buttonType = 'button',
    smooth = true,
    isRedesign,
    grayBg,
    white,
    darkWhite,
    isViewLink,
    variant,
    colorBtn,
    imgOrder = false,
    withMinimalPadding = false,
    whiteBg = false,
    rel,
}: IButtonProps) {
    const ref = useRef(null);

    const wasInViewport = useBeingInViewport(ref, 0);

    const isAnimationEnabled = Feature.isFeatureEnabled('showAnimationWrap');

    const isMobile = useTabletSmallWidth();

    const scrollOffset = isMobile ? 60 : 90;

    const isHideAnchor = type !== 'button' && typeof href === 'string' ? href.startsWith('#') : false;

    const className = clsx(
        customClass,
        btnStyles({
            size,
            disable,
            animation,
            isAnimationEnabled,
            wasInViewport,
            isRedesign,
            grayBg,
            white,
            darkWhite,
            isViewLink,
            variant,
            colorBtn,
            withMinimalPadding,
            whiteBg,
        })
    );

    const children = (
        <>
            {img && <Image src={img} width={22} height={22} alt={label} />}
            <span>{label}</span>
        </>
    );

    const btnWhiteChildren = (
        <div className={cn.innerWrapper}>
            {imgOrder ? (
                <>
                    <span className={cn.labelBefore}>{label}</span>
                    {variant === 'btnWBMaps' && icon}

                    {img && <Image src={img} alt={label} width={18} height={18} />}
                </>
            ) : (
                <>
                    {img && <Image src={img} alt={label} width={18} height={18} />}
                    <span>{label}</span>
                    {variant === 'btnWBMaps' && icon}
                </>
            )}
        </div>
    );

    const onClick = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
        if (onClickHandler) onClickHandler();
        if (smooth && type === 'link' && !isHideAnchor) smoothScroll(e, href);
    };

    /* eslint-disable react/button-has-type */
    if (type === 'button') {
        return (
            <button ref={ref} disabled={disable} type={buttonType} className={className} onClick={onClick}>
                {variant === 'btnWhite' || 'btnWhiteBlack' ? btnWhiteChildren : children}
            </button>
        );
    }

    if (type === 'buttonLink') {
        return (
            <a rel={rel} href={href} target={target} className={className} onClick={onClick} ref={ref}>
                {variant === 'btnWhite' || 'btnWhiteBlack' ? btnWhiteChildren : children}
            </a>
        );
    }

    if (type === 'anchor' || isHideAnchor) {
        return (
            <ScrollLink
                to={isHideAnchor ? href?.slice(1) : href}
                offset={-scrollOffset}
                activeClass="active"
                className={cn.scrollLink}
            >
                <button ref={ref} disabled={disable} type="button" className={className} onClick={onClick}>
                    {variant === 'btnWhite' || 'btnWhiteBlack' ? btnWhiteChildren : children}
                </button>
            </ScrollLink>
        );
    }

    return (
        <a rel={rel} href={href} ref={ref} target={target} className={className} onClick={onClick}>
            {variant === 'btnWhite' || 'btnWhiteBlack' ? btnWhiteChildren : children}
        </a>
    );
}

export default Button;
