import clsx from 'clsx';
import React from 'react';

import AnimatedComponent from '@/components/Animation';
import AnimatedPromoImage from '@/components/blocks/animatedPromo/AnimatedPromoImage';
import AnimatedPromoSlideBottom from '@/components/blocks/animatedPromo/AnimatedPromoSlideBottom';
import AnimatedPromoSlideTop from '@/components/blocks/animatedPromo/AnimatedPromoSlideTop';
import CustomImage from '@/components/CustomImage';
import { IBanner } from '@/types/animatedPromo/item';
import { GTagEvent } from '@/components/shared/utilities/analytics/metrics';

export interface IProps {
    pos: number;
    isActive: boolean;
    banner: IBanner;
    handleTitleClick: (link: string, gtag: GTagEvent) => void;
}

const AnimatedPromoSlideWrapper: React.FC<{ link: string; children?: React.ReactNode }> = ({ link, children }) => (
    <div className={clsx('animatedPromoSlideGrid')}>{children}</div>
);

const AnimatedPromoSlide: React.FC<IProps> = ({ banner, handleTitleClick, pos, isActive }) => {
    // @todo переделать и привязаться на определенный параметр?
    const config = {
        link: banner?.buttons?.[1]?.link,
        gtag: banner?.buttons?.[1]?.gtag,
    };

    const flagBigImage =
        !banner.line.title && !banner.line.subtitle && !banner?.description && !banner?.advantages?.length;

    return (
        <AnimatedComponent>
            <div
                className={clsx(
                    'animatedPromoSlideItem',
                    pos === 0 && 'animatedPromoSlideItemFirst',
                    'sliderItem',
                    banner.withDecor && 'animatedPromoSlideDecor',
                    banner.backgroundImage && 'animatedPromoSlideBg',
                    flagBigImage && 'animatedPromoSlideItemBigImage'
                )}
            >
                <CustomImage
                    src={banner.backgroundImage}
                    fill
                    loading="eager"
                    priority={pos === 0}
                    alt=""
                    sizes="(max-width: 768px) 75vw,
                  (max-width: 1200px) 80vw,
                  80vw"
                />
                <AnimatedPromoSlideWrapper link={config.link}>
                    <div className="animatedPromoSlideContent">
                        {!flagBigImage && (
                            <AnimatedPromoSlideTop
                                title={banner.line.title}
                                subtitle={banner.line.subtitle}
                                description={banner?.description}
                                descriptionMobile={banner?.descriptionMobile}
                                advantages={banner?.advantages}
                                handleTitleClick={() => handleTitleClick(config.link, config.gtag)}
                            />
                        )}
                        <AnimatedPromoImage
                            isActive={isActive}
                            animationImageWebm={banner?.animationImageWebm}
                            withAnimationImage={banner?.withAnimationImage}
                            animationImage={banner?.animationImage}
                            image={banner?.image}
                            mobileImage={banner?.mobileImage}
                            imageTopMv={banner?.imageTopMv}
                            imageBottomMv={banner?.imageBottomMv}
                            imageBig={banner?.imageBig}
                            isAnimate={banner?.withAnimate}
                            animationType={banner?.animationType}
                            bigSize={flagBigImage}
                            priority={pos === 0}
                        />
                        <AnimatedPromoSlideBottom buttons={banner.buttons} />
                    </div>
                </AnimatedPromoSlideWrapper>
            </div>
        </AnimatedComponent>
    );
};

export default AnimatedPromoSlide;
