import React from 'react';

import AnimatedPromoSlideAdvantages from '@/components/blocks/animatedPromo/AnimatedPromoSlideAdvantages';
import { useResize } from '@/hooks/useResize';
import { IAdvantages } from '@/types/animatedPromo/item';
import { TABLET_WIDTH } from '@/utils/constants';

export interface IProps {
    title: string;
    subtitle: string;
    descriptionMobile: string;
    description: string;
    advantages: IAdvantages[];
    handleTitleClick: () => void;
}

const AnimatedPromoSlideTop: React.FC<IProps> = ({
    handleTitleClick,
    title,
    subtitle,
    description,
    advantages,
    descriptionMobile,
}) => {
    const width = useResize();
    const showMobileTitle = width <= TABLET_WIDTH;

    const getTextBlock = () => {
        if (showMobileTitle && descriptionMobile && typeof window !== 'undefined') {
            return (
                <div
                    className="animatedPromoSlideTopSliderItemDesc"
                    dangerouslySetInnerHTML={{
                        __html: descriptionMobile,
                    }}
                />
            );
        }

        if (description) {
            return (
                <div
                    className="animatedPromoSlideTopSliderItemDesc"
                    dangerouslySetInnerHTML={{
                        __html: description,
                    }}
                />
            );
        }
        return (
            <div className="animatedPromoSlideTopAdvantages">
                <AnimatedPromoSlideAdvantages advantages={advantages} />
            </div>
        );
    };

    return (
        <div className="animatedPromoSlideTopSliderItemTop">
            <div className="animatedPromoSlideTopSliderItemTopLines">
                <div className="animatedPromoSlideTopSliderItemTitle" onClick={handleTitleClick} role="presentation">
                    <span
                        dangerouslySetInnerHTML={{
                            __html: title,
                        }}
                    />
                </div>
                {subtitle && (
                    <div className="animatedPromoSlideTopSliderItemSubtitle">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: subtitle,
                            }}
                        />
                    </div>
                )}
            </div>

            {getTextBlock()}
        </div>
    );
};

export default AnimatedPromoSlideTop;
