/* eslint-disable */
export const getVarioqubFlags = (func?, funcSecond?) => {
    (function (e, x, pe, r, i, me, nt) {
        (e[i] =
            e[i] ||
            function () {
                (e[i].a = e[i].a || []).push(arguments);
            }),
            (me = x.createElement(pe)),
            (me.async = 1),
            (me.src = r),
            (nt = x.getElementsByTagName(pe)[0]),
            nt.parentNode.insertBefore(me, nt);
    })(window, document, 'script', 'https://abt.s3.yandex.net/expjs/latest/exp.js', 'ymab');

    window?.ymab('metrika.19473172', 'getFlags', flags => {
        console.log('flags ----', flags);

        if (flags?.menu?.includes('new')) {
            func();
        }

        if (flags?.menuvklad?.includes('new')) {
            funcSecond();
        }
    });
};
