import clsx from 'clsx';
import { useRouter } from 'next/navigation';
import React, { FC, useMemo, useState } from 'react';
import Collapsible from 'react-collapsible';

import CustomImage from '@/components/CustomImage';
import FooterProducts from '@/components/layout/Footer/Footer/footerProducts';
import FooterUsers from '@/components/layout/Footer/Footer/footerUsers';
import FooterSearch from '@/components/layout/Footer/FooterSearch';
import Trigger from '@/components/layout/Footer/Trigger';
import TextField from '@/components/TextField';
import Button from '@/components/UI/Button';
import IconLogo from '@/components/UI/IconLogo';
import { useFixHtml } from '@/hooks/useFixHtml';
import { useResize } from '@/hooks/useResize';
import { IFooter } from '@/types/footer';
import { MOBILE_WIDTH } from '@/utils/constants';
import { event } from '@/components/shared/utilities/analytics/metrics';

import MapSite from '../MapSite';
import FooterMenu from './footerMenu';
import cn from './style.module.sass';

const Footer: FC<IFooter & { isDisabled?: boolean; liteActiveCode?: string; isFlocktory?: boolean }> = ({
    products,
    users,
    menu,
    copyright,
    phoneText,
    phone,
    phoneLink,
    store,
    storeTitle,
    social,
    logoLink = '/',
    isMobileAppsActive = true,
    isSocialActive = true,
    isSearchActive = true,
    lite,
    isDisabled,
    liteActiveCode,
    isFlocktory = false,
}) => {
    const isLiteVersionExist = lite?.filter(element => element.code === liteActiveCode)[0];
    const width = useResize();
    const { unfix, fix } = useFixHtml();

    const router = useRouter();

    const [isMapSiteOpen, setMapSiteOpen] = useState(false);
    const [isTriggerOpen, setIsTriggerOpen] = useState(false);

    const openWindow = () => {
        setMapSiteOpen(true);
        fix();
    };

    const closeWindow = () => {
        setMapSiteOpen(false);
        unfix();
    };

    const toggleTrigger = () => {
        setIsTriggerOpen(!isTriggerOpen);
    };

    const liteText = useMemo(() => {
        if (!liteActiveCode) return null;

        const text = lite?.filter(element => element.code === liteActiveCode)[0]?.text;

        return text;
    }, [liteActiveCode]);

    const liteTextCollapsible = useMemo(() => {
        if (!liteActiveCode) return null;

        const text = lite?.filter(element => element.code === liteActiveCode)[0]?.textCollapsible;

        return text;
    }, [liteActiveCode]);

    const isDesktop = width >= MOBILE_WIDTH;
    const isMobile = width < MOBILE_WIDTH;

    if (isDisabled) return null;

    if (isLiteVersionExist && liteActiveCode) {
        return (
            <footer className={clsx(cn.footer, cn.lite)}>
                <div className={cn.footerWrap}>
                    <div className={cn.footerBase}>
                        <div className="full-wrapper">
                            <div className={cn.footerLiteWrapper}>
                                <div className={cn.footerLiteText} dangerouslySetInnerHTML={{ __html: liteText }} />
                                {liteTextCollapsible && (
                                    <Collapsible
                                        className={clsx(cn.footerLiteText, cn.footerLiteTextSecondary)}
                                        openedClassName={clsx(cn.footerLiteText, cn.footerLiteTextSecondary)}
                                        trigger={
                                            /* eslint-disable */
                                            <Trigger isOpen={isTriggerOpen} toggle={toggleTrigger} />
                                            /* eslint-enable */
                                        }
                                        transitionTime={300}
                                        triggerDisabled
                                        easing="ease-in-out"
                                        open={isTriggerOpen}
                                    >
                                        <div
                                            className={cn.footerLiteExpandText}
                                            dangerouslySetInnerHTML={{
                                                __html: liteTextCollapsible,
                                            }}
                                        />
                                    </Collapsible>
                                )}
                            </div>
                            <div className={clsx(cn.footerLite)}>
                                <div className={clsx(cn.footerLiteItem)}>
                                    <a href={logoLink} className={cn.logo}>
                                        <IconLogo />
                                    </a>
                                </div>
                                <TextField
                                    text={copyright}
                                    name="copyright"
                                    isHTML
                                    htmlValue={copyright}
                                    className={clsx(cn.footerLiteItem, cn.footerInfoItemCopy)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }

    return (
        <footer className={clsx(cn.footer, isFlocktory && cn.flocktoryMenu)}>
            <div className={cn.footerWrap}>
                <FooterProducts products={products} hideOnMobile />
                <FooterUsers users={users} hideOnMobile />
                <FooterMenu menu={menu} hideOnMobile />
                <div className={cn.footerBase}>
                    <div className="full-wrapper">
                        <div className={cn.footerBaseGrid}>
                            <div className={clsx(cn.footerBaseItem, cn.footerBaseItemLeft)}>
                                <div className={cn.footerInfo}>
                                    <div className={clsx(cn.footerInfoItem, cn.footerInfoItemLogo)}>
                                        <div className={cn.footerTop}>
                                            <div className={clsx(cn.footerItem, cn.footerItemLogo)}>
                                                <a href={logoLink} className={cn.logo}>
                                                    <IconLogo />
                                                </a>
                                            </div>
                                            <div className={clsx(cn.footerItem, cn.footerItemMap, cn.showOnMobile)}>
                                                <Button
                                                    variant="btnWBMaps"
                                                    type="button"
                                                    label="Меню сайта"
                                                    animation={false}
                                                    customClass="full"
                                                    size="small"
                                                    onClick={() => openWindow()}
                                                />
                                                <MapSite
                                                    products={products}
                                                    users={users}
                                                    menu={menu}
                                                    open={isMapSiteOpen}
                                                    close={() => closeWindow()}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={clsx(cn.footerInfoItem, cn.footerInfoItemPhone)}>
                                        <div className={cn.footerInfoTextPhone}>{phoneText}</div>
                                        <a href={`tel:${phoneLink}`} className={cn.footerInfoPhone}>
                                            {phone}
                                        </a>
                                    </div>
                                    {isDesktop && (
                                        <TextField
                                            text={copyright}
                                            name="copyright"
                                            isHTML
                                            htmlValue={copyright}
                                            className={clsx(cn.footerInfoItem, cn.footerInfoItemCopy)}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className={clsx(cn.footerBaseItem, cn.footerBaseItemRight)}>
                                <div className={clsx(cn.footerStore, isMobileAppsActive === false && cn.hidden)}>
                                    <div className={cn.footerStoreGrid}>
                                        {store && (
                                            <div className={cn.footerStoreItem}>
                                                <a
                                                    href={store.link}
                                                    target="_blank"
                                                    className={cn.footerStoreLink}
                                                    rel="noopener noreferrer"
                                                    onClick={() => event(store.gtag)}
                                                >
                                                    {store.image && (
                                                        <CustomImage
                                                            src={store.image}
                                                            alt={store.name}
                                                            width={60}
                                                            height={60}
                                                        />
                                                    )}
                                                </a>
                                                <div className={cn.footerStoreItemInfo}>
                                                    <a
                                                        href={store.link}
                                                        target="_blank"
                                                        className={cn.footerStoreLink}
                                                        rel="noopener noreferrer"
                                                        onClick={() => event(store.gtag)}
                                                    >
                                                        <div className={cn.footerStoreTitle}>{store.name}</div>
                                                    </a>
                                                    <div className={cn.footerStoreDesc}>{store.desc}</div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className={clsx(cn.footerSearch, isSearchActive === false && cn.hidden)}>
                                    <FooterSearch />
                                </div>

                                <div className={clsx(cn.footerSocial, isSocialActive === false && cn.hidden)}>
                                    <div className={cn.footerSocialGrid}>
                                        {social?.map(({ link, name, image, gtag }, index) => (
                                            <div className={cn.footerSocialItem} key={`${index}-${link}`}>
                                                <a
                                                    href={link}
                                                    target="_blank"
                                                    className={cn.footerSocialLink}
                                                    rel="noopener noreferrer"
                                                    onClick={() => event(gtag)}
                                                >
                                                    {image && (
                                                        <CustomImage src={image} alt={name} width={39} height={39} />
                                                    )}
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {isMobile && (
                                    <TextField
                                        text={copyright}
                                        name="copyright"
                                        isHTML
                                        htmlValue={copyright}
                                        className={clsx(cn.footerInfoItem, cn.footerInfoItemCopy)}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
