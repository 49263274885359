'use client';

import { enableStaticRendering } from 'mobx-react-lite';
import React, { ReactNode } from 'react';

import { AppStoreProvider } from '@/context/AppStoreContext';
import { BreadcrumbsProvider } from '@/context/BreadcrumbsProvider';
import { CityProvider } from '@/context/CityProvider';
import { GeoProvider } from '@/context/GeoStoreContext';
import { LayoutProvider } from '@/context/LayoutStoreContext';
import { ThemeProvider } from '@/context/ThemeProvider';
import { AppStore } from '@/domain/core/AppStore';
import { IBreadcrumbs } from '@/types/breadcrumbs';

enableStaticRendering(typeof window === 'undefined');

export interface IInitialState {
    appStore: AppStore;
    breadcrumbs?: IBreadcrumbs;
}

export const AppProvider = ({ children, initialState }: { children: ReactNode; initialState: IInitialState }) => (
    <AppStoreProvider initialState={initialState.appStore}>
        <LayoutProvider>
            <ThemeProvider>
                <BreadcrumbsProvider initialState={initialState?.breadcrumbs}>
                    <GeoProvider>
                        <CityProvider>{children}</CityProvider>
                    </GeoProvider>
                </BreadcrumbsProvider>
            </ThemeProvider>
        </LayoutProvider>
    </AppStoreProvider>
);
