import { EditorContent, useEditor } from '@tiptap/react';
import React from 'react';

import { useExtensions } from '@/hooks/useExtensions';

type TextEditorProps = {
    textValue: string;
    className?: string;
};

const TiptapTextField = ({ textValue, className }: TextEditorProps) => {
    const { loaded, extensions } = useExtensions();

    const editor = useEditor(
        {
            extensions,
            content: textValue,
            editorProps: {},
            editable: false,
        },
        [textValue, loaded]
    );

    if (loaded) return null;

    return (
        <div className={className}>
            <EditorContent editor={editor} />
        </div>
    );
};

export default TiptapTextField;
