import clsx from 'clsx';
import React from 'react';
import Countdown from 'react-countdown';

import { NotificationIcon } from '@/components/blocks/forms/ConfirmEmailForm/icon';

import cn from './style.module.sass';

interface IProps {
    time?: number;
    handler?: () => void;
}

const SuccessGenerateCodeNotification: React.FC<IProps> = ({ time, handler }) => {
    const completeHandler = () => {
        handler();
    };

    const renderer = ({ minutes, seconds }) => (
        <span>
            {/* eslint-disable-next-line */}
            {minutes}:{seconds}
        </span>
    );

    return (
        <div className={cn.notificationWrapper}>
            <div className={cn.notificationContent}>
                <div className={clsx(cn.notificationIcon, cn.notificationItem)}>
                    <NotificationIcon />
                </div>
                <div className={clsx(cn.notificationInfo, cn.notificationItem)}>
                    <div className={cn.notificationTitle}>Код сгенерирован и выслан вам на мобильный телефон.</div>
                    <div className={cn.notificationDesc}>
                        {`Повторная генерация кода будет доступна через  `}
                        <Countdown date={Date.now() + time} renderer={renderer} onComplete={completeHandler} />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default SuccessGenerateCodeNotification;
