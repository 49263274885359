import { Mark, mergeAttributes } from '@tiptap/core';

export interface AnchorOptions {
    HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        anchor: {
            setAnchor: (anchor: string) => ReturnType;
            unsetAnchor: () => ReturnType;
        };
    }
}

export const Anchor = Mark.create<AnchorOptions>({
    name: 'anchor',

    addOptions() {
        return {
            HTMLAttributes: { id: '2' },
        };
    },

    addAttributes() {
        return {
            href: {
                default: '',
            },
            class: {
                default: 'anchor',
            },
            target: {
                default: '',
            },
            rel: {
                default: '',
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'a',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['a', mergeAttributes(HTMLAttributes)];
    },

    addCommands() {
        return {
            setAnchor:
                anchor =>
                ({ commands, state }) =>
                    commands.setMark('anchor', {
                        href: `#${anchor}`,
                    }),
            unsetAnchor:
                () =>
                ({ commands }) =>
                    commands.unsetMark(this.name),
        };
    },
});
