import Link, { LinkProps } from 'next/link';
import { useSearchParams } from 'next/navigation';
import React from 'react';

import Feature from '@/domain/feature/Feature';

interface CustomLinkProps extends LinkProps {
    children?: React.ReactNode;
    customClass?: string;
    onClick?: () => void;
}

export function BasicLink({ href, customClass, onClick, children, ...props }: CustomLinkProps) {
    const searchParams = useSearchParams();
    const isNewLinkEnabled = Feature.isFeatureEnabled('enableSaveGETParamInSession');
    const finalSearchParams = searchParams.toString();

    if (isNewLinkEnabled && finalSearchParams?.length > 0) {
        return (
            <Link {...props} href={`${href.toString()}?${finalSearchParams}`} className={customClass} onClick={onClick}>
                {children}
            </Link>
        );
    }

    return (
        <a href={href.toString()} className={customClass} onClick={onClick}>
            {children}
        </a>
    );
}
