import clsx from 'clsx';
import React, { ReactNode, useRef } from 'react';

import Feature from '@/domain/feature/Feature';
import useBeingInViewport from '@/hooks/useBeingInViewport';

interface IAnimatedComponent {
    className?: string;
    classNameActive?: string; // класс который проставится при активности
    [name: string]: unknown;
    children?: ReactNode;
}

const AnimatedComponent: React.FC<IAnimatedComponent> = ({
    children,
    className,
    classNameActive = 'animation-init',
    ...rest
}) => {
    const isAnimationEnabled = Feature.isFeatureEnabled('showAnimationWrap');

    const ref = useRef(null);

    const wasInViewport = useBeingInViewport(ref, 0);

    return (
        <div
            ref={ref}
            {...rest}
            className={clsx(
                className,
                isAnimationEnabled && 'animation',
                isAnimationEnabled && wasInViewport && classNameActive
            )}
        >
            {children}
        </div>
    );
};
export default AnimatedComponent;
