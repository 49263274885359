import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import Link from 'next/link';
import React, { FC, useState } from 'react';

import Icon from './icon';
import cn from './style.module.sass';

const NotificationCashLoan: FC = observer(() => {
    const [isShow, setIsShow] = useState(false);

    const handleClick = () => {
        setIsShow(false);
    };

    if (!isShow) return null;

    return (
        <div className={clsx(cn.wrapper)}>
            <div className={cn.innerWrapper}>
                <Icon />
                <Link href="/service/cashcredit" className={cn.articleTag}>
                    Игорь Андреевич, ваша заявка на открытие кредитной карты ждет. Продолжить офрмление.
                </Link>
                <div className={clsx(cn.crossIcon, 'crossIcon')} role="presentation" onClick={handleClick}>
                    <span className={clsx(cn.cross, 'cross')} />
                </div>
            </div>
        </div>
    );
});

export default NotificationCashLoan;
