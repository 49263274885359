import React, { FC } from 'react';

import cn from './style.module.sass';

const Icon: FC = () => (
    <svg width="30" height="41" viewBox="0 0 30 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            className={cn.svgStroke}
            d="M28.0008 8.0459V38.0631C28.0008 38.8323 27.3518 39.4813 26.5826 39.4813H3.21895C2.44977 39.4813 1.80078 38.8443 1.80078 38.0631V3.25801C1.80078 2.47682 2.43775 1.83984 3.21895 1.83984H21.7827"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
        />
        <path
            className={cn.svgStroke}
            d="M25.5858 10.5C27.813 10.5 28.9285 7.80716 27.3536 6.23223L23.7678 2.64645C22.1929 1.07154 19.5 2.18694 19.5 4.41421V7.92167C19.5 9.34225 20.6534 10.5 22.0769 10.5H25.5858Z"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
        />
        <path
            className={cn.svgStroke}
            d="M7.44922 14.4102H14.9006"
            stroke="#CC023D"
            strokeWidth="3"
            strokeLinecap="round"
        />
        <path
            className={cn.svgStroke}
            d="M7.44922 21.4541H22.34"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
        />
        <path
            className={cn.svgStroke}
            d="M7.44922 28.6865H22.34"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
        />
    </svg>
);

export default Icon;
