import { makeAutoObservable } from 'mobx';

interface ILayoutStore {
    isHeaderSticky: boolean;
    isLiteHeaderDesktop: boolean;
    isLiteHeaderMobile: boolean;
    isExternalFrameView: boolean;
    promoHeight: number;
    setHeaderSticky: (value: boolean) => void;
    setLiteHeaderDesktop: (value: boolean) => void;
    setLiteHeaderMobile: (value: boolean) => void;
    setIsExternalFrameView: (value: boolean) => void;
    setPromoHeight: (value: number) => void;
}

class LayoutStore implements ILayoutStore {
    public promoHeight = 0;

    public isExternalFrameView = false;

    public isHeaderSticky = true;

    public isLiteHeaderDesktop = false;

    public isLiteHeaderMobile = false;

    constructor() {
        makeAutoObservable(this);
    }

    public setHeaderSticky = (value: boolean) => {
        this.isHeaderSticky = value;
    };

    public setLiteHeaderDesktop = (value: boolean) => {
        this.isLiteHeaderDesktop = value;
    };

    public setLiteHeaderMobile = (value: boolean) => {
        this.isLiteHeaderMobile = value;
    };

    public setIsExternalFrameView = (status: boolean) => {
        this.isExternalFrameView = status;
    };

    public setPromoHeight = (height: number) => {
        this.promoHeight = height;
    };
}
export default LayoutStore;
