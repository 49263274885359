import Error from 'next/error';
import React, { FC, ReactElement } from 'react';

import Feature from '@/domain/feature/Feature';

interface IProps {
    name: string;
    isPage?: boolean;
    children: ReactElement;
}

const FeatureWrapper: FC<IProps> = ({ name, children, isPage = false }) => {
    const flag = Feature.isFeatureEnabled(name);

    if (flag) {
        return children;
    }

    if (isPage) {
        return <Error statusCode={404} />;
    }
    return null;
};

export default FeatureWrapper;
