import clsx from 'clsx';
import React from 'react';

import CustomImage from '@/components/CustomImage';
import ModalWrapper from '@/components/modal/ModalWrapper';
import Button from '@/components/UI/Button';

import cn from './style.module.sass';

interface IProps {
    isOpen: boolean;
    isFail?: boolean;
    isInfoText?: boolean;
    handleClose: () => void;
    handleOpen?: () => void;
    title: string;
    subTitle?: string;
}

const SubscriptionModal: React.FC<IProps> = ({
    isOpen,
    isFail = false,
    isInfoText = false,
    handleClose,
    handleOpen,
    title,
    subTitle,
}) => (
    <ModalWrapper
        isOpen={isOpen}
        handleClose={handleClose}
        handleOpen={handleOpen}
        wrapperClassName={cn.modalWrapper}
        bgClassName={cn.bgWrapper}
    >
        <div className={cn.wrapper}>
            {isFail ? (
                <div
                    className={clsx(cn.title, isInfoText && cn.info)}
                    dangerouslySetInnerHTML={{
                        __html: title || 'Произошла ошибка при отправке формы',
                    }}
                />
            ) : (
                <div className={cn.innerWrapper}>
                    <div className={cn.icon}>
                        <CustomImage src="/img-next/svg/success.svg" alt="" width="48" height="48" />
                    </div>
                    <div className={cn.title} dangerouslySetInnerHTML={{ __html: title }} />
                    {subTitle && <div className={cn.subTitle} dangerouslySetInnerHTML={{ __html: subTitle }} />}
                </div>
            )}
            {!isInfoText && (
                <Button variant="btnBlue" onClick={handleClose} type="button" label="Закрыть" customClass={cn.button} />
            )}
        </div>
    </ModalWrapper>
);
export default SubscriptionModal;
