import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { ChangeEvent, useState } from 'react';
import InputMask from 'react-input-mask';

import { finFormattingValue } from '@/utils/finFormattingValue';

import cn from './style.module.sass';

export interface IInput {
    value?: string;
    placeholder?: string;
    label?: string;
    type?: string;
    onChangeHandler?: (e: ChangeEvent<HTMLInputElement>) => unknown;
    isArrowed?: boolean;
    isError?: boolean;
    errorText?: string;
    customClass?: string;
    size?: 'default' | 'small';
    isDisabled?: boolean;
    shouldColored?: boolean;
    [name: string]: unknown;
    mask?: string | '';
    isPrivateBank?: boolean;
    isFin?: boolean;
    isRedesign?: boolean;
    isDate?: boolean;
}

const Input: React.FC<IInput> = observer<IInput, HTMLInputElement>(
    (
        {
            value,
            placeholder,
            label,
            onChangeHandler,
            isError,
            errorText,
            isArrowed,
            customClass,
            isDisabled,
            type = 'text',
            size,
            shouldColored,
            mask = '',
            isPrivateBank,
            isFin = false,
            isRedesign,
            isDate = false,
            ...rest
        },
        ref
    ) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const ComponentInput = mask ? InputMask : 'input';

        const [formatValue, setFormatValue] = useState('');

        return (
            <>
                <div
                    className={clsx(
                        cn.wrapper,
                        size === 'small' && cn.small,
                        shouldColored && cn.colored,
                        isError && cn.wrapperError,
                        mask && !isPrivateBank && !isRedesign && cn.masked,
                        isPrivateBank && cn.private,
                        isRedesign && cn.credit,
                        customClass
                    )}
                >
                    {/* eslint-disable */}
                    <ComponentInput
                        {...(mask ? { mask } : {})}
                        disabled={isDisabled}
                        onChange={e => {
                            onChangeHandler(e);

                            if (!isDate) {
                                setFormatValue(finFormattingValue(e.target.value));
                            }
                        }}
                        value={isFin ? formatValue : value}
                        className={clsx(
                            cn.input,
                            !isError && cn.valid,
                            label && cn.inputLabel,
                            isPrivateBank && cn.private,
                            isRedesign && cn.credit
                        )}
                        type={type}
                        required
                        placeholder={placeholder}
                        ref={ref}
                        {...rest}
                    />
                    {isArrowed && <span className={clsx(cn.arrow)} />}
                    {label && <span className={cn.label}>{label}</span>}
                </div>
                {isError && (
                    <div
                        key={errorText}
                        className={clsx(cn.error, isPrivateBank && cn.private, isRedesign && cn.credit)}
                    >
                        {errorText}
                    </div>
                )}
            </>
        );
    },
    { forwardRef: true }
);

export default Input;
