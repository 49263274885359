import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

import TooltipIcon from '@/components/blocks/Promo/tooltipIcon';
import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';
import CustomTooltip from '@/components/Tooltip';
import { useAppStore } from '@/context/AppStoreContext';
import { IPromoItem } from '@/types/promo/item';

interface IProps {
    promo: IPromoItem[];
    sizeIcon: number;
}

const TooltipText: FC<{ tooltipText: string }> = ({ tooltipText }) => (
    <div className="promoTooltipText" dangerouslySetInnerHTML={{ __html: tooltipText }} />
);

const PromoDots: FC<IProps> = ({ promo, sizeIcon }) => {
    const [isTinaView] = useAppStore(store => store.isTinaView);

    return (
        <div className="promoDotsWrap">
            <div className="promoDots">
                {promo?.slice(0, 4)?.map(({ image: itemImage, imageLink, title, subtitle, tooltipText }, index) => (
                    <div className="promoDotsItem" data-length={promo.length} key={index}>
                        <div className="promoDotsItemWrap">
                            <div className="promoDotsItemImg">
                                <div className="promoDotsItemImgWrap">
                                    {itemImage && !imageLink && (
                                        <CustomImage src={itemImage} alt={title} width={sizeIcon} height={sizeIcon} />
                                    )}

                                    {imageLink && !isTinaView && (
                                        <CustomImage src={imageLink} alt={title} width={sizeIcon} height={sizeIcon} />
                                    )}

                                    {imageLink && isTinaView && (
                                        <div className="promoServiceDesc">
                                            предпросмотр картинки с продуктовым параметром невозможен
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="promoDotsItemContent">
                                {title && (
                                    <div className="promoDotsItemTitleWrap">
                                        <TextField
                                            text={title}
                                            name={`promo.[${index}].title`}
                                            isTextArea
                                            className="promoDotsItemTitle"
                                        />

                                        {tooltipText && (
                                            <div className="promoTooltip">
                                                <CustomTooltip
                                                    html={<TooltipText tooltipText={tooltipText} />}
                                                    background="white"
                                                >
                                                    <TooltipIcon />
                                                </CustomTooltip>
                                            </div>
                                        )}
                                    </div>
                                )}
                                <TextField
                                    text={subtitle}
                                    name={`promo.[${index}].subtitle`}
                                    className="promoDotsItemSubtitle"
                                    isTextArea
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default observer(PromoDots);
