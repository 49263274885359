import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useRef, useState } from 'react';

import PromoButtons from '@/components/blocks/Promo/PromoButtons';
import PromoDots from '@/components/blocks/Promo/PromoDots';
import PromoSpecial from '@/components/blocks/Promo/PromoSpecial';
import PromoTop from '@/components/blocks/Promo/PromoTop';
import PromoVideo from '@/components/blocks/Promo/PromoVideo';
import CustomImage from '@/components/CustomImage';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import Button from '@/components/UI/Button';
import { useAppStore } from '@/context/AppStoreContext';
import { useLayoutStore } from '@/context/LayoutStoreContext';
import { useMobileWidth } from '@/hooks/useMedia';
import { IBanner, IPromoEntry, IPromoItem, ISpecial } from '@/types/promo/item';

export interface IPromo {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'promo';
    /**
     * Список кнопок с анонсом. Пока есть непонятки с синей плашкой, так как она располагаться может в любом месте.
     * Сделать markdown?
     */
    banner: IBanner;
    promo?: IPromoItem[];
    entry?: IPromoEntry;
    hideBreadcrumbs?: boolean;
    enableSpecial?: boolean;
    special?: ISpecial;
}

const Promo: FC<IPromo> = ({ banner, promo, entry, hideBreadcrumbs, special, enableSpecial }) => {
    const [isTinaView] = useAppStore(store => store.isTinaView);

    const {
        image,
        mobileImage,
        imageLink,
        imageLinkMobile,
        imageTopMv,
        imageBottomMv = false,
        imageBig = false,
    } = banner;

    const isMobile = useMobileWidth();

    const promoRef = useRef<HTMLDivElement>(null);

    const { setPromoHeight } = useLayoutStore();

    useEffect(() => {
        if (isMobile) return;

        if (!promoRef.current?.offsetHeight) return;

        setPromoHeight(promoRef.current?.offsetHeight);
    }, [isMobile, promoRef]);

    const [currentImage, setCurrentImage] = useState<string>();
    const [isLinkedImage, setIsLinkedImage] = useState<boolean>(false);

    useEffect(() => {
        if (isMobile) {
            const actualImage = imageLinkMobile || mobileImage || imageLink || image;
            setIsLinkedImage(actualImage === imageLinkMobile || actualImage === imageLink);
            setCurrentImage(actualImage);
        } else {
            const actualImage = imageLink?.length > 0 ? imageLink : image;
            setIsLinkedImage(actualImage === imageLink);
            setCurrentImage(actualImage);
        }
    }, [isMobile, imageLinkMobile, mobileImage, imageLink, image]);

    return (
        <div
            className={clsx(
                'promo promo--primary',
                banner?.color === 'white' && 'promoWhite',
                enableSpecial && 'promo--special'
            )}
        >
            <div className="promoWrap">
                <div className="promoGrid" ref={promoRef}>
                    <div
                        className={clsx('promoItem', banner?.backgroundImage && 'promoItemBg')}
                        style={{
                            background: banner?.activeGradient
                                ? 'linear-gradient(259.88deg, #727BC8 -12.53%, #577CCE 20.05%, #6158A0 50.08%, #B4547E 87.2%, #61478E 128.05%)'
                                : banner?.background,
                        }}
                    >
                        {banner?.backgroundImage && !!banner?.activeImage && !banner?.activeGradient && (
                            <CustomImage src={banner?.backgroundImage} fill alt="" />
                        )}
                        <div className="promoItemGrid">
                            <div className="promoItemContent">
                                <PromoTop
                                    line={banner?.line}
                                    hideBreadcrumbs={hideBreadcrumbs}
                                    entry={entry}
                                    advantages={banner?.advantages}
                                    description={banner?.description}
                                />
                                <div className="promoItemBot">
                                    <div className="promoBtn">
                                        <PromoButtons buttons={banner?.buttons} />
                                    </div>
                                </div>
                            </div>
                            <div
                                className={clsx(
                                    'promoItemImage',
                                    entry?.text && 'promoItemImageEntry',
                                    imageTopMv && !imageBottomMv && 'promoItemImageTopMvImage',
                                    imageBottomMv && 'promoItemImageBottomMvImage',
                                    imageBig && 'promoItemImageBig'
                                )}
                            >
                                <div className="promoImage">
                                    {!banner.withAnimationImage && isLinkedImage && isTinaView && (
                                        <div className="promoServiceDesc">
                                            предпросмотр картинки с продуктовым параметром невозможен
                                        </div>
                                    )}
                                    {!banner.withAnimationImage && currentImage && !isLinkedImage && (
                                        <CustomImage
                                            src={currentImage}
                                            alt={banner?.line?.title}
                                            fill
                                            mockWidth={480}
                                            mockHeight={420}
                                            priority
                                            loading="eager"
                                        />
                                    )}

                                    {!banner.withAnimationImage && isLinkedImage && !isTinaView && (
                                        <CustomImage
                                            src={currentImage}
                                            alt={banner?.line?.title}
                                            fill
                                            mockWidth={480}
                                            mockHeight={420}
                                            priority
                                            loading="eager"
                                        />
                                    )}

                                    {banner.withAnimationImage && (
                                        <div className="promoImageAnimation">
                                            <PromoVideo
                                                animationImageWebm={banner?.animationImageWebm}
                                                animationImage={banner?.animationImage}
                                            />
                                        </div>
                                    )}
                                </div>
                                {entry?.text &&
                                    (entry.color === 'white' ? (
                                        <div className={clsx('promoEntry', 'promoEntryNoMobail')}>
                                            <Button
                                                variant="btnWhiteBorder"
                                                type="link"
                                                href={entry?.link}
                                                label={entry?.text}
                                                size="small"
                                                customClass={clsx('full')}
                                                animation={false}
                                            />
                                        </div>
                                    ) : (
                                        <div className={clsx('promoEntry', 'promoEntryNoMobail')}>
                                            <Button
                                                variant="btnBlueBorder"
                                                type="link"
                                                href={entry?.link}
                                                label={entry?.text}
                                                size="small"
                                                customClass={clsx('full')}
                                                animation={false}
                                            />
                                        </div>
                                    ))}
                                <div className="promoBtnMobail">
                                    <PromoButtons buttons={banner?.buttons} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {promo && promo?.length > 0 && <PromoDots promo={promo} sizeIcon={64} />}

                {imageBottomMv && (
                    <div className="promoBtnMobailBottom">
                        {banner?.buttons?.length > 0 && <PromoButtons buttons={banner?.buttons} />}
                    </div>
                )}

                {enableSpecial && <PromoSpecial {...special} />}
            </div>
        </div>
    );
};

export default withBlockLinks(observer(Promo));
