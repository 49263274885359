import { createContext } from 'react';

const TinaLibContext = createContext<{
    TinaInline: typeof import('react-tinacms-inline');
    Tina: typeof import('tinacms');
    ReactTinacmsEditor: typeof import('react-tinacms-editor');
    DateFieldPlugin: typeof import('react-tinacms-date').DateFieldPlugin;
}>(null);

export default TinaLibContext;
