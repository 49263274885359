import clsx from 'clsx';
import React, { FC } from 'react';

import { BasicLink } from '@/components/UI/BasicLink';
import { ISimpleMenu } from '@/types/menu/menu';
import { event } from '@/components/shared/utilities/analytics/metrics';

export interface IMenuTop {
    /**
     * Доп. класс
     */
    customClass?: string;
    headerTopMenu: ISimpleMenu[];
    isBusinessPage?: boolean;
}

const MenuTop: FC<IMenuTop> = ({ customClass, headerTopMenu, isBusinessPage }) => {
    const isElementActive = (index: number) => {
        if (isBusinessPage) return index === 1;
        return index === 0;
    };

    return (
        <nav className={clsx('menuTop', customClass)}>
            {headerTopMenu?.map(({ href, name, gtag }, index) => (
                <div
                    className="menuTopItem"
                    key={name}
                    onClick={() => {
                        if (gtag?.action) event(gtag);
                    }}
                    role="presentation"
                >
                    <BasicLink
                        href={href}
                        customClass={clsx('menuTopLink', isElementActive(index) && 'menuTopLinkActive')}
                    >
                        {name}
                    </BasicLink>
                </div>
            ))}
        </nav>
    );
};

export default MenuTop;
