import { cva } from 'class-variance-authority';

import cn from '@/components/UI/Button/style.module.sass';

export const btnStyles = cva(cn.btnBase, {
    variants: {
        variant: {
            btnBlue: cn.btnBlue,
            btnBlueBorder: cn.btnBlueBorder,
            btnColor: cn.btnColor,
            btnWBMaps: cn.btnWBMaps,
            btnWhite: cn.btnWhite,
            btnWhiteBlack: cn.btnWhiteBlack,
            btnWhiteBorder: cn.btnWhiteBorder,
        },

        size: {
            large: cn.large,
            small: cn.small,
            xsmall: cn.xsmall,
            full: cn.full,
            long: cn.long,
        },
        disable: {
            true: cn.disable,
        },
        animation: {
            true: cn.animation,
        },
        isAnimationEnabled: {
            true: cn.animation,
        },
        wasInViewport: {
            true: cn.animationInit,
        },
        isRedesign: {
            true: cn.btnNewStyle,
        },
        darkWhite: {
            true: cn.darkWhite,
        },
        grayBg: {
            true: cn.grayBg,
        },
        white: {
            true: cn.white,
        },
        isViewLink: {
            true: cn.viewLink,
        },
        colorBtn: {
            orange: cn.orange,
            yellow: cn.yellow,
            red: cn.red,
            white: cn.white,
            blue: cn.blue,
        },
        withMinimalPadding: {
            true: cn.withMinimalPadding,
        },
        whiteBg: {
            true: cn.whiteBg,
        },
    },

    compoundVariants: [
        {
            animation: true,
            isAnimationEnabled: true,
            className: cn.animation,
        },
        {
            animation: true,
            isAnimationEnabled: true,
            wasInViewport: true,
            className: cn.animationInit,
        },
    ],
    defaultVariants: {
        disable: false,
    },
});
