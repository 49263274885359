import React, { createContext, ReactNode, useContext, useMemo } from 'react';

import { ThemeStore } from '@/domain/theme/ThemeStore';

const ThemeContext = createContext<ThemeStore | null>(null);

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
    const value = useMemo(() => new ThemeStore(), []);
    return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};

export const useTheme = () => useContext(ThemeContext);
