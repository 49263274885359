import React, { createContext, ReactNode, useContext, useMemo } from 'react';

import LayoutStore from '@/domain/core/LayoutStore';

const LayoutContext = createContext<LayoutStore | null>(null);

export const LayoutProvider = ({ children }: { children: ReactNode }) => {
    const value = useMemo(() => new LayoutStore(), []);
    return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>;
};

export const useLayoutStore = () => useContext(LayoutContext);
