import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import Image from 'next/image';
import { usePathname } from 'next/navigation';
import React from 'react';

import Menu from '@/components/layout/Header/Menu';
import Button from '@/components/UI/Button';
import { ButtonWithList } from '@/components/UI/button/ButtonWithList';
import IconLogo from '@/components/UI/IconLogo';
import Feature from '@/domain/feature/Feature';
import { IGtagHeader } from '@/types/header';
import { IHiddenMenu, ISimpleMenu } from '@/types/menu/menu';
import { event } from '@/components/shared/utilities/analytics/metrics';

interface IMenuConfig {
    show: boolean;
    active: string;
}

type HeaderBottomProps = {
    hideMenu: () => void;
    showMenu: (active: string) => void;
    gtag: IGtagHeader;
    logoLink: string;
    menuConfig: IMenuConfig;
    headerMainMenu: ISimpleMenu[];
    hiddenHeaderMenu: IHiddenMenu[];
    isBusinessPage?: boolean;
    mobileButton?: {
        text: string;
        link: string;
    };
};

const HeaderBottom: React.FC<HeaderBottomProps> = observer(
    ({
        hideMenu,
        gtag,
        logoLink = '/',
        menuConfig,
        showMenu,
        headerMainMenu,
        hiddenHeaderMenu,
        mobileButton,
        isBusinessPage,
    }) => {
        const asPath = usePathname();

        const isNewYearFlag = Feature.isFeatureEnabled('isNewYear');

        const shouldHideClientButton = asPath?.includes('/service/cashcredit');
        const showMobileButton = shouldHideClientButton && mobileButton?.text;
        const buttonBlurBorderText = isBusinessPage ? 'Почта Банк Бизнес' : 'Интернет-банк';
        const buttonBlurBorderLink = isBusinessPage
            ? 'https://online.pochtabank.ru/business/'
            : 'https://my.pochtabank.ru/';

        return (
            <div className="headerBottomWrap">
                {isNewYearFlag && (
                    <>
                        <Image
                            src="/img-next/svg/new-year/03_snow.svg"
                            width={63}
                            height={40}
                            alt="снежинка"
                            className="snow-03"
                        />
                        <div className="leftSnowBlock">
                            <Image
                                src="/img-next/svg/new-year/01_snow.svg"
                                width={81}
                                height={62}
                                alt="снежинка"
                                className="snow-01"
                            />
                            <Image
                                src="/img-next/svg/new-year/02_snow.svg"
                                width={89}
                                height={85}
                                alt="снежинка"
                                className="snow-02"
                            />
                            <Image
                                src="/img-next/svg/new-year/07_snow_mobile.svg"
                                width={52}
                                height={52}
                                alt="снежинка"
                                className="snow-07"
                            />
                        </div>
                        <div className="rightSnowBlock">
                            <Image
                                src="/img-next/svg/new-year/04_snow.svg"
                                width={69}
                                height={46}
                                alt="снежинка"
                                className="snow-04"
                            />
                            <Image
                                src="/img-next/svg/new-year/05_snow.svg"
                                width={61}
                                height={44}
                                alt="снежинка"
                                className="snow-05"
                            />
                            <Image
                                src="/img-next/svg/new-year/06_snow.svg"
                                width={67}
                                height={52}
                                alt="снежинка"
                                className="snow-06"
                            />
                        </div>
                    </>
                )}

                <div className="headerBottomGrid">
                    <div className="headerBottomItem headerBottomItemLeft">
                        <div className="headerLeft">
                            <div className="headerLeftItem headerLeftItemLogo" onMouseEnter={hideMenu}>
                                {isNewYearFlag && (
                                    <>
                                        <Image
                                            src="/img-next/svg/new-year/logo_snow_01.svg"
                                            width={60}
                                            height={16}
                                            alt="снежная шапка"
                                            className="snowCover_01"
                                        />

                                        <Image
                                            src="/img-next/svg/new-year/logo_snow_02.svg"
                                            width={57}
                                            height={15}
                                            alt="снежная шапка"
                                            className="snowCover_02"
                                        />
                                    </>
                                )}

                                <a
                                    href={logoLink}
                                    className="logo"
                                    onClick={() => {
                                        if (gtag?.logo?.action) event(gtag.logo);
                                    }}
                                >
                                    <IconLogo />
                                </a>
                            </div>
                            <div className={clsx('headerLeftItem', 'headerLeftItemMenu')}>
                                <Menu
                                    gtag={gtag}
                                    activeItem={menuConfig.active}
                                    showCollapsedMenu={showMenu}
                                    headerMainMenu={headerMainMenu}
                                    isBusinessPage={isBusinessPage}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={clsx('headerBottomItem', 'headerBottomItemRight')} onMouseEnter={hideMenu}>
                        <div className="headerRight">
                            <div className="headerRightWrap">
                                <div className={clsx('headerRightItem', 'headerRightItemLeft')}>
                                    {isBusinessPage ? (
                                        <Button
                                            variant="btnBlueBorder"
                                            type="link"
                                            href="https://lk.pochtabank.ru/Production/lk.nsf/Welcome.xsp"
                                            label="Зарплатный проект"
                                            size="small"
                                            customClass={clsx(
                                                'headerRightItemRightBusiness',
                                                shouldHideClientButton && 'headerRightItemHideMobile'
                                            )}
                                        />
                                    ) : (
                                        <ButtonWithList
                                            label="Онлайн-заявки"
                                            hiddenHeaderMenu={hiddenHeaderMenu}
                                            gtag={gtag?.client}
                                            customClass={shouldHideClientButton && 'headerRightItemHideMobile'}
                                            isFullHeight
                                        />
                                    )}
                                    {showMobileButton && (
                                        <Button
                                            variant="btnBlue"
                                            type="link"
                                            href={mobileButton?.link}
                                            label={mobileButton?.text || 'Заполнить заявку'}
                                            size="small"
                                            customClass="full header-button-show-mobile"
                                            animation={false}
                                        />
                                    )}
                                </div>
                                <div
                                    className={clsx(
                                        'headerRightItem',
                                        'headerRightItemRight',
                                        isBusinessPage && 'headerRightItemRightBusiness'
                                    )}
                                >
                                    <Button
                                        variant="btnBlue"
                                        type="link"
                                        href={buttonBlurBorderLink}
                                        target="_blank"
                                        label={buttonBlurBorderText}
                                        size="full"
                                        customClass={clsx('full', 'headerLoginBtn')}
                                        img="/img-next/svg/loginBank.svg"
                                        animation={false}
                                        onClick={() => {
                                            if (gtag?.bank?.action) event(gtag?.bank);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export default HeaderBottom;
