import LndAttribution, { getSearchParams, IAttribution, SimpleObject } from '@/domain/attribution/lnd-attribution';
import { SearchEngineDetector } from '@/domain/attribution/search-engine-detector';

class Attribution implements IAttribution {
    private readonly key = 'NONLND_storage';

    private readonly params = [
        { utm: 'utm_medium', save: 'utmMedium' },
        { utm: 'utm_source', save: 'utmSource' },
        { utm: 'utm_campaign', save: 'utmCampaign' },
        { utm: 'utm_term', save: 'utmTerm' },
        { utm: 'utm_content', save: 'utmContent' },
        { utm: 'yclid', save: 'yclId' },
        { utm: 'cpa_partner_id', save: 'cpaPartnerId' },
        { utm: 'cpa_click_id', save: 'cpaClickId' },
        { utm: 'ym_uid', save: 'ymUid' },
    ];

    private readonly searchEngineDetector = new SearchEngineDetector();

    private pickParams = (params: SimpleObject) => {
        const result = {};

        Object.entries(params).forEach(([key, value]) => {
            const foundedParam = this.params.find(param => param.utm === key);
            if (foundedParam) {
                result[foundedParam.save] = value;
            }
        });

        return result;
    };

    private static getFromLocalStorage = (key: string): string | null => {
        if (typeof window === 'undefined') {
            return null;
        }

        const value = window.localStorageProvider.getItem(key);

        return value;
    };

    private setToLocalStorage = (params: SimpleObject) => {
        if (typeof window === 'undefined') return;

        window.localStorageProvider.setItem(this.key, JSON.stringify(params));
    };

    public getMetrics(): SimpleObject {
        try {
            return JSON.parse(window.localStorage.getItem(this.key)) as SimpleObject;
        } catch (e) {
            console.error(e);

            return {};
        }
    }

    public execute(referrer: string, location: Location) {
        if (!window.localStorageProvider) {
            throw Error('Local storage provider is not defined');
        }

        /* при внутреннем переходе не сохраняем utm */
        if (referrer && new URL(referrer)?.host === location.host) {
            return;
        }

        const searchParams = getSearchParams(location.search);

        const resultParams = this.pickParams(searchParams);

        if (Object.keys(resultParams).length > 0) {
            this.setToLocalStorage(resultParams);

            return;
        }

        if (referrer?.length > 0) {
            const engine = this.searchEngineDetector.detect(referrer);

            if (engine) {
                this.setToLocalStorage({ utmSource: engine.source, utmMedium: 'organic' });

                return;
            }
            this.setToLocalStorage({ utmSource: referrer, utmMedium: 'referral' });

            return;
        }

        if (!Attribution.getFromLocalStorage(LndAttribution.attributionKey)) {
            this.setToLocalStorage({ utmSource: 'direct', utmMedium: 'none' });

            return;
        }

        const currentMetrics = LndAttribution.getMetrics();

        if (currentMetrics?.lndUtmSource === 'direct' && currentMetrics?.lndUtmMedium === 'none') {
            this.setToLocalStorage({ utmSource: 'direct', utmMedium: 'none' });

            return;
        }

        this.setToLocalStorage({ utmSource: 'direct', utmMedium: 'none' });
    }
}

export default new Attribution();
