import React, { FC } from 'react';

import Search from './search';
import cn from './style.module.sass';

const FooterSearch: FC = () => (
    <form action="https://www.pochtabank.ru/search" className={cn.footerSearch}>
        <input
            id="footer-search-input"
            className={cn.footerSearchInput}
            name="q"
            type="text"
            placeholder="Поиск по сайту"
            autoComplete="off"
        />
        <button type="submit" className={cn.footerSearchBtn}>
            <Search />
        </button>
    </form>
);

export default FooterSearch;
