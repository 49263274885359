import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';

import { BasicLink } from '@/components/UI/BasicLink';
import Feature from '@/domain/feature/Feature';
import { IGtagHeader } from '@/types/header';
import { ISimpleMenu } from '@/types/menu/menu';
import { getVarioqubFlags } from '@/utils/getVarioqubFlags';
import { event } from '@/components/shared/utilities/analytics/metrics';

import MenuIcon from './menuIcon';

export interface IMenu {
    /**
     * Доп. класс
     */
    customClass?: string;
    showCollapsedMenu?: (active: string) => void;
    activeItem?: string;
    isBusinessPage?: boolean;
    headerMainMenu: ISimpleMenu[];
    gtag?: IGtagHeader;
}

const Menu: FC<IMenu> = ({ activeItem, customClass, showCollapsedMenu, headerMainMenu, isBusinessPage, gtag }) => {
    // пока хардкод для экспериментов вариокуба - нужно сделать универсально
    const [isNewMenu, setIsNewMenu] = useState(false);
    const [isNewMenuSecond, setIsNewMenuSecond] = useState(false);
    const firstItemsHref = '/service/loans';
    const secondItemsHref = '/service/deposits';

    useEffect(() => {
        if (Feature.isFeatureEnabled('enableVarioqubFlags')) {
            getVarioqubFlags(
                () => setIsNewMenu(true),
                () => setIsNewMenuSecond(true)
            );
        }
    }, []);

    return (
        <nav className={clsx('menu', customClass)}>
            {headerMainMenu?.map(({ href, name, gtag: gtagEvent, code }, index) => {
                let currentHref = href;

                if (isNewMenu && name === 'Кредиты') {
                    currentHref = firstItemsHref;
                }
                if (isNewMenuSecond && name === 'Вклады') {
                    currentHref = secondItemsHref;
                }

                return (
                    <div
                        key={index}
                        className="menuItem"
                        onMouseEnter={() => {
                            showCollapsedMenu(
                                (isNewMenu && name === 'Кредиты') || (isNewMenuSecond && name === 'Вклады')
                                    ? ''
                                    : code || name
                            );
                        }}
                        onClick={() => {
                            if (gtagEvent?.action) event(gtagEvent);
                        }}
                        role="presentation"
                    >
                        {href || (isNewMenu && name === 'Кредиты') || (isNewMenuSecond && name === 'Вклады') ? (
                            <BasicLink
                                href={currentHref}
                                customClass={clsx('menuLink', activeItem === name && 'active')}
                            >
                                <span>{name}</span>
                            </BasicLink>
                        ) : (
                            <div className={clsx('menuLink', 'menuNoLink', activeItem === name && 'active')}>
                                <span>{name}</span>
                            </div>
                        )}
                    </div>
                );
            })}
            {headerMainMenu && !isBusinessPage && (
                <div
                    className="menuItem menuItemIcon"
                    onMouseEnter={() => {
                        showCollapsedMenu('other');
                    }}
                >
                    <MenuIcon />
                </div>
            )}
        </nav>
    );
};

export default Menu;
