import { useEffect } from 'react';

import Attribution from '@/domain/attribution/attribution';
import LNDAttribution from '@/domain/attribution/lnd-attribution';

export const useAttribution = () => {
    useEffect(() => {
        try {
            const { referrer, location } = document;

            LNDAttribution.execute(referrer, location);
            Attribution.execute(referrer, location);

            window.localStorageProvider.setItem(LNDAttribution.attributionKey, JSON.stringify('true'));
        } catch (error) {
            if (error instanceof Error) {
                console.error(`Attribution error: `, error.message);
            }
        }
    }, []);
};
