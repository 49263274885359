import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import Switch from 'react-switch';

// eslint-disable-next-line import/no-cycle
import Moon from '@/components/UI/BtnSwitchTheme/moon';
import Sun from '@/components/UI/BtnSwitchTheme/sun';
import { useTheme } from '@/context/ThemeProvider';
import { TTheme } from '@/types/theme';
import { GtagContext } from '@/utils/contexts';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

export interface BtnSwitchProps {
    isActive?: boolean;
    gtag?: GTagEvent;
}

export const BtnSwitchTheme: React.FC<BtnSwitchProps> = observer(({ gtag, isActive = true }) => {
    const { setTheme, theme } = useTheme();
    const context = useContext(GtagContext);

    const generateEvent = () => {
        if (gtag && gtag?.action) {
            event(gtag);
            return;
        }
        if (context?.theme?.action) {
            event(context?.theme);
        }
    };

    const handleChange = (nextChecked: boolean) => {
        const newTheme = nextChecked ? 'dark' : 'light';
        setTheme(newTheme);
        generateEvent();
    };

    const toggleTheme = (newTheme: TTheme) => {
        setTheme(newTheme);
        generateEvent();
    };

    return (
        <div className={clsx(cn.btnSwitch, isActive === false && cn.hidden)}>
            <button type="button" className={cn.btnSwitchItem} onClick={() => toggleTheme('light')}>
                <Sun />
            </button>
            <div className={cn.btnSwitchItem}>
                <Switch
                    onChange={handleChange}
                    checked={theme === 'dark'}
                    borderRadius={16}
                    width={35}
                    height={21}
                    handleDiameter={16}
                    onColor="#7F818F"
                    offColor="#7F818F"
                    className={cn.switch}
                    uncheckedIcon={false}
                    checkedIcon={false}
                />
            </div>
            <button type="button" className={cn.btnSwitchItem} onClick={() => toggleTheme('dark')}>
                <Moon />
            </button>
        </div>
    );
});
