import Image from 'next/image';
import React, { FC, RefObject } from 'react';

import AnimatedPromoDot from '@/components/blocks/animatedPromo/AnimatedPromoDot';
import Feature from '@/domain/feature/Feature';
import { IAnimatedPromoItem, ISwiperRef } from '@/types/animatedPromo/item';

interface IProps {
    list: IAnimatedPromoItem[];
    handleClick: (index: number) => void;
    active: number;
    swiperRef: RefObject<ISwiperRef>;
    setActive: (active: number) => void;
}

const isNewYearFlag = Feature.isFeatureEnabled('isNewYear');

const AnimatedPromoDotList: FC<IProps> = ({ handleClick, active, list, swiperRef, setActive }) => (
    <div className="animatedPromoDots">
        <div className="animatedPromoDotsWrap">
            {list?.map((item, index) => (
                <AnimatedPromoDot
                    key={`${index}-${item.title}`}
                    pos={index}
                    item={item}
                    listLength={list.length}
                    swiperRef={swiperRef}
                    handleClick={handleClick}
                    active={active}
                    setActive={setActive}
                    isNewYearFlag={isNewYearFlag && index === 0}
                />
            ))}
        </div>

        {isNewYearFlag && (
            <>
                <Image
                    src="/img-next/svg/new-year/slider_snow_01.svg"
                    alt="снег"
                    width={212}
                    height={34}
                    className="snowCoverLeft"
                />
                <Image
                    src="/img-next/svg/new-year/slider_snow_02.svg"
                    alt="снег"
                    width={88}
                    height={78}
                    className="snowCoverRight"
                />
            </>
        )}
    </div>
);
export default AnimatedPromoDotList;
