'use client';

import clsx from 'clsx';
import React from 'react';

import ConfirmEmailForm from '@/components/blocks/forms/ConfirmEmailForm/form';
import CustomImage from '@/components/CustomImage';

import cn from './style.module.sass';

export interface IConfirmEmail {
    _template?: 'confirmEmailForm';
}

const ConfirmEmail: React.FC<IConfirmEmail> = ({ _template = 'confirmEmailForm' }) => (
    <div className={clsx(cn.wrapper, 'section')}>
        <div className={clsx(cn.fullWrapper)}>
            <div className={clsx(cn.content)}>
                <div className={clsx(cn.imageContent)}>
                    <div className={cn.imageWrapper}>
                        <div className={cn.image}>
                            <CustomImage
                                src="/img-next/png/confirm-email.png"
                                alt="Подтверждение почты"
                                mockWidth="240"
                                mockHeight="198"
                                fill
                            />
                        </div>
                    </div>
                </div>
                <div className={clsx(cn.formContent)}>
                    <ConfirmEmailForm />
                </div>
            </div>
        </div>
    </div>
);

export default ConfirmEmail;
