import { makeAutoObservable } from 'mobx';

import { IGeoGroups } from '@/types/geoGroups';

export interface IGeoStore {
    geoPages: string[];
    setGeoPages: (items: string[]) => void;
    geoGroups?: IGeoGroups[];
    setGeoGroups: (items: IGeoGroups[]) => void;
}

export default class GeoStore implements IGeoStore {
    public geoPages: string[] = [];

    public geoGroups: IGeoGroups[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    public setGeoGroups = (items: IGeoGroups[]) => {
        this.geoGroups = [...this.geoGroups, ...items];
    };

    public setGeoPages = (items: string[]) => {
        this.geoPages = [...this.geoPages, ...items];
    };
}
