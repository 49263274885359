/* eslint-disable prefer-destructuring */
export const FRONT_URL = process.env.FRONT_URL;
export const FRONT_ROOT_URL = process.env.FRONT_ROOT_URL;
export const STORYBOOK_FRONT_URL = process.env.STORYBOOK_FRONT_URL;
export const INNER_SECRET_KEY = process.env.INNER_SECRET_KEY;
const NEXT_PUBLIC_PROXY_BACK_URL = process.env.NEXT_PUBLIC_PROXY_BACK_URL;
const NEXT_PUBLIC_DEFAULT_URL = process.env.NEXT_PUBLIC_DEFAULT_URL;
const PROXY_BACK_URL = process.env.PROXY_BACK_URL;
export const TIME_CACHE = process.env.TIME_CACHE || 900;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
const publicRuntimeConfig = { IS_BUILD: process.env.IS_BUILD || 'FALSE' };
export const CDN_URL = process.env.CDN_URL;

export const ENVIRONMENT = process.env.APP_ENV;

export const isLocal = process.env.NEXT_PUBLIC_IS_LOCAL === 'TRUE';
export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const IS_SANDBOX = process.env.IS_SANDBOX === 'TRUE';
export const IS_CDN_ENABLED = process.env.IS_CDN_ENABLED === 'TRUE' || process.env.IS_CDN_ENABLED === 'true';
export const IS_BUILD = (publicRuntimeConfig as { IS_BUILD: string }).IS_BUILD === 'TRUE';

export const protocol = IS_PRODUCTION || isLocal ? 'https://' : 'http://';
// LOCAL_API_ROUTE. Используется для локальной разработки без подъема кубера
// Подробнее в документации проекта.
const LOCAL_API_ROUTE = IS_BUILD ? PROXY_BACK_URL : NEXT_PUBLIC_PROXY_BACK_URL;

// API_ROUTE. Используется для обычных запросов (города, дозапрос информации о блоках)
// Также заменяет собой privateUrl во время билда но с ключем INNER_SECRET_KEY
const API_URL = IS_BUILD ? `${protocol}${FRONT_URL}/bxapi` : '/bxapi';
export const API_ROUTE = isLocal ? LOCAL_API_ROUTE : API_URL;

// Внутренний адрес пода, используется для privateUrl
export const INNER_API_URL = process.env.INNER_BACK_BXAPI;

// PRIVATE_API_ROUTE. Используется для защищеных запросов непосредственно по имени пода.
// Может использоваться только на серверной части проекта
const PRIVATE_API_MAIN = isLocal ? LOCAL_API_ROUTE : `http://${INNER_API_URL}/bxapi/`;
export const PRIVATE_API_ROUTE = IS_BUILD ? API_ROUTE : PRIVATE_API_MAIN;

// PRIVATE_API_ROUTE_NEXT. Используется для защищеных запросов непосредственно по имени пода к NEXT JS.
// Может использоваться только на серверной части проекта
// Не должен использоваться при билде!!!
export const PRIVATE_API_ROUTE_NEXT = isLocal ? `${NEXT_PUBLIC_DEFAULT_URL}/api/` : `http://${INNER_API_URL}/api/`;

export const TABLET_MOBILE = 1023;
export const TABLET_WIDTH = 1152;
export const TABLET_SMALL_WIDTH = 1024;
export const MOBILE_WIDTH = 767;
export const MOBILE_SM_WIDTH = 576;

export const DAY_IN_YEAR = 366;
