import clsx from 'clsx';
import React from 'react';

import TextField from '@/components/TextField';

import cn from './style.module.sass';

export interface ITitle {
    /**
     * Заголовок
     */
    title: string;

    // eslint-disable-next-line no-magic-numbers
    level: 1 | 2 | 3 | 4 | 5 | 6;
    /**
     * Дополнительный класс
     */
    customClass?: string;
    name?: string;
    [name: string]: unknown;
}

const Title: React.FC<ITitle> = ({ title, level, customClass, name = '', ...rest }) => (
    <TextField
        text={title}
        name={name}
        customTag={`h${level}`}
        className={clsx(cn.title, cn[`h${level}`], customClass)}
        {...rest}
    />
);

export default Title;
