import React from 'react';

function TooltipIcon() {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z"
                className="promoSvgFillWhite"
            />
            <path
                d="M14.2 17.8C14.2 18.4627 13.6627 19 13 19C12.3372 19 11.8 18.4627 11.8 17.8C11.8 17.1373 12.3372 16.6 13 16.6C13.6627 16.6 14.2 17.1373 14.2 17.8Z"
                className="promoSvgFillDark"
            />
            <rect x="11.8" y="7" width="2.4" height="8.4" rx="1.2" className="promoSvgFillDark" />
        </svg>
    );
}

export default TooltipIcon;
