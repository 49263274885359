import React, { createContext, ReactNode, useContext, useMemo } from 'react';

import BreadcrumbsStore from '@/domain/breadcrumbs/BreadcrumbsStore';
import { IBreadcrumbs } from '@/types/breadcrumbs';

const BreadcrumbsContext = createContext<BreadcrumbsStore | null>(null);

export const BreadcrumbsProvider = ({
    children,
    initialState,
}: {
    children: ReactNode;
    initialState: IBreadcrumbs;
}) => {
    const list = initialState?.list || null;
    const isActive = initialState?.isActive || false;
    const color = initialState?.color || null;
    const value = useMemo(() => new BreadcrumbsStore(list, isActive, color), []);
    return <BreadcrumbsContext.Provider value={value}>{children}</BreadcrumbsContext.Provider>;
};

export const useBreadcrumbs = () => useContext(BreadcrumbsContext);
