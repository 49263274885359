import Image, { ImageLoader, ImageProps } from 'next/image';
import React from 'react';

import { useAppStore } from '@/context/AppStoreContext';
import { IS_CDN_ENABLED } from '@/utils/constants';
import { getImage } from '@/utils/getImage';

declare const VALID_LAYOUT_VALUES: readonly ['fill', 'fixed', 'intrinsic', 'responsive', undefined];
declare type LayoutValue = (typeof VALID_LAYOUT_VALUES)[number];
declare type PlaceholderValue = 'blur' | 'empty';
declare type StringImageProps = {
    src: string;
    priority?: boolean;
    loading?: string;
} & (
    | {
          width?: never;
          height?: never;
          fill: boolean;
      }
    | {
          width: number | string;
          height: number | string;
          layout?: Exclude<LayoutValue, 'fill'>;
      }
) &
    (
        | {
              placeholder?: Exclude<PlaceholderValue, 'blur'>;
              blurDataURL?: never;
          }
        | {
              placeholder: 'blur';
              blurDataURL: string;
          }
    );

type TCustomImage = ImageProps &
    StringImageProps &
    ({ mockHeight?: never; mockWidth?: never } | { mockHeight: string | number; mockWidth: string | number });

const ImageWithLoader: React.FC<TCustomImage> = props => {
    const loader: ImageLoader = ({ src, width, quality = 90 }) =>
        `${process.env.CDN_URL}/_next/image?url=${src}&w=${width}&q=${quality}`;

    if (IS_CDN_ENABLED) {
        return <Image {...props} loader={loader} />;
    }

    return <Image {...props} />;
};

const CustomImage: React.FC<TCustomImage> = ({
    src,
    mockHeight,
    mockWidth,
    priority = false,
    loading = 'lazy',
    ...rest
}) => {
    const [isTinaView] = useAppStore(store => store.isTinaView);

    if (src)
        return (
            <ImageWithLoader quality={90} priority={priority} loading={loading} {...(rest as TCustomImage)} src={src} />
        );

    if (!isTinaView) return null;

    const mockSizedImage = getImage(mockWidth, mockHeight);

    if (mockHeight && mockWidth && mockSizedImage) {
        return (
            <ImageWithLoader
                quality={90}
                priority={priority}
                loading={loading}
                {...(rest as TCustomImage)}
                src={mockSizedImage}
            />
        );
    }

    const defaultSizedImage = getImage(rest?.width, rest?.height);

    if (rest?.width && rest?.height && defaultSizedImage) {
        return (
            <ImageWithLoader
                quality={90}
                loading={loading}
                priority={priority}
                {...(rest as TCustomImage)}
                src={defaultSizedImage}
            />
        );
    }

    return null;
};

export default CustomImage;
