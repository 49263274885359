import React from 'react';

import cn from './style.module.sass';

const CloseIcon: React.FC = () => (
    <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={cn.closeIcon}
    >
        <path
            d="M8.59844 1.39941L1.39844 8.59941"
            className={cn.stroke}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M1.39844 1.39941L8.59844 8.59941"
            stroke="white"
            className={cn.stroke}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default CloseIcon;
