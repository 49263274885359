export interface ISessionStorageProvider {
    readonly isEnabled: boolean;
    getItem: (key: string) => string | null;
    setItem: (key: string, value: string) => void;
}

class SessionStorageProvider implements ISessionStorageProvider {
    isEnabled = typeof window !== 'undefined' && window.navigator.cookieEnabled;

    getItem = key => {
        if (this.isEnabled) return window.sessionStorage.getItem(key);
        return null;
    };

    setItem = (key, value) => this.isEnabled && window.sessionStorage.setItem(key, value);
}

export default SessionStorageProvider;
