import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

import CustomImage from '@/components/CustomImage';
import TextField from '@/components/TextField';
import { ISpecial } from '@/types/promo/item';

const PromoSpecial: FC<ISpecial> = ({ text, desc, imageSpecial }) => (
    <div className="promoSpecialWrap">
        <div className="promoSpecialInnerWrap">
            <div className="promoSpecialContent">
                {text && (
                    <TextField name="special.text" text={text} isHTML htmlValue={text} className="promoSpecialTitle" />
                )}
                {desc && (
                    <TextField name="special.desc" text={desc} isHTML htmlValue={desc} className="promoSpecialDesc" />
                )}
            </div>
            <div className="promoSpecialImg">
                <CustomImage src={imageSpecial} alt="Почтабанк" fill />
            </div>
        </div>
    </div>
);

export default observer(PromoSpecial);
