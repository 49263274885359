import React, { FC } from 'react';

import cn from './style.module.sass';

const Icon: FC = () => (
    <svg width="7" height="5" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L3.5 3.5L6 1" className={cn.svgStroke} strokeWidth="1.2" />
    </svg>
);

export default Icon;
