import clsx from 'clsx';
import React, { FC } from 'react';

import FooterMenu from '@/components/layout/Footer/Footer/footerMenu';
import FooterProducts from '@/components/layout/Footer/Footer/footerProducts';
import FooterUsers from '@/components/layout/Footer/Footer/footerUsers';
import Title from '@/components/UI/Title';
import { IFooterItem, IFooterItemMenu } from '@/types/footer';

import CloseIcon from './closeIcon';
import cn from './style.module.sass';

export interface IMapSite {
    products?: IFooterItem[];
    users?: IFooterItem[];
    menu?: IFooterItemMenu[];
    open: boolean;
    close: () => void;
}

const MapSite: FC<IMapSite> = ({ products, users, menu, open, close }) => (
    <div className={clsx(cn.map, open && cn.open)}>
        <div className={cn.wrapper}>
            <div className={cn.top}>
                <div className={cn.topGrid}>
                    <div className={clsx(cn.topItem, cn.topItemTitle)}>
                        <Title title="Меню сайта" level={3} customClass={cn.topTitle} />
                    </div>
                    <div className={clsx(cn.topItem, cn.topItemClose)}>
                        <button type="button" onClick={() => close()} className={cn.close}>
                            <CloseIcon />
                        </button>
                    </div>
                </div>
            </div>
            <div className={cn.content}>
                <div className={cn.row}>
                    {products && <FooterProducts products={products} />}
                    {users && <FooterUsers users={users} />}
                </div>
                {menu && (
                    <div className={cn.row}>
                        <FooterMenu menu={menu} />
                    </div>
                )}
            </div>
        </div>
    </div>
);

export default MapSite;
