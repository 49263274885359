import { mergeAttributes, Node } from '@tiptap/core';
import { ReactNodeViewRenderer } from '@tiptap/react';

import Component from './QuestionmarkWrapper';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        reactComponent: {
            setComponent: (value: string, color: string) => ReturnType;
        };
    }
}

export default Node.create({
    name: 'questionMark',
    group: 'inline',
    inline: true,
    atom: true,
    addAttributes() {
        return {
            text: {
                default: '',
            },
            textTooltip: {
                default: '',
            },
            questionMarkColor: {
                default: '',
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'question-mark',
            },
        ];
    },

    addCommands() {
        return {
            setComponent:
                (value: string, color: string) =>
                ({ commands, state }) => {
                    const { from, to } = state.selection;
                    const text = state.doc.textBetween(from, to, ' ');

                    return commands.insertContent({
                        type: this.name,
                        attrs: {
                            text,
                            textTooltip: value,
                            questionMarkColor: color,
                        },
                    });
                },
        };
    },

    addKeyboardShortcuts() {
        return {
            'Mod-Enter': () =>
                this.editor
                    .chain()
                    .insertContentAt(this.editor.state.selection.head, { type: this.type.name })
                    .focus()
                    .run(),
        };
    },

    renderHTML({ HTMLAttributes }) {
        return ['question-mark', mergeAttributes(HTMLAttributes)];
    },

    addNodeView() {
        return ReactNodeViewRenderer(Component);
    },
});
