import { makeAutoObservable } from 'mobx';

import { IBreadcrumbs } from '@/types/breadcrumbs';

interface IBreadcrumbsElement {
    name: string;
    link: string;
}

export interface IBreadcrumbsStore {
    list: IBreadcrumbsElement[];
    isActive: boolean;
    color: 'white' | 'grey' | string;
    hydrate: (data: IBreadcrumbs) => void;
}

class BreadcrumbsStore implements IBreadcrumbsStore {
    public list: IBreadcrumbsElement[] = null;

    public color: 'white' | 'grey' | string = null;

    public isActive = false;

    public hydrate = (data: IBreadcrumbs) => {
        this.list = data?.list;
        this.isActive = data?.isActive;
        this.color = data?.color;
    };

    constructor(list: IBreadcrumbsElement[], isActive: boolean, color: 'white' | 'grey' | string) {
        makeAutoObservable(this);
        this.list = list;
        this.isActive = isActive;
        this.color = color;
    }
}

export default BreadcrumbsStore;
