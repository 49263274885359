import clsx from 'clsx';
import Image from 'next/image';
import React, { RefObject, useEffect, useRef, useState } from 'react';

import CustomImage from '@/components/CustomImage';
import useBeingInViewport from '@/hooks/useBeingInViewport';
import { useResize } from '@/hooks/useResize';
import { IAnimatedPromoItem, ISwiperRef } from '@/types/animatedPromo/item';
import { TABLET_WIDTH } from '@/utils/constants';
import { event } from '@/components/shared/utilities/analytics/metrics';

interface IProps {
    item: IAnimatedPromoItem;
    handleClick: (index: number) => void;
    active: number;
    pos: number;
    listLength: number;
    swiperRef: RefObject<ISwiperRef>;
    setActive: (active: number) => void;
    isNewYearFlag: boolean;
}

const AnimatedPromoDot: React.FC<IProps> = ({
    handleClick,
    active,
    item,
    pos,
    listLength,
    swiperRef,
    setActive,
    isNewYearFlag,
}) => {
    const [background, setBackground] = useState<string>(null);
    const [ready, setReady] = useState<boolean>(false);
    const [isActive, setIsActive] = useState<boolean>(false);

    const ref = useRef<HTMLButtonElement>(null);

    const isVisible = useBeingInViewport(ref, 0, true);
    const width = useResize();

    const { image, title, subtitle, banner, titleMobile } = item;

    const [correctTitle, setCorrectTitle] = useState<string>(title);

    const isTabletLg = width <= TABLET_WIDTH && typeof window !== 'undefined';

    const onClick = () => {
        handleClick(pos);
        if (banner?.dotGtag?.action) event(banner?.dotGtag);
    };

    const onAnimationEnd = () => {
        if (!isVisible) return;
        const nextSlide = listLength - 1 === pos ? 0 : pos + 1;
        swiperRef?.current?.swiper?.slideTo(nextSlide + 1, 300);
        setActive(nextSlide);
    };

    useEffect(() => {
        if (isTabletLg) {
            setCorrectTitle(titleMobile);
        } else setCorrectTitle(title);
    }, [isTabletLg, titleMobile, title]);

    useEffect(() => {
        setReady(true);
    }, []);

    useEffect(() => {
        setIsActive(active === pos);
    }, [active, pos]);

    useEffect(() => {
        if (!isTabletLg) {
            setBackground(null);
            return undefined;
        }
        if (banner?.backgroundImage) {
            setBackground(`url(${banner?.backgroundImage})`);
            return undefined;
        }
        setBackground(banner.background);
        return undefined;
    }, [isTabletLg]);

    return (
        <button
            type="button"
            aria-label="Dots"
            className={clsx(
                'animatedPromoDotsItem',
                `animatedPromoDotsItem-${listLength}`,
                isActive ? 'animatedPromoDotsItemActive' : 'animatedPromoDotsItemInActive'
            )}
            onClick={onClick}
            ref={ref}
        >
            <div className="animatedPromoDotsItemWrap">
                <div className="animatedPromoDotsItemWrapBg">
                    <div className="animatedPromoDotsItemImg" style={{ background }}>
                        <div className="animatedPromoDotsItemImgWrap">
                            <CustomImage
                                src={image}
                                alt={title}
                                fill
                                sizes="(max-width: 768px) 10vw,
                                      (max-width: 1200px) 5vw,
                                      5vw"
                                mockWidth={72}
                                mockHeight={52}
                            />
                        </div>
                        {isActive && (
                            <div
                                className={clsx('animatedPromoProgress', (!ready || !isVisible) && 'paused')}
                                onAnimationEnd={onAnimationEnd}
                            />
                        )}
                    </div>
                    <div className="animatedPromoDotsItemContent">
                        {isActive && (
                            <h1
                                className="animatedPromoDotsItemTitle"
                                dangerouslySetInnerHTML={{
                                    __html: correctTitle,
                                }}
                            />
                        )}
                        {!isActive && (
                            <div
                                className="animatedPromoDotsItemTitle"
                                dangerouslySetInnerHTML={{
                                    __html: correctTitle,
                                }}
                            />
                        )}
                        {subtitle && (
                            <div
                                className="animatedPromoDotsItemSubtitle"
                                dangerouslySetInnerHTML={{
                                    __html: subtitle,
                                }}
                            />
                        )}
                    </div>
                </div>
                {isNewYearFlag && (
                    <Image
                        src="/img-next/svg/new-year/slider_snow_02.svg"
                        alt="снег"
                        width={88}
                        height={78}
                        className="snowCoverRightMobile"
                    />
                )}
            </div>
        </button>
    );
};

export default AnimatedPromoDot;
