export class SearchEngineDetector {
    private readonly engines = [
        { domain: 'www.google.com', source: 'google' },
        { domain: 'ya.ru', source: 'yandex' },
        { domain: 'yandex.ru', source: 'yandex' },
        { domain: 'www.bing.com', source: 'bing' },
        { domain: 'duckduckgo.com', source: 'duckduckgo' },
        { domain: 'mail.ru', source: 'mail' },
        { domain: 'nova.rambler.ru', source: 'rambler' },
        { domain: 'search.yahoo.com', source: 'yahoo' },
        { domain: 'www.ecosia.org', source: 'ecosia' },
        { domain: 'www.baidu.com', source: 'baidu' },
        { domain: 'www.ask.com', source: 'ask' },
    ];

    public detect = (referrer?: string): { domain: string; source: string } | undefined => {
        if (typeof window === 'undefined') {
            return undefined;
        }

        const ref = referrer ?? window.document.referrer;

        return this.engines.find(item =>
            /* prettier-ignore */
            new RegExp(item.domain).test(ref)
        );
    };
}
