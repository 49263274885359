import React, { createContext, ReactNode, useContext, useMemo } from 'react';

import City from '@/domain/city/store';

const CityContext = createContext<typeof City | null>(null);

export const CityProvider = ({ children }: { children: ReactNode }) => {
    const value = useMemo(() => City, [City]);
    return <CityContext.Provider value={value}>{children}</CityContext.Provider>;
};

export const useCity = () => useContext(CityContext);
