import clsx from 'clsx';
import React from 'react';

import CustomImage from '@/components/CustomImage';
import { IHeaderSocial } from '@/types/header';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

function HeaderSocial({ gtag, text, link, image }: IHeaderSocial) {
    const generateEvent = () => {
        if (gtag?.action) event(gtag);
    };

    return (
        <div className={cn.headerSocialItem}>
            <a
                href={link}
                target="_blank"
                className={clsx(cn.headerSocialItemLink)}
                rel="noreferrer"
                onClick={generateEvent}
            >
                {image && <CustomImage src={image} alt={text} width={39} height={39} />}
                <span>{text}</span>
            </a>
        </div>
    );
}

export default HeaderSocial;
