import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';

import cn from './style.module.sass';

interface ICustomSlider {
    className?: string;
    isStartFromEnd?: boolean;
    children?: React.ReactNode;
}

const CustomSlider: React.FC<ICustomSlider> = ({ children, className, isStartFromEnd }) => {
    const slider = useRef<HTMLDivElement>(null);
    const [isDown, setIsDown] = useState<boolean>(false);
    const [startX, setStartX] = useState<number | null>(null);
    const [scrollLeft, setScrollLeft] = useState<number | null>(null);

    const onMouseDown = (e: MouseEvent) => {
        setIsDown(true);
        if (slider.current) {
            const { offsetLeft } = slider.current;
            if (offsetLeft !== undefined) {
                setStartX(e.pageX - offsetLeft);
            }
            setScrollLeft(slider.current.scrollLeft);
        }
    };

    const onMouseUp = (e: MouseEvent) => {
        setIsDown(false);
    };

    const onMouseMove = (e: MouseEvent) => {
        if (!isDown) return;
        if (slider.current && startX && scrollLeft) {
            const x = e.pageX - slider.current.offsetLeft;
            const walk = x - startX;
            slider.current.scrollLeft = scrollLeft - walk;
        }
    };

    useEffect(() => {
        if (isStartFromEnd && slider.current) {
            slider.current.scrollLeft = slider.current.offsetWidth;
        }
    }, [isStartFromEnd]);

    useEffect(() => {
        const domNode = slider.current;
        if (domNode) {
            domNode.addEventListener('mousedown', onMouseDown);
        }
        return () => {
            domNode?.removeEventListener('mousedown', onMouseDown);
        };
    }, [scrollLeft, isDown]);

    useEffect(() => {
        document.addEventListener('mouseup', onMouseUp);
        return () => {
            document.removeEventListener('mouseup', onMouseUp);
        };
    }, [scrollLeft, isDown]);

    useEffect(() => {
        if (isDown) {
            document.addEventListener('mousemove', onMouseMove);
        }
        return () => {
            document.removeEventListener('mousemove', onMouseMove);
        };
    }, [isDown, scrollLeft]);

    return (
        <div className={clsx(cn.slider, 'customSlider', className)} ref={slider}>
            {children}
        </div>
    );
};

export default CustomSlider;
