import { SearchEngineDetector } from '@/domain/attribution/search-engine-detector';

export type SimpleObject = Record<string, string>;

export function getSearchParams(searchString: string): SimpleObject {
    const result = {};

    new URLSearchParams(searchString).forEach((value, key) => {
        result[key] = value;
    });

    return result;
}

export function debug(text: string) {
    console.info(`%c LND: ${text}`, 'color: MediumSeaGreen;');
}

export interface IAttribution {
    execute(referrer: string, location: Location): void;

    getMetrics(): SimpleObject;
}

class LNDAttribution implements IAttribution {
    private readonly key = 'LND_Storage';

    public readonly attributionKey = 'attributionKey_storage';

    private readonly params = [
        { utm: 'utm_medium', save: 'lndUtmMedium' },
        { utm: 'utm_campaign', save: 'lndUtmCampaign' },
        { utm: 'utm_source', save: 'lndUtmSource' },
        { utm: 'utm_term', save: 'lndUtmTerm' },
        { utm: 'utm_content', save: 'lndUtmContent' },
        { utm: 'yclid', save: 'lndYclId' },
        { utm: 'cpa_partner_id', save: 'lndCpaPartnerId' },
        { utm: 'cpa_click_id', save: 'lndCpaClickId' },
        { utm: 'utm_omd', save: 'utm_omd' },
        { utm: 'erid', save: 'erid' },
        { utm: 'rb_clickid', save: 'rb_clickid' },
        { utm: 'clientid', save: 'clientid' },
        { utm: 'adr_id', save: 'adr_id' },
        { utm: 'vk_id', save: 'vk_id' },
        { utm: 'generation_id', save: 'generation_id' },
    ];

    private readonly searchEngineDetector = new SearchEngineDetector();

    private setToLocalStorage = (params: SimpleObject) => {
        if (typeof window === 'undefined') return;

        window.localStorageProvider.setItem(this.key, JSON.stringify(params));
    };

    private static getFromLocalStorage = (key: string): string | null => {
        if (typeof window === 'undefined') {
            return null;
        }

        const value = window.localStorageProvider.getItem(key);

        return value;
    };

    private pickParams = (params: SimpleObject) => {
        const result = {};

        Object.entries(params).forEach(([key, value]) => {
            const foundedParam = this.params.find(param => param.utm === key);
            if (foundedParam) {
                result[foundedParam.save] = value;
            }
        });

        return result;
    };

    public getMetrics(): SimpleObject {
        try {
            return JSON.parse(window.localStorage.getItem(this.key)) as SimpleObject;
        } catch (e) {
            console.error(e);

            return {};
        }
    }

    public execute(referrer: string, location: Location) {
        if (!window.localStorageProvider) {
            throw Error('Local storage provider is not defined');
        }

        /* при внутреннем переходе не сохраняем utm */
        if (referrer && new URL(referrer)?.host === location.host) {
            return;
        }

        const searchParams = getSearchParams(location.search);

        const resultParams = this.pickParams(searchParams);

        if (Object.keys(resultParams).length > 0) {
            this.setToLocalStorage(resultParams);

            return;
        }

        if (referrer?.length > 0) {
            const engine = this.searchEngineDetector.detect(referrer);

            if (engine) {
                this.setToLocalStorage({ lndUtmSource: engine.source, lndUtmMedium: 'organic' });

                return;
            }
            this.setToLocalStorage({ lndUtmSource: referrer, lndUtmMedium: 'referral' });

            return;
        }

        if (!LNDAttribution.getFromLocalStorage(this.attributionKey)) {
            this.setToLocalStorage({ lndUtmSource: 'direct', lndUtmMedium: 'none' });

            return;
        }

        const currentMetrics = this.getMetrics();

        if (currentMetrics?.lndUtmSource === 'direct' && currentMetrics?.lndUtmMedium === 'none') {
            this.setToLocalStorage({ lndUtmSource: 'direct', lndUtmMedium: 'none' });
        }
    }
}

export default new LNDAttribution();
