import clsx from 'clsx';
import React, { FC } from 'react';

type IPreloader = {
    enablePadding?: boolean;
    withHeight?: boolean;
};

const PreloaderBlocks: FC<IPreloader> = ({ enablePadding, withHeight = true }) => (
    <div
        className={clsx('cssload-container', 'preloader', enablePadding && 'enablePadding', withHeight && 'withHeight')}
    >
        <div className="cssload-speeding-wheel" />
    </div>
);

export default PreloaderBlocks;
