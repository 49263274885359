import axios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';

import { API_ROUTE, INNER_SECRET_KEY, IS_SANDBOX, PRIVATE_API_ROUTE, PRIVATE_API_ROUTE_NEXT } from '@/utils/constants';

export const nextApiRequest = axios.create({
    baseURL: '/api',
    withCredentials: true,
});

// Использовать только на серверной части!!!
// ИСПОЛЬЗОВАТЬ INNER_SECRET_KEY ТОЛЬКО В ПРИВАТНЫХ РОУТАХ
export const privateNextApiRequest = axios.create({
    baseURL: PRIVATE_API_ROUTE_NEXT,
    withCredentials: true,
    headers: {
        platform: IS_SANDBOX ? 'sandbox' : 'base',
        'Inner-Secret-Key': INNER_SECRET_KEY,
    },
});

interface privateAxiosRequestConfig extends AxiosRequestConfig {
    next?: NextFetchRequestConfig | undefined;
}
interface privateAxiosInstance extends AxiosInstance {
    (url: string, config?: privateAxiosRequestConfig): AxiosPromise;
}

// ИСПОЛЬЗОВАТЬ INNER_SECRET_KEY ТОЛЬКО В ПРИВАТНЫХ РОУТАХ
export const privateRequest: privateAxiosInstance = axios.create({
    baseURL: PRIVATE_API_ROUTE,
    withCredentials: true,
    headers: {
        platform: IS_SANDBOX ? 'sandbox' : 'base',
        'Inner-Secret-Key': INNER_SECRET_KEY,
    },
});

// НЕ ИСПОЛЬЗОВАТЬ INNER_SECRET_KEY
export const apiRequest = axios.create({
    baseURL: API_ROUTE,
    withCredentials: true,
    headers: {
        platform: IS_SANDBOX ? 'sandbox' : 'base',
    },
});

// Пока отказался от этого, вроде бы не совсем удовлетворяет условиям.

export const authApiRequest = axios.create({
    baseURL: API_ROUTE,
    withCredentials: true,
});

const authMiddleware = async (config: AxiosRequestConfig) => {
    await nextApiRequest.get('/auth-check');
    return config;
};

authApiRequest.interceptors.request.use(authMiddleware, error => {
    /* eslint-disable-next-line */
    Promise.reject(error);
});
