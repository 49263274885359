import clsx from 'clsx';
import React, { FC } from 'react';

import { BasicLink } from '@/components/UI/BasicLink';
import { IFooter } from '@/types/footer';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

const FooterProducts: FC<IFooter & { hideOnMobile?: boolean }> = ({ products, hideOnMobile }) => (
    <div className={clsx(cn.footerProducts, hideOnMobile && cn.hideOnMobile)}>
        <div className={cn.footerProductsWrap}>
            <div className={cn.footerProductsGrid}>
                {products?.map(({ link, text, gtag }, index) => (
                    <div className={cn.footerProductsItem} key={`${index}-${text}`}>
                        <BasicLink customClass={clsx(cn.footerProductsLink)} href={link} onClick={() => event(gtag)}>
                            {text}
                        </BasicLink>
                    </div>
                ))}
            </div>
        </div>
    </div>
);

export default FooterProducts;
