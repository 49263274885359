'use client';

import React, { createContext, ReactNode, useCallback, useContext, useRef, useSyncExternalStore } from 'react';

import { AppStore } from '@/domain/core/AppStore';

type PageStoreDataReturnType = {
    get: () => AppStore;
    set: (value: Partial<AppStore>) => void;
    subscribe: (callback: () => void) => () => void;
    initialState: AppStore;
};

type PageStoreReturnType<SelectorOutput> = [SelectorOutput, (value: Partial<AppStore>) => void];
function useAppStoreData(initialState: AppStore): PageStoreDataReturnType {
    const store = useRef(initialState);
    const get = useCallback(() => store.current, []);

    const subscribers = useRef(new Set<() => void>());

    const set = useCallback((value: Partial<AppStore>) => {
        store.current = { ...store.current, ...value };

        subscribers.current.forEach(callback => callback());
    }, []);

    const subscribe = useCallback((callback: () => void) => {
        subscribers.current.add(callback);

        return () => subscribers.current.delete(callback);
    }, []);

    return {
        get,
        set,
        subscribe,
        initialState,
    };
}

const AppStoreContext = createContext<ReturnType<typeof useAppStoreData> | null>(null);

const AppStoreProvider = ({ children, initialState }: { children: ReactNode; initialState: AppStore }) => {
    const value = useAppStoreData(initialState);
    return <AppStoreContext.Provider value={value}>{children}</AppStoreContext.Provider>;
};

function useAppStore<SelectorOutput>(
    selector: (store: AppStore) => SelectorOutput
): PageStoreReturnType<SelectorOutput> {
    const store = useContext(AppStoreContext);

    if (!store) {
        throw new Error('Store not found');
    }

    const state = useSyncExternalStore(
        store.subscribe,
        () => selector(store.get()),
        () => selector(store.initialState)
    );

    return [state, store.set];
}

export { AppStoreProvider, useAppStore };
