'use client';

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useState } from 'react';

import { findPrelaoderVarianrtsByType } from './PreloaderVariants';

export interface IPreloaderProps {
    type?: string;
    loadingInit?: boolean;
    timeoutLoader?: number;
    defaultLoading?: boolean;
}

const Preloader: React.FC<IPreloaderProps> = ({ type, loadingInit = false, timeoutLoader, defaultLoading = true }) => {
    const [loading, setLoading] = useState(() => loadingInit);
    useEffect(() => {
        const isLoaded: () => void = () => setTimeout(() => setLoading(!window.hasLoadedPage), timeoutLoader || 2000);
        if (typeof window !== undefined && loadingInit) {
            isLoaded();
        }
    }, []);
    if (!loading && !defaultLoading) return null;
    const Component = findPrelaoderVarianrtsByType(type);

    return <Component />;
};

export default Preloader;
