import { observer } from 'mobx-react-lite';
import React from 'react';

import TextField from '@/components/TextField';
import { IMeta } from '@/types/meta';

interface IProps {
    meta: IMeta;
}

const SectionTitle: React.FC<IProps> = observer(({ meta }) => {
    if (typeof meta?.sectionTitle === 'string') {
        if (!meta?.sectionTitle) return null;
        return (
            <TextField className="layoutSectionTitle" name="meta.sectionTitle" text={meta.sectionTitle} isTextArea />
        );
    }

    if (!meta?.sectionTitle?.text) return null;

    if (meta?.sectionTitle?.isTitle) {
        return (
            <TextField
                className="layoutSectionTitle"
                name="meta.sectionTitle.text"
                text={meta.sectionTitle.text}
                isTextArea
                customTag="h1"
            />
        );
    }

    return (
        <TextField
            className="layoutSectionTitle"
            name="meta.sectionTitle.text"
            text={meta.sectionTitle.text}
            isTextArea
        />
    );
});

export default SectionTitle;
