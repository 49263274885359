import clsx from 'clsx';
import React, { FC, useRef } from 'react';

import YandexSearchForm from '@/components/UI/YandexSearchForm';
import useOnClickOutside from '@/hooks/useOnClickOutside';

import cn from './style.module.sass';

interface IProps {
    hideSearchHandler?: () => void;
    flag?: boolean;
}
const HeaderSearchForm: FC<IProps> = ({ hideSearchHandler, flag }) => {
    const ref = useRef(null);

    useOnClickOutside(ref, hideSearchHandler);

    return (
        <div className={clsx(cn.wrapper)} ref={ref}>
            <div className={cn.fullWrapper} id="header-search">
                <YandexSearchForm flag={flag} />
            </div>
        </div>
    );
};

export default HeaderSearchForm;
