import React, { FC } from 'react';

const MenuIcon: FC = () => (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg" className="menuIcon">
        <circle opacity="0.1" cx="14.5" cy="14.5" r="14" className="menuIconStroke" />
        <circle cx="14.5" cy="14.5" r="1.5" className="menuIconFill" />
        <circle cx="8.5" cy="14.5" r="1.5" className="menuIconFill" />
        <circle cx="20.5" cy="14.5" r="1.5" className="menuIconFill" />
    </svg>
);

export default MenuIcon;
