import React, { forwardRef, useEffect, useState } from 'react';
import { Swiper } from 'swiper/react';
import ISwiperOptions from 'swiper/types/swiper-class';

interface IAttachOptions {
    attributes: boolean;
    childList: boolean;
    characterData: boolean;
}

const CustomSwiper: React.FC<Swiper> = forwardRef(({ children, ...rest }, ref) => {
    const [observers, setObservers] = useState<MutationObserver[]>([]);

    function onInit() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias,@typescript-eslint/no-unsafe-assignment
        const swiper: ISwiperOptions = this;

        const attach = (target, options?: IAttachOptions) => {
            const observer = new MutationObserver(_ => {
                swiper.update();
            });

            observer.observe(target, {
                attributes: options?.attributes || true,
                childList: options?.childList || true,
                characterData: options?.characterData || true,
            });

            setObservers([...observers, observer]);
        };

        const containerParents = swiper.$el.parents();
        for (let i = 0; i < containerParents.length; i += 1) {
            attach(containerParents[i]);
        }
    }

    useEffect(
        () => () => {
            observers?.forEach(observer => {
                observer.disconnect();
            });
            setObservers([]);
        },
        []
    );

    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line react/jsx-no-bind
        <Swiper {...rest} ref={ref} onInit={onInit}>
            {children}
        </Swiper>
    );
});

export default CustomSwiper;
