import clsx from 'clsx';
import React from 'react';

import AnimatedPromoButton from '@/components/blocks/animatedPromo/AnimatedPromoButton';
import { IButton } from '@/types/animatedPromo/item';

export interface IProps {
    buttons: IButton[];
}

const AnimatedPromoSlideBottom: React.FC<IProps> = ({ buttons }) => (
    <div className="animatedPromoSlideTopSliderSliderItemBot">
        <div className="animatedPromoSlideBottomSliderBtn">
            {buttons?.map((button, index) => (
                <div
                    key={index}
                    className={clsx(
                        'animatedPromoSlideBottomSliderBtnItem',
                        index !== 0 && 'animatedPromoSlideBottomSliderBtnItemMobailNo',
                        index !== 0 && 'sliderBtnItemMobailNo'
                    )}
                >
                    <AnimatedPromoButton button={button} />
                </div>
            ))}
        </div>
    </div>
);

export default AnimatedPromoSlideBottom;
