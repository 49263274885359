import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import ChooseCityBlock from '@/components/city/ChooseCity';
import HeaderCityThemePart from '@/components/layout/Header/HeaderCityThemePart';
import { useGeoStore } from '@/context/GeoStoreContext';
import City from '@/domain/city/store';
import { useFixHtml } from '@/hooks/useFixHtml';
import { useResize } from '@/hooks/useResize';
import { IGtagHeader, IHeaderSocial } from '@/types/header';
import { TABLET_WIDTH } from '@/utils/constants';
import { event } from '@/components/shared/utilities/analytics/metrics';

interface IProps {
    gtag: IGtagHeader;
    showSearchHandler?: () => void;
    headerSocial?: IHeaderSocial;
    usePwa?: boolean;
}

const HeaderTopGroup: React.FC<IProps> = observer(({ gtag, showSearchHandler, headerSocial, usePwa = true }) => {
    const { showDialog, toggleCallback } = City;
    const { geoPages } = useGeoStore();
    const { fix, unfix } = useFixHtml();
    const width = useResize();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const openModalCity = () => {
        if (gtag?.city?.open?.action) event(gtag?.city?.open);
        fix();
        setIsOpen(true);
    };

    const closeModalCity = () => {
        unfix();
        setIsOpen(false);
    };

    const openPWACity = () => {
        if (gtag?.city?.open?.action) event(gtag?.city?.open);
        toggleCallback(-2);
    };

    const closePWACity = () => {
        toggleCallback(-1);
    };

    const isMobile = width < TABLET_WIDTH;
    const isDesktop = width > TABLET_WIDTH;

    if ((!showDialog && isDesktop) || geoPages.length === 0) {
        return (
            <HeaderCityThemePart
                handleClose={closeModalCity}
                handleOpen={openModalCity}
                isOpen={isOpen}
                gtag={gtag}
                showSearchHandler={showSearchHandler}
                headerSocial={headerSocial}
            />
        );
    }

    if (!showDialog) return null;

    if (isMobile && usePwa) {
        return (
            <ChooseCityBlock
                gtag={gtag?.city}
                handleClose={closePWACity}
                handleOpen={openPWACity}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
            />
        );
    }

    return (
        <ChooseCityBlock
            gtag={gtag?.city}
            handleClose={closeModalCity}
            handleOpen={openModalCity}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
        />
    );
});

export default HeaderTopGroup;
