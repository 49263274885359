import React from 'react';

export function NotificationIcon() {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="inherit" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.6908 12C22.6908 17.4308 18.2882 21.8333 12.8574 21.8333C7.42663 21.8333 3.02409 17.4308 3.02409 12C3.02409 6.5692 7.42662 2.16667 12.8574 2.16666C18.2882 2.16666 22.6908 6.5692 22.6908 12ZM24.8574 12C24.8574 18.6274 19.4848 24 12.8574 24C6.23001 24 0.857425 18.6274 0.857424 12C0.857423 5.37258 6.23 -9.39372e-07 12.8574 -2.09815e-06C19.4848 -3.25692e-06 24.8574 5.37258 24.8574 12ZM11.8574 7C11.8574 6.44771 12.3052 6 12.8574 6C13.4097 6 13.8574 6.44771 13.8574 7L13.8574 13C13.8574 13.5523 13.4097 14 12.8574 14C12.3052 14 11.8574 13.5523 11.8574 13L11.8574 7ZM13.8574 17C13.8574 17.5523 13.4097 18 12.8574 18C12.3051 18 11.8574 17.5523 11.8574 17C11.8574 16.4477 12.3051 16 12.8574 16C13.4097 16 13.8574 16.4477 13.8574 17Z"
                fill="#0055CC"
            />
        </svg>
    );
}
