import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import TinaTextField from '@/components/TextField/index.tina';
import TiptapTextField from '@/components/UI/TipTap/TiptapTextField';
import { useAppStore } from '@/context/AppStoreContext';
import Feature from '@/domain/feature/Feature';

import cn from './style.module.sass';

type ITextField = {
    className?: string;
    text: string;
    name: string;
    customTag?: keyof JSX.IntrinsicElements;
    isTextArea?: boolean;
    /* @todo: убрать текстовый редактор тины, пока что завязываемся на этот флаг */
    simpleField?: boolean;
    [name: string]: unknown;
} & (
    | { isHTML?: true; htmlValue: string; offset?: number | { x: number; y: number } }
    | { isHTML?: false; htmlValue?: string; offset?: number | { x: number; y: number } }
);

const TextField: React.FC<ITextField> = observer(
    ({ className, text, name, simpleField, isTextArea, customTag, isHTML, htmlValue, offset, ...rest }) => {
        const isNewEditor = Feature.isFeatureEnabled('isNewEditor');
        const [isTinaView] = useAppStore(store => store.isTinaView);

        if (!text || text === '<p></p>') {
            return null;
        }
        const createDomParser = () => {
            if (typeof window !== 'undefined') {
                return new DOMParser();
            }
            return { parseFromString: (input: string) => input };
        };

        function convertPTagWithImgToDiv(value: string): string {
            const parser = createDomParser();
            const doc = parser.parseFromString(value, 'text/html');

            if (typeof doc === 'object') {
                const paragraphsWithImg = doc.querySelectorAll('p img');
                paragraphsWithImg.forEach(img => {
                    const paragraph = img.parentNode as Element;
                    const div = document.createElement('div');
                    div.innerHTML = paragraph.innerHTML;
                    paragraph.parentNode?.replaceChild(div, paragraph);
                });

                return doc.body.innerHTML;
            }
            return value;
        }

        if (isTinaView && name) {
            if (!isNewEditor) {
                return (
                    <TinaTextField
                        isTextArea={isTextArea}
                        className={className}
                        name={name}
                        customTag={customTag}
                        isHTML={isHTML}
                        simpleField={simpleField}
                        htmlValue={htmlValue}
                        offset={offset}
                        text={text}
                        {...rest}
                    />
                );
            }
            return <TiptapTextField textValue={convertPTagWithImgToDiv(text)} className={className} />;
        }

        if (customTag) {
            const Tag = customTag;

            return (
                <Tag className={clsx(className, cn.textField)} {...rest} dangerouslySetInnerHTML={{ __html: text }} />
            );
        }

        return (
            <div
                className={clsx(className, cn.textField, cn.content)}
                {...rest}
                dangerouslySetInnerHTML={{ __html: text }}
            />
        );
    }
);

export default TextField;
