import { mergeAttributes, Node } from '@tiptap/core';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        indent: {
            setIndent: () => ReturnType;
            unsetIndent: () => ReturnType;
            updateClass: () => ReturnType;
        };
    }
}

export const Indent = Node.create({
    name: 'indent',
    content: 'block*',
    group: 'block',
    defining: true,

    addOptions() {
        return {
            HTMLAttributes: { class: 'indent' },
        };
    },

    addAttributes() {
        return {
            class: {
                default: 'indent',
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'div',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['div', mergeAttributes(HTMLAttributes), 0];
    },

    addCommands() {
        let clickCount = 0;

        return {
            setIndent:
                () =>
                ({ commands, editor }) => {
                    const isActiveIndent = editor.isActive('indent');

                    if (isActiveIndent) return null;

                    return commands.toggleWrap('indent', {
                        tag: 'div',
                    });
                },
            updateClass:
                () =>
                ({ commands }) => {
                    if (clickCount < 5) {
                        clickCount += 1;

                        return commands.updateAttributes('indent', { class: `indent-${clickCount}` });
                    }
                    return null;
                },
            unsetIndent:
                () =>
                ({ commands }) => {
                    if (clickCount === 0) {
                        return commands.resetAttributes('indent', 'class');
                    }
                    clickCount -= 1;

                    return commands.updateAttributes('indent', { class: `indent-${clickCount}` });
                },
        };
    },
});
