'use client';

import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import React, { FC, ReactNode, Suspense, useEffect, useMemo } from 'react';

import Breadcrumbs from '@/components/Breadcrumbs';
import Block from '@/components/layout/Block';
import BlockList from '@/components/layout/BlocksList';
import Footer from '@/components/layout/Footer/Footer';
import Header from '@/components/layout/Header/Header';
import SectionTitle from '@/components/layout/SectionTitle';
import { Metrics } from '@/components/shared/utilities/analytics/metrics';
import { useAppStore } from '@/context/AppStoreContext';
import { useCity } from '@/context/CityProvider';
import { useGeoStore } from '@/context/GeoStoreContext';
import { useLayoutStore } from '@/context/LayoutStoreContext';
import { useTheme } from '@/context/ThemeProvider';
import Feature from '@/domain/feature/Feature';
import MetricsStore from '@/domain/metrics/Store';
import LocalStorageProvider from '@/domain/storage/localStorage';
import SessionStorageProvider from '@/domain/storage/sessionStorage';
import useAntiFlicker from '@/hooks/useAntiFlicker';
import { useAttribution } from '@/hooks/useAttribution';
import { useTabletSmallWidth, useTabletWidth } from '@/hooks/useMedia';
import usePerformanceObserver from '@/hooks/usePerformanceObserver';
import { sidebarBlocksConfig } from '@/tina/config/sidebar/sidebar.config';
import { IData } from '@/types/data';
import { Cookie } from '@/utils/cookie';
import { getQueryParam } from '@/utils/getQueryParam';

export type DefaultLayoutProps = {
    data: IData;
    withBlockList?: boolean;
    children?: ReactNode;
    geoPagesIds?: string[];
};

const PWAMenu = dynamic(() => import(/* webpackChunkName: "PWAMenu" */ '@/components/PWAMenu/PWAMenu'));

const ProductPanel = dynamic(() => import('@/components/ProductPanel'));

const DesignReview = dynamic(() => import('@/components/designReview/DesignReview'), {
    ssr: false,
});

const GoToAdminButton = dynamic(() => import('@/components/GoToAdminButton'), { ssr: false });

const DefaultLayout: FC<DefaultLayoutProps> = ({ data, withBlockList = true, geoPagesIds, children }) => {
    const asPath = usePathname();
    const [isSidebar] = useAppStore(store => store.isSidebar);
    const [willChangeLayout] = useAppStore(store => store.willChangeLayout);
    const [hydrateTimes] = useAppStore(store => store.hydrateTimes);
    const { setInitialTheme, theme } = useTheme();
    const { fetchUserCity } = useCity();
    const { setGeoPages } = useGeoStore();

    useEffect(() => {
        setInitialTheme(data?.pageParams?.darkTheme, asPath);
    }, [data?.pageParams?.darkTheme, asPath]);

    useEffect(() => {
        Cookie.setNewSiteCookie();
        setGeoPages(geoPagesIds || []);
    }, []);

    const hideMetrics = useMemo(() => {
        if (typeof window === 'undefined') return false;

        if (asPath.includes('/service/cashcredit_test')) return true;

        const queryString = getQueryParam(asPath, 'metrics');

        return queryString === 'off';
    }, []);

    if (!hideMetrics) MetricsStore.hydrate(data?.gaParams);

    const { meta, header, blocks, footer, pageParams, schemaData, id } = data;
    const { isLiteHeaderDesktop, isLiteHeaderMobile } = useLayoutStore();
    const isTabletSmallWidth = useTabletSmallWidth(true);

    const isTabletWidth = useTabletWidth(true);

    const showProductPanel = pageParams?.productPanel && !isTabletSmallWidth;
    const breadCrumbsConditions = !isLiteHeaderMobile && !isLiteHeaderDesktop;
    const isLiteHeader = data?.pageParams?.header?.isLiteHeader;

    const showPWAMenu = isTabletWidth && !pageParams?.header?.isDisabled;

    const hidePage = useAntiFlicker(pageParams?.antiFlicker);

    useEffect(() => {
        fetchUserCity().catch(error => console.error(error));
    }, []);

    if (typeof window !== 'undefined') {
        window.localStorageProvider = new LocalStorageProvider();
        window.sessionStorageProvider = new SessionStorageProvider();

        // устанавливаем куки для корректной работы кеша в случае падения подов
        if (!Cookie.getCookie('PochtaBankGeoData')) {
            Cookie.setGeoCookie();
        }
    }

    useAttribution();
    usePerformanceObserver();

    return (
        <div
            className={clsx(
                'layoutWrapper',
                'wrapper',
                theme === 'dark' && 'dark',
                theme === 'dark' && 'layoutContentDark',
                hidePage && 'layoutWrapperHide'
            )}
            id="wrapper"
        >
            <Suspense>
                <Header {...header} {...pageParams?.header} isBusinessPage={pageParams?.isBusinessPage} />
            </Suspense>

            <div
                className={clsx(
                    'layoutMiddle',
                    isSidebar && 'sidebarView',
                    pageParams?.header?.isDisabled && 'layoutMiddleNoPadding'
                )}
            >
                {(breadCrumbsConditions || !isLiteHeader) && <Breadcrumbs />}

                <SectionTitle meta={meta} />

                <div className="layoutInner">
                    <div className="layoutContent">
                        {withBlockList && (
                            <BlockList blocks={blocks} key={willChangeLayout ? hydrateTimes : 'blockList'} />
                        )}
                        {children}
                    </div>

                    {isSidebar && (
                        <div className="layoutSidebar">
                            {withBlockList &&
                                data?.sidebar?.blocks?.map(block => (
                                    <Block key={block.nanoId} block={block} config={sidebarBlocksConfig} />
                                ))}
                        </div>
                    )}
                </div>
            </div>

            {showProductPanel && (
                <ProductPanel
                    {...pageParams.productPanel}
                    isHeaderStatic={pageParams?.header?.isStatic}
                    isHeaderDisabled={pageParams?.header?.isDisabled}
                />
            )}

            {showPWAMenu && (
                <Suspense>
                    <PWAMenu
                        data={header?.pwaMenu}
                        gtag={header?.gtag}
                        isPWAHidden={pageParams?.isPWAHidden}
                        isFlocktory={pageParams?.isFlocktory}
                    />
                </Suspense>
            )}

            <Suspense>
                <Footer
                    {...footer}
                    isDisabled={pageParams?.footer?.isDisabled}
                    liteActiveCode={pageParams?.footer?.liteActiveCode}
                    isFlocktory={pageParams?.isFlocktory}
                />
            </Suspense>

            {Feature.isFeatureEnabled('showDesignReview') && <DesignReview />}
            <GoToAdminButton id={id} />

            {!hideMetrics && process.env.NODE_ENV === 'production' && process.env.APP_ENV !== 'work' && (
                <Suspense>
                    <Metrics />
                </Suspense>
            )}
        </div>
    );
};

export default observer(DefaultLayout);
