import { mergeAttributes, Node, wrappingInputRule } from '@tiptap/core';
import { ListItem } from '@tiptap/extension-list-item';
import TextStyle from '@tiptap/extension-text-style';

export interface OrderedListOptions {
    itemTypeName: string;
    HTMLAttributes: Record<string, any>;
    keepMarks: boolean;
    keepAttributes: boolean;
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        orderedList: {
            toggleOrderedList: () => ReturnType;
        };
    }
}

export const inputRegex = /^(\d+)\.\s$/;

export const OrderedList = Node.create<OrderedListOptions>({
    name: 'orderedList',

    addOptions() {
        return {
            itemTypeName: 'listItem',
            HTMLAttributes: {
                class: 'indent',
            },
            keepMarks: false,
            keepAttributes: false,
        };
    },

    group: 'block list',

    content() {
        return `${this.options.itemTypeName}+`;
    },

    addAttributes() {
        return {
            start: {
                default: 1,
                parseHTML: element =>
                    element.hasAttribute('start') ? parseInt(element.getAttribute('start') || '', 10) : 1,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: 'ol',
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        const { start, ...attributesWithoutStart } = HTMLAttributes;

        return start === 1
            ? ['ol', mergeAttributes(this.options.HTMLAttributes, attributesWithoutStart), 0]
            : ['ol', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
    },

    addCommands() {
        return {
            toggleOrderedList:
                () =>
                ({ commands, chain }) => {
                    if (this.options.keepAttributes) {
                        return chain()
                            .toggleList(this.name, this.options.itemTypeName, this.options.keepMarks)
                            .updateAttributes(ListItem.name, this.editor.getAttributes(TextStyle.name))
                            .run();
                    }
                    return commands.toggleList(this.name, this.options.itemTypeName, this.options.keepMarks);
                },
        };
    },

    addInputRules() {
        let inputRule = wrappingInputRule({
            find: inputRegex,
            type: this.type,
            getAttributes: match => ({ start: +match[1] }),
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            joinPredicate: (match, node) => node.childCount + node.attrs.start === +match[1],
        });

        if (this.options.keepMarks || this.options.keepAttributes) {
            inputRule = wrappingInputRule({
                find: inputRegex,
                type: this.type,
                keepMarks: this.options.keepMarks,
                keepAttributes: this.options.keepAttributes,
                getAttributes: match => ({ start: +match[1], ...this.editor.getAttributes(TextStyle.name) }),
                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                joinPredicate: (match, node) => node.childCount + node.attrs.start === +match[1],
                editor: this.editor,
            });
        }
        return [inputRule];
    },
});
