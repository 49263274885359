import React from 'react';

export const smoothScroll = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>, href: string) => {
    if (href?.includes('#')) {
        try {
            const index = href?.indexOf('#');
            const id = href?.slice(index + 1);
            const element = document.querySelector(id);

            if (element) {
                e.preventDefault();
                element.scrollIntoView({ block: 'center', behavior: 'smooth' });
            }
        } catch (error) {
            console.error('На странице не найден элемент с заданным ID.', error);
        }
    }
};
