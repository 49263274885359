import { Extensions } from '@tiptap/react';
import { useEffect, useRef, useState } from 'react';

import { baseExtensions, getTableExtensions } from '@/tina/plugins/htmlEditorNew/extensions';

export const useExtensions = () => {
    const [loaded, setLoaded] = useState<boolean>(true);

    const currentExtensions = useRef<Extensions>(baseExtensions);

    useEffect(() => {
        getTableExtensions()
            .then(tableExtensions => {
                currentExtensions.current = [...currentExtensions.current, ...tableExtensions];
            })
            .catch(console.error)
            .finally(() => {
                setLoaded(false);
            });
    }, []);

    return {
        loaded,
        extensions: currentExtensions.current,
    };
};
