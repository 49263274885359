import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

export type InnerFormValues = {
    [name: string]: any;
};

const DateSchema = z.date().max(new Date(), { message: 'Дата не может быть в будущем, выберите корректную дату' });
export const schemaConfig = {
    accept: z.boolean().refine(value => value === true, {
        message: 'Поле обязательно для заполнения',
    }),
    checkbox: z.boolean().refine(value => value === true, {
        message: 'Поле обязательно для заполнения',
    }),
    checkboxString: z.array(z.string()).min(1, 'Поле обязательно для заполнения'),
    phone: z
        .string({ required_error: 'Поле обязательно для заполнения' })
        .refine(value => value && value.indexOf('_') < 0, {
            message: 'Неверный формат номера телефона',
        }),
    email: z
        .string()
        .min(1, 'Поле обязательно для заполнения')
        .email('E-mail введен некоректно')
        .max(255, 'E-mail не должен быть больше 255 символов'),

    emailNonRequired: z
        .string()
        .email('E-mail введен некоректно')
        .max(255, 'E-mail не должен быть больше 255 символов'),
    fio: z
        .string()
        .min(3, 'ФИО не должно быть короче 3х символов')
        .max(255, 'ФИО не должно быть больше 255 символов')
        .refine(val => val !== undefined && val !== null, {
            message: 'Поле обязательно для заполнения',
        }),
    organization: z
        .string()
        .min(3, 'Наименование не должно быть короче 3х символов')
        .max(255, 'Наименование не должно быть больше 255 символов')
        .refine(val => val !== undefined && val !== null, {
            message: 'Поле обязательно для заполнения',
        }),
    required: z.string().min(1, 'Поле обязательно для заполнения'),

    agreement: z
        .string()
        .min(1, 'Поле обязательно для заполнения')
        .regex(/^\d+$/, 'Поле должно содержать только цифры'),

    value: z.string().min(1, 'Поле обязательно для заполнения'),
    option: z.string({ required_error: 'Выберите опцию' }),

    inn: z
        .string()
        .max(12, 'ИНН не должен быть длиннее 12 цифр')
        .regex(/^\d{0}$|^\d{10}$|^\d{12}$/, 'ИНН должен содержать 10 или 12 цифр')
        .min(1, 'Поле обязательно для заполнения'),
    file: z
        .array(z.any())
        .or(z.null())
        .refine(value => value?.length > 0, {
            message: 'Необходимо загрузить файл(ы)',
        }),
    array: z
        .array(z.any())
        .or(z.undefined())
        .refine(val => val !== undefined && val !== null, {
            message: 'Поле со списком обязательно для заполнения',
        }),
    code: z
        .string({
            required_error: 'Поле обязательно для заполнения',
        })
        .regex(/^[0-9]+$/, 'Код должен содержать только цифры')
        .min(6, 'Код должен содержать 6 символов')
        .max(6, 'Код должен содержать 6 символов'),
    fi: z
        .string()
        .min(1, 'Поле обязательно для заполнения')
        .regex(/^[А-ЯЁа-яё\\-]+ [А-ЯЁа-яё\\-]+$/, 'Неверный формат ввода имени (должно быть минимум 2 слова)'),

    city: z
        .string({ required_error: 'Поле обязательно для заполнения' })
        .regex(/^[А-ЯЁа-яё\s-]+$/, 'Неверный формат ввода названия города'),
    requiredSelect: z.string({}).min(1, 'Поле обязательно для заполнения'),
    select: z.object({
        label: z.string().min(1, 'Поле обязательно для заполнения'),
        value: z.string().min(1, 'Поле обязательно для заполнения'),
    }),
    widgetBirth: z
        .date()
        .max(new Date(new Date().getFullYear() - 18, new Date().getMonth()), 'Кредит выдается с 18 лет!'),
    widgetFio: z
        .string()
        .min(1, 'Поле обязательно для заполнения')
        .regex(
            /^[А-ЯЁа-яё\\-]+ {0,1}([А-ЯЁа-яё\\-]+ {0,1}){0,5}[А-ЯЁа-яё\\-]+$/,
            'Только русские буквы, дефисы и пробелы. Максимум 7 слов.'
        ),
    birthDate: z.union([DateSchema, z.null()]).refine(date => date !== null, {
        message: 'Пожалуйста, выберите дату',
    }),
    arrayOfStringsOptional: z.any(),
    conditionalRequiredCommentPoll: z.string().min(1, 'Поле обязательно для заполнения'),
};

export type FieldConfig = {
    name: string;
    type: keyof typeof schemaConfig;
    required?: boolean;
};

export const useValidationSchema = (validationConfig: Array<FieldConfig>) => {
    const schemaObject = validationConfig.reduce((accumulator, item) => {
        const schemaByType = schemaConfig[item.type];

        return {
            ...accumulator,
            [item.name]: item.required
                ? schemaByType
                : z.preprocess(arg => (!arg ? undefined : arg), schemaByType.optional()),
        };
    }, {});

    return zodResolver(z.object(schemaObject));
};
