import * as Tooltip from '@radix-ui/react-tooltip';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useState } from 'react';

import { useResize } from '@/hooks/useResize';
import { MOBILE_WIDTH } from '@/utils/constants';

import cn from './style.module.sass';

interface IProps {
    html?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
    background?: 'white' | 'grey';
    children: ReactNode;
    className?: string;
    place?: 'top' | 'right' | 'bottom' | 'left';
}

const CustomTooltip: React.FC<IProps> = observer(
    ({ children, html, className, background = 'grey', place = 'bottom' }) => {
        const width = useResize();
        const isMobile = width <= MOBILE_WIDTH && typeof window !== undefined;

        const [open, setOpen] = useState(false);

        return (
            <div className={clsx(cn.tooltip, className)}>
                <Tooltip.Provider delayDuration={100}>
                    <Tooltip.Root open={open}>
                        <Tooltip.Trigger
                            onMouseEnter={() => {
                                setOpen(true);
                            }}
                            onMouseLeave={() => {
                                setOpen(false);
                            }}
                            onFocus={() => {
                                setOpen(true);
                            }}
                            onBlur={() => {
                                setOpen(false);
                            }}
                            onTouchStart={() => {
                                setOpen(true);
                            }}
                            onTouchCancel={() => {
                                setOpen(false);
                            }}
                        >
                            {children}
                        </Tooltip.Trigger>
                        <Tooltip.Portal>
                            <Tooltip.Content
                                side={isMobile ? 'top' : place}
                                className={clsx(cn.tooltip__reactTooltip, background === 'white' && cn.white)}
                            >
                                {html}
                                <Tooltip.Arrow className={cn.tooltip__arrow} />
                            </Tooltip.Content>
                        </Tooltip.Portal>
                    </Tooltip.Root>
                </Tooltip.Provider>
            </div>
        );
    }
);

export default CustomTooltip;
