import clsx from 'clsx';
import React, { FC } from 'react';

import { BasicLink } from '@/components/UI/BasicLink';
import { IFooter } from '@/types/footer';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

const FooterMenu: FC<IFooter & { hideOnMobile?: boolean }> = ({ menu, hideOnMobile }) => (
    <div className={clsx(cn.footerMenu, hideOnMobile && cn.hideOnMobile)}>
        <div className={cn.footerMenuWrap}>
            <div className={cn.footerMenuGrid}>
                {menu?.map(({ title, list }, index) => (
                    <div className={cn.footerMenuCol} key={`${index}-${title}`}>
                        {title && <div className={cn.footerMenuColTitle}>{title}</div>}
                        {list?.map(({ link, text, gtag }, innerIndex) => (
                            <div className={cn.footerMenuItem} key={`${innerIndex}-${text}`}>
                                <BasicLink
                                    href={link}
                                    customClass={clsx(cn.footerMenuLink)}
                                    onClick={() => event(gtag)}
                                >
                                    {text}
                                </BasicLink>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    </div>
);

export default FooterMenu;
