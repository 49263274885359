import React from 'react';

import cn from './style.module.sass';

function Sun() {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path
                    d="M11.8368 6.16321L11.8368 6.16324L11.842 6.16829C12.5785 6.88381 13.0237 7.89737 13.0237 9.00013C13.0237 10.0969 12.5589 11.115 11.8368 11.8371L11.8368 11.837L11.8317 11.8422C11.1162 12.5788 10.1026 13.0239 8.99988 13.0239C7.90306 13.0239 6.88503 12.5591 6.16296 11.8371C5.44089 11.115 4.97607 10.0969 4.97607 9.00013C4.97607 7.90331 5.44089 6.88527 6.16296 6.16321L6.163 6.16324L6.16805 6.15804C6.88357 5.42148 7.89712 4.97632 8.99988 4.97632C10.0967 4.97632 11.1147 5.44114 11.8368 6.16321Z"
                    fill="white"
                    className={cn.svgStroke}
                />
                <path
                    d="M17.3811 8.38086H15.5478C15.2144 8.38086 14.9287 8.66657 14.9287 8.99991C14.9287 9.33324 15.2144 9.61896 15.5478 9.61896H17.3811C17.7144 9.61896 18.0001 9.33324 18.0001 8.99991C18.0001 8.66657 17.7144 8.38086 17.3811 8.38086Z"
                    className={cn.svgFill}
                />
                <path
                    d="M8.99991 14.9287C8.66657 14.9287 8.38086 15.2144 8.38086 15.5478V17.3811C8.38086 17.7144 8.66657 18.0001 8.99991 18.0001C9.33324 18.0001 9.61896 17.7144 9.61896 17.3811V15.5478C9.61896 15.2144 9.33324 14.9287 8.99991 14.9287Z"
                    className={cn.svgFill}
                />
                <path
                    d="M15.357 14.5001L14.0474 13.1905C13.8331 12.9524 13.4284 12.9524 13.1903 13.1905C12.9522 13.4286 12.9522 13.8096 13.1903 14.0477L14.4998 15.3572C14.7379 15.5953 15.1189 15.5953 15.357 15.3572C15.5951 15.1191 15.5951 14.7382 15.357 14.5001Z"
                    className={cn.svgFill}
                />
                <path
                    d="M8.99991 0C8.66657 0 8.38086 0.285714 8.38086 0.619047V2.45238C8.38086 2.78571 8.66657 3.07143 8.99991 3.07143C9.33324 3.07143 9.61896 2.78571 9.61896 2.45238V0.619047C9.61896 0.285714 9.33324 0 8.99991 0Z"
                    className={cn.svgFill}
                />
                <path
                    d="M15.3809 2.64268C15.1428 2.40459 14.7618 2.40459 14.5237 2.64268L13.2142 3.95221C12.9761 4.1903 12.9761 4.57125 13.2142 4.80935C13.4285 5.04744 13.8333 5.04744 14.0714 4.80935L15.3809 3.49983C15.619 3.26173 15.619 2.88078 15.3809 2.64268Z"
                    className={cn.svgFill}
                />
                <path
                    d="M2.45238 8.38086H0.619047C0.285714 8.38086 0 8.66657 0 8.99991C0 9.33324 0.261905 9.61896 0.619047 9.61896H2.45238C2.78571 9.61896 3.07143 9.33324 3.07143 8.99991C3.07143 8.66657 2.78571 8.38086 2.45238 8.38086Z"
                    className={cn.svgFill}
                />
                <path
                    d="M4.78567 13.1905C4.57138 12.9524 4.16662 12.9524 3.92853 13.1905L2.619 14.5001C2.38091 14.7382 2.38091 15.1191 2.619 15.3572C2.8571 15.5953 3.23805 15.5953 3.47614 15.3572L4.78567 14.0477C5.02376 13.8096 5.02376 13.4286 4.78567 13.1905Z"
                    className={cn.svgFill}
                />
                <path
                    d="M4.78567 3.95221L3.47614 2.64268C3.23805 2.40459 2.8571 2.40459 2.619 2.64268C2.38091 2.88078 2.38091 3.26173 2.619 3.49983L3.92853 4.80935C4.16662 5.04744 4.54757 5.04744 4.78567 4.80935C5.02376 4.57125 5.02376 4.1903 4.78567 3.95221Z"
                    className={cn.svgFill}
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default Sun;
