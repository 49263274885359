import clsx from 'clsx';
import React, { useMemo } from 'react';

import IconArrow from '@/components/UI/IconArrow';

import cn from './style.module.sass';

interface IProps {
    toggle: () => void;
    isOpen: boolean;
}

const Trigger: React.FC<IProps> = ({ isOpen, toggle }) => {
    const triggerText = useMemo(() => {
        if (isOpen) return 'Свернуть';
        return 'Подробнее';
    }, [isOpen]);

    return (
        <div role="presentation" onClick={toggle} className={clsx(cn.wrapper, isOpen && cn.active)}>
            <div className={cn.text}>{triggerText}</div>
            <div className={cn.arrow}>
                <IconArrow direction={isOpen ? 'up' : 'down'} />
            </div>
        </div>
    );
};

export default Trigger;
