import dynamic from 'next/dynamic';
import React from 'react';

import { IDefaultBlocksConfig } from '@/types/tina/config';

export const sidebarBlocksConfig: IDefaultBlocksConfig = {
    newsSubscription: dynamic(() => import('@/components/blocks/sidebar/NewsSubscription')) as React.FC,
    rssSubscription: dynamic(() => import('@/components/blocks/sidebar/RSSSubscription')) as React.FC,
    sidebarInfo: dynamic(() => import('@/components/blocks/sidebar/SidebarInfo')) as React.FC,
    sidebarButton: dynamic(() => import('@/components/blocks/sidebar/SidebarButton')) as React.FC,
    sidebarDocs: dynamic(() => import('@/components/blocks/sidebar/SidebarDocs')) as React.FC,
    sidebarAuthor: dynamic(() => import('@/components/blocks/sidebar/SidebarAuthor')) as React.FC,
    sidebarAnchorLinks: dynamic(() => import('@/components/blocks/sidebar/SidebarAnchorLinks')) as React.FC,
};
