import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import CustomSlider from '@/components/CustomSlider';
import { useBreadcrumbs } from '@/context/BreadcrumbsProvider';
import { IBreadcrumbsStore } from '@/domain/breadcrumbs/BreadcrumbsStore';

const Breadcrumbs: React.FC<{
    customClassName?: string;
    customClassItem?: string;
    hideDisable?: boolean;
    customContent?: IBreadcrumbsStore;
}> = observer(({ customClassName, customClassItem, hideDisable = false, customContent = undefined }) => {
    const breadcrumbsStore = useBreadcrumbs();
    const breadcrumbs: IBreadcrumbsStore = customContent || breadcrumbsStore;

    const { list, color, isActive } = breadcrumbs;

    if (!isActive && !hideDisable) return null;

    return (
        <div className={clsx('breadcrumbs', customClassName, color === 'white' && 'breadcrumbsWhite')}>
            <CustomSlider className="breadcrumbsGrid">
                {list?.map(({ name, link }) => (
                    <div className={clsx('breadcrumbsItem', customClassItem)} key={name}>
                        <a href={link}>{name}</a>
                    </div>
                ))}
            </CustomSlider>
        </div>
    );
});

export default Breadcrumbs;
