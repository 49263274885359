import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';

import useBeingInViewport from '@/hooks/useBeingInViewport';
import { useWindowScroll } from '@/hooks/useWindowScroll';

interface IProps {
    // hash без решетки
    to: string;
    className?: string;
    activeClass?: string;
    offset?: number;
    children?: React.ReactNode;
    onClick?: () => void;
}

export const ScrollLink = observer(({ to, children, className, activeClass, offset = 0, onClick }: IProps) => {
    const [isActiveClass, setIsActiveClass] = useState(false);
    const blockLink = useRef<HTMLElement | null>(null);
    const scroll = useWindowScroll(0);

    const executeScroll = (e: React.MouseEvent<HTMLDivElement | HTMLAnchorElement>) => {
        const element = document?.getElementById(to) || document?.getElementsByName(to)[0];

        if (element) {
            e.preventDefault();

            window.scrollTo({
                behavior: 'smooth',
                top: element.getBoundingClientRect().top + window.scrollY + offset,
            });
        }

        if (onClick) onClick();
    };

    useEffect(() => {
        blockLink.current = document?.getElementById(to);
    }, []);

    const isVisible = useBeingInViewport(blockLink, 0, true);
    const scrollOffset = 25;

    useEffect(() => {
        if (isVisible) {
            const blockPosition = blockLink?.current?.getBoundingClientRect().top;
            const blockHeight = blockLink?.current?.offsetHeight;

            const hideIfBlockInView =
                blockPosition + offset - scrollOffset < 0 && blockPosition > 0 - blockHeight - offset + scrollOffset;

            setIsActiveClass(hideIfBlockInView);
        } else {
            setIsActiveClass(false);
        }
    }, [scroll, isVisible]);

    return (
        <div
            role="presentation"
            onClick={e => executeScroll(e)}
            className={clsx(className, isActiveClass && activeClass)}
        >
            {children}
        </div>
    );
});
