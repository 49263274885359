import dynamic from 'next/dynamic';
import React from 'react';

import AnimatedPromo from '@/components/blocks/animatedPromo/AnimatedPromo';
import Promo from '@/components/blocks/Promo';
import { IDefaultBlocksConfig } from '@/types/tina/config';

// template: component

export const defaultBlocksConfig: IDefaultBlocksConfig = {
    animatedPromo: AnimatedPromo,
    promo: Promo,
    promoSecondary: dynamic(() => import('@/components/blocks/PromoSecondary')) as React.FC,
    advantages: dynamic(() => import('@/components/blocks/advantages/Advantages')) as React.FC,
    advantagesV2: dynamic(() => import('@/components/blocks/advantages/Advantages-v2')) as React.FC,
    info: dynamic(() => import('@/components/blocks/Info')) as React.FC,
    threeInARow: dynamic(() => import('@/components/blocks/ThreeInARow')) as React.FC,
    stretch: dynamic(() => import('@/components/blocks/Stretch')) as React.FC,
    mainSlider: dynamic(() => import('@/components/blocks/MainSlider')) as React.FC,
    services: dynamic(() => import('@/components/blocks/Services')) as React.FC,
    // eslint-disable-next-line import/no-cycle
    news: dynamic(() => import('@/components/blocks/News')) as React.FC,
    links: dynamic(() => import('@/components/blocks/Links')) as React.FC,
    instruction: dynamic(() => import('@/components/blocks/Instruction')) as React.FC,
    requisites: dynamic(() => import('@/components/blocks/Requisites')) as React.FC,
    title: dynamic(() => import('@/components/UI/Title/index.block')) as React.FC,
    groupedTariffs: dynamic(() => import('@/components/blocks/GroupedTariffs')) as React.FC,
    creditCardsList: dynamic(() => import('@/components/blocks/Cards')) as React.FC,
    questions: dynamic(() => import('@/components/blocks/Questions')) as React.FC,
    tariffInfo: dynamic(() => import('@/components/blocks/TariffInfo')) as React.FC,
    offersCredits: dynamic(() => import('@/components/blocks/OffersCredits')) as React.FC,
    linkingList: dynamic(() => import('@/components/blocks/LinkingList')) as React.FC,
    citiesList: dynamic(() => import('@/components/blocks/LinkingList')) as React.FC,
    textBlock: dynamic(() => import('@/components/blocks/TextBlock')) as React.FC,
    // DEPRECATED
    tariffTabs: dynamic(() => import('@/components/blocks/tariffTabs/_deprecated/TariffTabs')) as React.FC,
    // DEPRECATED
    paymentGroup: dynamic(() => import('@/components/blocks/frames/_deprecated/PaymentGroup')) as React.FC,
    faq: dynamic(() => import('@/components/blocks/faq/FAQ')) as React.FC,
    universalDetail: dynamic(() => import('@/components/blocks/universalDetail/UniversalDetail')) as React.FC,
    universalList: dynamic(() => import('@/components/blocks/universalList/UniversalList')) as React.FC,
    partners: dynamic(() => import('@/components/blocks/partners/Partners')) as React.FC,
    infoBgBlock: dynamic(() => import('@/components/blocks/InfoBgBlock')) as React.FC,
    creditGiftBlock: dynamic(() => import('@/components/blocks/CreditGiftBlock')) as React.FC,
    infoCard: dynamic(() => import('@/components/blocks/infoCard/InfoCard')) as React.FC,
    popularExchangeRates: dynamic(
        () => import('@/components/blocks/PopularExchangeRates/PopularExchangeRates')
    ) as React.FC,
    allExchangeRates: dynamic(() => import('@/components/blocks/AllExchangeRates/AllExchangeRates')) as React.FC,
    periodInfo: dynamic(() => import('@/components/blocks/PeriodInfo/')) as React.FC,
    cashback: dynamic(() => import('@/components/blocks/Cashback')) as React.FC,
    possibilities: dynamic(() => import('@/components/blocks/Possibilities')) as React.FC,
    safely: dynamic(() => import('@/components/blocks/Safely')) as React.FC,
    managementInfo: dynamic(() => import('@/components/blocks/managementInfo/ManagementInfo')) as React.FC,
    singleDocuments: dynamic(() => import('@/components/blocks/singleDocuments/SingleDocuments')) as React.FC,
    table: dynamic(() => import('@/components/blocks/Table')) as React.FC,
    aboutPB: dynamic(() => import('@/components/blocks/aboutPB/aboutPB')) as React.FC,
    parametersBonds: dynamic(() => import('@/components/blocks/bonds/ParametersBonds')) as React.FC,
    tariffsRestrictions: dynamic(() => import('@/components/blocks/bonds/TariffsRestrictions')) as React.FC,
    mobileApp: dynamic(() => import('@/components/blocks/MobileApp/')) as React.FC,
    feedbackForm: dynamic(() => import('@/components/blocks/FeedbackForm/')) as React.FC,
    tabs: dynamic(() => import('@/components/blocks/tabsBlock/TabsBlock')) as React.FC,
    preferentialCalculator: dynamic(
        () => import('@/components/blocks/calculators/layouts/PreferentialCalculator')
    ) as React.FC,
    cashCalculator: dynamic(() => import('@/components/blocks/calculators/layouts/CashCalculator')) as React.FC,
    cashCalculatorV2: dynamic(() => import('@/components/blocks/calculators/layouts/CashCalculatorV2')) as React.FC,
    vezdedohodCalculator: dynamic(
        () => import('@/components/blocks/calculators/layouts/VezdedohodCalculator')
    ) as React.FC,
    cardToCard: dynamic(() => import('../../components/blocks/frames/CardToCard')) as React.FC,
    loanRepayment: dynamic(() => import('../../components/blocks/frames/LoanRepayment')) as React.FC,
    cashLoan: dynamic(() => import('../../components/blocks/frames/CashLoan')) as React.FC,
    vddohod: dynamic(() => import('../../components/blocks/frames/Vddohod')) as React.FC,
    example: dynamic(() => import('@/components/blocks/Example/')) as React.FC,
    payPlatforms: dynamic(() => import('@/components/blocks/PayPlatforms/')) as React.FC,
    depositApplication: dynamic(() => import('../../components/blocks/frames/DepositApplication')) as React.FC,
    sberschetOpen: dynamic(() => import('../../components/blocks/frames/SberschetOpen')) as React.FC,
    pushkinskayaCard: dynamic(() => import('../../components/blocks/frames/PushkinskayaCard')) as React.FC,
    help: dynamic(() => import('../../components/blocks/tariffTabs/Help')) as React.FC,
    documents: dynamic(() => import('../../components/blocks/tariffTabs/Documents')) as React.FC,
    tariffBlocks: dynamic(() => import('../../components/blocks/tariffTabs/TariffBlocks')) as React.FC,
    introPromo: dynamic(() => import('@/components/blocks/IntroPromo')) as React.FC,
    ourTargets: dynamic(() => import('@/components/blocks/OurTargets')) as React.FC,
    management: dynamic(() => import('@/components/blocks/Management')) as React.FC,
    summaryNumbers: dynamic(() => import('@/components/blocks/SummaryNumbers')) as React.FC,
    tariffsDocumentsArchive: dynamic(() => import('@/components/blocks/TariffsDocumentsArchive')) as React.FC,
    quotesCarousel: dynamic(() => import('@/components/blocks/QuotesCarousel')) as React.FC,
    headManagement: dynamic(() => import('@/components/blocks/headManagement/HeadManagement')) as React.FC,
    biography: dynamic(() => import('@/components/blocks/biography/Biography')) as React.FC,
    mediaPublications: dynamic(() => import('@/components/blocks/mediaPublications/MediaPublications')) as React.FC,
    informationCenter: dynamic(() => import('@/components/blocks/InformationCenter')) as React.FC,
    map: dynamic(() => import('@/components/blocks/frames/MapContainer'), {
        ssr: true,
    }) as React.FC,
    insurance: dynamic(() => import('@/components/blocks/insurance/InsuranceList')) as React.FC,
    pensionTransfer: dynamic(() => import('@/components/blocks/frames/PensionTransfer')) as React.FC,
    sberschetCalculator: dynamic(
        () => import('@/components/blocks/calculators/layouts/SberschetCalculator')
    ) as React.FC,
    indication: dynamic(() => import('@/components/blocks/Indication')) as React.FC,
    bankAdvantages: dynamic(() => import('@/components/blocks/BankAdvantages')) as React.FC,
    atmList: dynamic(() => import('@/components/blocks/ATMList')) as React.FC,
    creditSlider: dynamic(() => import('@/components/blocks/CreditSlider')) as React.FC,
    studyCalculator: dynamic(() => import('@/components/blocks/calculators/layouts/StudyCalculator')) as React.FC,
    rkoCalculator: dynamic(() => import('@/components/blocks/calculators/layouts/RkoCalculator')) as React.FC,
    pledgeCalculator: dynamic(() => import('@/components/blocks/calculators/layouts/PledgeCalculator')) as React.FC,
    creditCalculator: dynamic(() => import('@/components/blocks/calculators/layouts/CreditCalculator')) as React.FC,
    applicationApps: dynamic(() => import('@/components/blocks/ApplicationApps')) as React.FC,
    forestMap: dynamic(() => import('@/components/blocks/frames/ForestMap'), {
        ssr: false,
    }) as React.FC,
    youtube: dynamic(() => import('@/components/blocks/YouTube')) as React.FC,
    photoSlider: dynamic(() => import('@/components/blocks/PhotoSlider')) as React.FC,
    quoteHr: dynamic(() => import('@/components/blocks/QuoteHr')) as React.FC,
    vacancy: dynamic(() => import('@/components/blocks/vacancy/Vacancy')) as React.FC,
    bankText: dynamic(() => import('@/components/blocks/BankText')) as React.FC,
    instructionSlider: dynamic(() => import('@/components/blocks/InstructionSlider')) as React.FC,
    social: dynamic(() => import('@/components/blocks/socials/Socials')) as React.FC,
    connectionRequestForm: dynamic(() => import('@/components/blocks/forms/ConnectionRequestForm')) as React.FC,
    internetAcquiringRequestForm: dynamic(
        () => import('@/components/blocks/forms/InternetAcquiringRequestForm')
    ) as React.FC,
    hrFaq: dynamic(() => import('@/components/blocks/HrFaq')) as React.FC,
    secondaryPreferentialCalculator: dynamic(
        () => import('@/components/blocks/calculators/layouts/SecondaryPreferentialCalculator')
    ) as React.FC,
    text: dynamic(() => import('@/components/blocks/Text')) as React.FC,
    formBlock: dynamic(() => import('@/components/blocks/forms/FormBlock')) as React.FC,
    // По хорошему объединить в 1калькулятор, клиент попросил разделить на 5 разных калькуляторов.
    profitabilityCalculatorHotSeasons: dynamic(
        () => import('@/components/blocks/calculators/layouts/ProfitabilityCalculator')
    ) as React.FC,
    profitabilityCalculatorCapital: dynamic(
        () => import('@/components/blocks/calculators/layouts/ProfitabilityCalculator')
    ) as React.FC,
    profitabilityCalculatorProfit: dynamic(
        () => import('@/components/blocks/calculators/layouts/ProfitabilityCalculator')
    ) as React.FC,
    profitabilityCalculatorPension: dynamic(
        () => import('@/components/blocks/calculators/layouts/ProfitabilityCalculator')
    ) as React.FC,
    profitabilityCalculatorCumulative: dynamic(
        () => import('@/components/blocks/calculators/layouts/ProfitabilityCalculator')
    ) as React.FC,
    cardApplication: dynamic(() => import('@/components/blocks/frames/CardApplication')) as React.FC,
    buildingCalculator: dynamic(() => import('@/components/blocks/calculators/layouts/BuildingCalculator')) as React.FC,
    buildingCalculatorWithPicture: dynamic(
        () => import('@/components/blocks/calculators/layouts/BuildingCalculatorWithPicture')
    ) as React.FC,
    dictionary: dynamic(() => import('@/components/blocks/dictionary/Dictionary')) as React.FC,
    unsubscribeNews: dynamic(() => import('@/components/blocks/frames/UnsubscribeNews')) as React.FC,
    onlineStorePartners: dynamic(() => import('@/components/blocks/frames/OnlineStorePartners')) as React.FC,
    newYearQuiz: dynamic(() => import('@/components/blocks/frames/NewYearQuiz')) as React.FC,
    errors: dynamic(() => import('@/components/Errors')) as React.FC,
    mirSupremeCard: dynamic(() => import('@/components/blocks/frames/MirSupremeCard')) as React.FC,
    vezdedohodCard: dynamic(() => import('@/components/blocks/frames/VezdedohodCard')) as React.FC,
    purchases: dynamic(() => import('@/components/blocks/purchases/Purchases')) as React.FC,
    feedbackFormFrame: dynamic(() => import('@/components/blocks/frames/FeedbackFormFrame')) as React.FC,
    youTubeVideos: dynamic(() => import('@/components/blocks/youTubeVideos/YouTubeVideos')) as React.FC,
    secondaryCashCalculator: dynamic(
        () => import('@/components/blocks/calculators/layouts/SecondaryCashCalculator')
    ) as React.FC,
    savings: dynamic(() => import('@/components/blocks/frames/PollSavings'), {
        ssr: false,
    }) as React.FC,
    safeAccountCalculator: dynamic(
        () => import('@/components/blocks/calculators/layouts/SafeAccountCalculator')
    ) as React.FC,
    moneyBoxAccountCalculator: dynamic(
        () => import('@/components/blocks/calculators/layouts/MoneyBoxAccountCalculator')
    ) as React.FC,
    profitabilityMonthCalculator: dynamic(
        () => import('@/components/blocks/calculators/layouts/ProfitabilityMonthCalculator')
    ) as React.FC,
    creditCard: dynamic(() => import('@/components/blocks/frames/CreditCard')) as React.FC,
    creditForm: dynamic(() => import('@/components/blocks/frames/CreditForm')) as React.FC,
    creditCardTypeCredit: dynamic(() => import('@/components/blocks/frames/CreditCardTypeCredit')) as React.FC,
    dzenPage: dynamic(() => import('@/components/blocks/articleList/ArticleList')) as React.FC,
    digitalCardApplicationForm: dynamic(
        () => import('@/components/blocks/forms/DigitalCardApplicationForm')
    ) as React.FC,
    connectOnlineForm: dynamic(() => import('@/components/blocks/forms/ConnectOnlineForm')) as React.FC,
    universalExternalIframe: dynamic(() => import('@/components/blocks/frames/UniversalExternalIframe')) as React.FC,
    universalExternalFormScript: dynamic(
        () => import('@/components/blocks/frames/UniversalExternalFormScript')
    ) as React.FC,
    feedbackUlIpSurvey: dynamic(() => import('@/components/blocks/frames/FeedbackUlIpSurvey')) as React.FC,
    confirmEmailForm: dynamic(() => import('@/components/blocks/forms/ConfirmEmailForm')) as React.FC,
    partnersMap: dynamic(() => import('@/components/blocks/PartnersMap')) as React.FC,
    articlesDetail: dynamic(() => import('@/components/blocks/ArticlesDetail')) as React.FC,
    mainArticlesSlider: dynamic(() => import('@/components/blocks/MainArticlesSlider')) as React.FC,
    crmOffers: dynamic(() => import('@/components/blocks/frames/CrmOffers'), {
        ssr: false,
    }) as React.FC,
    articlesListForDetail: dynamic(() => import('@/components/blocks/ArticlesListForDetail')) as React.FC,
    crmOffersLinkBlock: dynamic(() => import('@/components/blocks/frames/CrmOffersLinkBlock')) as React.FC,
    tariffCompare: dynamic(() => import('@/components/blocks/TariffCompare')) as React.FC,
    selectableWithValue: dynamic(() => import('@/components/blocks/SelectableWithValue')) as React.FC,
    diagram: dynamic(() => import('@/components/blocks/Diagram')) as React.FC,
    lightBanner: dynamic(() => import('@/components/blocks/banners/LightBanner')) as React.FC,
    darkBanner: dynamic(() => import('@/components/blocks/banners/DarkBanner')) as React.FC,
    stepSelection: dynamic(() => import('@/components/blocks/StepSelection')) as React.FC,
    searchResultsPage: dynamic(() => import('@/components/blocks/SearchResultsPage')) as React.FC,
    promov2: dynamic(() => import('@/components/blocks/PromoV2')) as React.FC,
    coloredBanner: dynamic(() => import('@/components/blocks/banners/ColoredBanner')) as React.FC,
    creditRealEstateCalculator: dynamic(
        () => import('@/components/blocks/calculators/layouts/CreditRealEstateCalculator')
    ) as React.FC,
    holidayBanner: dynamic(() => import('@/components/blocks/banners/HolidayBanner')) as React.FC,
    guitarBanner: dynamic(() => import('@/components/blocks/banners/GuitarBanner')) as React.FC,
    privateBankingForm: dynamic(() => import('@/components/blocks/PrivateBanking')) as React.FC,
    mapContainerForStaticCity: dynamic(
        () => import('@/components/blocks/frames/MapContainerForStaticCity')
    ) as React.FC,
    vkladDesyatochka: dynamic(() => import('@/components/blocks/frames/VkladDesyatochka')) as React.FC,
    hrLandingForm: dynamic(() => import('@/components/blocks/HRLanding')) as React.FC,
    citiesLoans: dynamic(() => import('@/components/blocks/CitiesLoans')) as React.FC,
    actionDetail: dynamic(() => import('@/components/blocks/ActionDetail')) as React.FC,
    actionsSlider: dynamic(() => import('@/components/blocks/ActionsSlider')) as React.FC,
    paperWork: dynamic(() => import('@/components/blocks/PaperWork')) as React.FC,
    pushHrLandingForm: dynamic(() => import('@/components/blocks/PushHRLanding')) as React.FC,
    comHrLandingForm: dynamic(() => import('@/components/blocks/ComHRLanding')) as React.FC,
    salesHrLandingForm: dynamic(() => import('@/components/blocks/SalesHRLanding')) as React.FC,
    workRemotelyLanding: dynamic(() => import('@/components/blocks/WorkRemotely')) as React.FC,
    advantagesInRefinance: dynamic(() => import('@/components/blocks/RefinanceAdvantages')) as React.FC,
    feedbackFormQuestions: dynamic(() => import('@/components/blocks/FeedbackFormQuestions')) as React.FC,
    feedbackFormBank: dynamic(() => import('@/components/blocks/FeedbackFormBank')) as React.FC,
    feedbackFormHotLine: dynamic(() => import('@/components/blocks/FeedbackFormHotLine')) as React.FC,
    feedbackFormOffRequests: dynamic(() => import('@/components/blocks/FeedbackFormOffRequests')) as React.FC,
    feedbackFormPretension: dynamic(() => import('@/components/blocks/FeedbackFormPretension')) as React.FC,
    feedbackFormPretensionSecond: dynamic(() => import('@/components/blocks/FeedbackFormPretensionSecond')) as React.FC,
    feedbackFormMortgage: dynamic(() => import('@/components/blocks/FeedbackFormMortgage')) as React.FC,
    distributionAskQuestion: dynamic(() => import('@/components/blocks/DistributionAskQuestion')) as React.FC,
    feedbackFormAppeal: dynamic(() => import('@/components/blocks/FeedbackFormAppeal')) as React.FC,
    hypothecCalculator: dynamic(() => import('@/components/blocks/calculators/layouts/HypothecCalculator')) as React.FC,
    moneyBoxCalculator: dynamic(() => import('@/components/blocks/calculators/layouts/MoneyBoxCalculator')) as React.FC,
    infoBlockCredit: dynamic(() => import('@/components/blocks/InfoBlockCredit')) as React.FC,
    flocktoryIntegration: dynamic(() => import('@/components/blocks/FlocktoryIntegration')) as React.FC,
    microTiles: dynamic(() => import('@/components/blocks/MicroTiles')) as React.FC,
    reviews: dynamic(() => import('@/components/blocks/Reviews')) as React.FC,
    tiledShowcase: dynamic(() => import('@/components/blocks/TiledShowcase')) as React.FC,
    infoBlockGradient: dynamic(() => import('@/components/blocks/InfoBlockGradient')) as React.FC,
    topChart: dynamic(() => import('@/components/blocks/TopChart')) as React.FC,
    cashbackPercentage: dynamic(() => import('@/components/blocks/CashbackPercentage')) as React.FC,
    blockWidget: dynamic(() => import('@/components/blocks/Widget')) as React.FC,
    productsAndServices: dynamic(() => import('@/components/blocks/ProductsAndServices')) as React.FC,
    carCalculator: dynamic(() => import('@/components/blocks/calculators/layouts/CarCalculator')) as React.FC,
    cashCreditCalculator: dynamic(
        () => import('@/components/blocks/calculators/layouts/CashCreditCalculator')
    ) as React.FC,
    faqQuestions: dynamic(() => import('@/components/blocks/FaqQuestions')) as React.FC,
    faqTheme: dynamic(() => import('@/components/blocks/faq/FAQTheme'), { ssr: false }) as React.FC,
    cashCredit: dynamic(() => import('@/components/blocks/CashCredit/')) as React.FC,
    promoPension: dynamic(() => import('@/components/blocks/PromoPension')) as React.FC,
    actionPensions: dynamic(() => import('@/components/blocks/ActionPensions')) as React.FC,
    specialAdvantages: dynamic(() => import('@/components/blocks/advantages/SpecialAdvantages')) as React.FC,
    textBanner: dynamic(() => import('@/components/blocks/banners/TextBanner'), {
        ssr: false,
    }) as React.FC,
    feedbackFormVTwo: dynamic(() => import('@/components/blocks/FeedbackFormV2'), {
        ssr: false,
    }) as React.FC,
    infoBlockPensiya: dynamic(() => import('@/components/blocks/InfoBlockPensiya/')) as React.FC,
    campaignBlock: dynamic(() => import('@/components/blocks/CampaignBlock/')) as React.FC,
    blockWidgetPoll: dynamic(() => import('@/components/blocks/WidgetPoll')) as React.FC,
    pensionTransferV2: dynamic(() => import('@/components/blocks/frames/PensionTransferV2')) as React.FC,
    hypothecReadyHousingCalculator: dynamic(
        () => import('@/components/blocks/calculators/layouts/HypothecReadyHousingCalculator')
    ) as React.FC,
    feedbackFormVThree: dynamic(() => import('@/components/blocks/FeedbackFormVThree')) as React.FC,
    internship2024: dynamic(() => import('@/components/blocks/Internship2024')) as React.FC,
    infoTransitionBlock: dynamic(() => import('@/components/blocks/InfoTransitionBlock')) as React.FC,
    instructionInfoGraphic: dynamic(() => import('@/components/blocks/InstructionInfoGrapfic')) as React.FC,
    rightButtonText: dynamic(() => import('@/components/blocks/RightButtonText')) as React.FC,
    feedbackFormSalaryCard: dynamic(() => import('@/components/blocks/FeedbackFormSalaryCard')) as React.FC,
    feedbackFormSalaryProject: dynamic(() => import('@/components/blocks/FeedbackFormSalaryProject')) as React.FC,
    tinyGradientCategory: dynamic(() => import('@/components/blocks/TinyGradientCategory')) as React.FC,
    tinyResponsiveCategory: dynamic(() => import('@/components/blocks/TinyResponsiveCategory')) as React.FC,
    mobileAppBanner: dynamic(() => import('@/components/blocks/MobileAppBanner')) as React.FC,
    pboFaq: dynamic(() => import('@/components/blocks/PboFaq')) as React.FC,
    pboFaqDetail: dynamic(() => import('@/components/blocks/PboFaqDetail')) as React.FC,
    promotionGradientBanner: dynamic(() => import('@/components/blocks/PromotionGradientBanner')) as React.FC,
    textImageRow: dynamic(() => import('@/components/blocks/TextImageRow')) as React.FC,
    persons: dynamic(() => import('@/components/blocks/Persons')) as React.FC,
    promoSuper: dynamic(() => import('@/components/blocks/PromoSuper')) as React.FC,
    photoDateBlock: dynamic(() => import('@/components/blocks/PhotoDateBlock')) as React.FC,
    iosAppLink: dynamic(() => import('@/components/blocks/frames/IosAppLink')) as React.FC,
    drawParticipationList: dynamic(() => import('@/components/blocks/draw/participation-list')) as React.FC,
    guaranteedPrizes: dynamic(() => import('@/components/blocks/GuaranteedPrizes')) as React.FC,
    guaranteeCalculator: dynamic(
        () => import('@/components/blocks/calculators/layouts/GuaranteeCalculator')
    ) as React.FC,
    mainPrizes: dynamic(() => import('@/components/blocks/draw/main-prizes')) as React.FC,
    corgis: dynamic(() => import('@/components/blocks/Corgis/')) as React.FC,
};
