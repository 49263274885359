import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { useAppStore } from '@/context/AppStoreContext';
import { useCity } from '@/context/CityProvider';
// eslint-disable-next-line import/no-cycle
import { defaultBlocksConfig } from '@/tina/config/index.config';
import { IDefaultBlock } from '@/types/tina/block';
import { apiRequest } from '@/utils/request';

interface IProps {
    block: IDefaultBlock;
    config?: { [name: string]: React.FC };
    [name: string]: unknown;
}

const Block: React.FC<IProps> = observer(({ block, config = defaultBlocksConfig, ...rest }) => {
    const [data, setData] = useState<IDefaultBlock>(() => block);
    const [isDataFetching, setIsDataFetching] = useState(false);
    const [path] = useAppStore(store => store.path);
    const { currentCity } = useCity();

    const fetchBlockData = async () => {
        setIsDataFetching(true);
        const response = await apiRequest.get<{ data: IDefaultBlock }>(
            `/page/block?path=${path}&block=${block?._template}&section=${block?.section}`
        );
        const {
            data: { data: fetchedData },
        } = response;

        setData(fetchedData);
        setIsDataFetching(false);
    };

    useEffect(() => {
        if (block?.geo) {
            fetchBlockData().catch(console.error);
        }
    }, [currentCity]);

    if (!block?._template || !(block._template in config)) return null;

    const Component: React.FC<IDefaultBlock> = config[block._template];

    return <Component {...data} {...rest} isDataFetching={isDataFetching} />;
});

export default Block;
