import Image from 'next/image';
import React from 'react';

import cn from './style.module.sass';

interface IQuestionMark {
    textTooltip: string;
    color: string;
}

const QuestionMark = ({ textTooltip, color }: IQuestionMark) => (
    <button className={cn.questionMark} type="button" disabled>
        {textTooltip && <div className={cn.questionMarkText}>{textTooltip}</div>}
        <Image
            width={14}
            height={14}
            src={color === 'light' ? '/img-next/svg/questionmark-light.svg' : '/img-next/svg/questionmark-dark.svg'}
            alt="questionmark"
            className={cn.questionMark}
        />
    </button>
);
export default QuestionMark;
