import React, { FC } from 'react';

import cn from './style.module.sass';

const Search: FC = () => (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.7929 13.7929C15.1834 13.4024 15.8166 13.4024 16.2071 13.7929L20.2071 17.7929C20.5976 18.1834 20.5976 18.8166 20.2071 19.2071C19.8166 19.5976 19.1834 19.5976 18.7929 19.2071L14.7929 15.2071C14.4024 14.8166 14.4024 14.1834 14.7929 13.7929Z"
            className={cn.svgFill}
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.5 6C9.01472 6 7 8.01472 7 10.5C7 12.9853 9.01472 15 11.5 15C13.9853 15 16 12.9853 16 10.5C16 8.01472 13.9853 6 11.5 6ZM5 10.5C5 6.91015 7.91015 4 11.5 4C15.0899 4 18 6.91015 18 10.5C18 14.0899 15.0899 17 11.5 17C7.91015 17 5 14.0899 5 10.5Z"
            className={cn.svgFill}
        />
    </svg>
);

export default Search;
