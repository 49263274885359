import clsx from 'clsx';
import React, { FC } from 'react';

import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

interface IProps {
    gtag?: GTagEvent;
}
const Contacts: FC<IProps> = ({ gtag }) => {
    const generateEvent = () => {
        if (gtag?.action) event(gtag);
    };
    return (
        <a
            href="https://www.pochtabank.ru/map"
            target="_blank"
            className={clsx(cn.contacts)}
            rel="noreferrer"
            onClick={generateEvent}
        >
            <span>Отделения и банкоматы</span>
        </a>
    );
};

export default Contacts;
