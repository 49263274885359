import React from 'react';

import cn from './style.module.sass';

export function DzenPreloader() {
    return (
        <>
            <div className={cn.currentItem}>
                <div className={cn.content} />
            </div>
            <div className={cn.currentItem}>
                <div className={cn.content} />
            </div>
            <div className={cn.currentItem}>
                <div className={cn.content} />
            </div>
            <div className={cn.currentItem}>
                <div className={cn.content} />
            </div>
            <div className={cn.currentItem}>
                <div className={cn.content} />
            </div>
            <div className={cn.currentItem}>
                <div className={cn.content} />
            </div>
            <div className={cn.currentItem}>
                <div className={cn.content} />
            </div>
            <div className={cn.currentItem}>
                <div className={cn.content} />
            </div>
            <div className={cn.currentItem}>
                <div className={cn.content} />
            </div>
        </>
    );
}
