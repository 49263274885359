'use client';

import React, { useContext } from 'react';

import TinaLibContext from '@/context/TinaLibContext';
import { useTinaDynamicImport } from '@/hooks/useDynamicTinaImport';

export const useTinaLibStore = () => {
    const context = useContext(TinaLibContext);

    if (context === undefined) {
        throw new Error('undefined StoreProvider');
    }

    return context;
};

interface IProps {
    children?: React.ReactNode;
}

export const TinaLibProvider: React.FC<IProps> = ({ children }) => {
    const { Tina, TinaInline, ReactTinacmsEditor, DateFieldPlugin, loaded } = useTinaDynamicImport();

    if (!loaded) return null;

    return (
        <TinaLibContext.Provider value={{ Tina, TinaInline, ReactTinacmsEditor, DateFieldPlugin }}>
            {children}
        </TinaLibContext.Provider>
    );
};
