import { useEffect, useState } from 'react';

import { IAntiFlicker } from '@/types/pageParams';

function useAntiFlicker(antiFlicker: IAntiFlicker) {
    const [hidePage, setHidePage] = useState(() => !!antiFlicker?.isActive);

    useEffect(() => {
        if (!antiFlicker) return;

        if (!antiFlicker?.isActive) return;

        setTimeout(() => {
            setHidePage(false);
        }, antiFlicker?.timeout || 1000);
    }, [antiFlicker?.isActive, antiFlicker?.timeout]);

    return hidePage;
}

export default useAntiFlicker;
