import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
import React, { FC, startTransition, useEffect, useMemo, useRef, useState } from 'react';

import { HeaderProps, MenuConfig } from '@/components/layout/Header/Header/types';
import HeaderBottom from '@/components/layout/Header/HeaderBottom';
import HeaderSearchForm from '@/components/layout/Header/HeaderSearchForm';
import HeaderTopGroup from '@/components/layout/Header/HeaderTopGroup';
import MenuTop from '@/components/layout/Header/MenuTop';
import NotificationCashLoan from '@/components/layout/Header/NotificationCashLoan';
import BackOverlay from '@/components/modal/BackOverlay';
import PanelOldVersion from '@/components/PanelOldVersion';
import FeatureWrapper from '@/components/utility/FeatureWrapper';
import { useAppStore } from '@/context/AppStoreContext';
import { useLayoutStore } from '@/context/LayoutStoreContext';
import { useMobileWidth, useTabletSmallWidth } from '@/hooks/useMedia';

const MenuCollapsed = dynamic(() => import('@/components/layout/Header/MenuCollapsed'), {
    ssr: true,
});

const HeaderLite = dynamic(() => import('@/components/layout/Header/Header/lite'), { ssr: true });

const Header: FC<HeaderProps> = ({
    headerMainMenu,
    headerCollapsedMenu,
    headerTopMenu,
    hiddenHeaderMenu,
    isDisabled,
    isStatic,
    logoLink,
    gtag,
    isBusinessPage,
    mobileButton,
    headerSocial,
    liteHeaderButton,
    withTopPanel,
    liteHeaderDesktop,
    liteHeaderMobile,
    isLiteHeader,
}) => {
    const [path] = useAppStore(store => store.path);
    const [isTinaView] = useAppStore(store => store.isTinaView);
    const {
        isHeaderSticky,
        isLiteHeaderMobile,
        isLiteHeaderDesktop,
        setHeaderSticky,
        setLiteHeaderDesktop,
        setLiteHeaderMobile,
    } = useLayoutStore();

    const { isExternalFrameView } = useLayoutStore();

    const asPath = usePathname();

    const [menuConfig, setMenuConfig] = useState<MenuConfig>({
        show: false,
        active: '',
    });

    const [isSearchActive, setIsSearchActive] = useState<boolean>(false);

    const isTabletSmall = useTabletSmallWidth(true);
    const isMobile = useMobileWidth(true);

    const showSearchHandler = () => {
        setIsSearchActive(true);
    };

    const hideSearchHandler = () => {
        setIsSearchActive(false);
    };

    const headerRef = useRef<HTMLElement>(null);

    const showMenu = (active: string) => {
        setMenuConfig({ show: true, active });
    };

    const hideMenu = () => {
        setMenuConfig({ show: false, active: '' });
    };

    useEffect(() => {
        const value = !isStatic && !isExternalFrameView;
        setHeaderSticky(value);
    }, [path, isStatic, isExternalFrameView]);

    useEffect(() => {
        startTransition(() => {
            setLiteHeaderDesktop(liteHeaderDesktop);
        });
    }, [liteHeaderDesktop]);

    useEffect(() => {
        startTransition(() => {
            setLiteHeaderMobile(liteHeaderMobile);
        });
    }, [liteHeaderMobile]);

    useEffect(() => {
        if (isLiteHeader) {
            setLiteHeaderMobile(true);
            setLiteHeaderDesktop(true);
        }
    }, [isLiteHeader]);

    useEffect(() => {
        if (headerRef.current) {
            const observerHeader = new IntersectionObserver(
                ([e]) => e.target.classList.toggle('scrolled', e.intersectionRatio < 1),
                { threshold: [1] }
            );

            observerHeader.observe(headerRef.current);
        }
    }, [headerRef.current]);

    const filteredMainMenu = useMemo(
        () => headerMainMenu?.filter(item => !!item?.isBusiness === !!isBusinessPage),
        [headerMainMenu, isBusinessPage]
    );

    const isCashloanPage = asPath.includes('/service/cashloan');

    const logoLinkModified = isCashloanPage ? '/service/loans' : logoLink;

    if (isDisabled) return null;

    if (
        (isLiteHeaderMobile && isMobile) ||
        (isLiteHeaderDesktop && !isMobile) ||
        (isLiteHeaderDesktop && isLiteHeaderMobile)
    ) {
        return (
            <HeaderLite
                headerMainMenu={headerMainMenu}
                headerCollapsedMenu={headerCollapsedMenu}
                headerTopMenu={headerTopMenu}
                hiddenHeaderMenu={hiddenHeaderMenu}
                isDisabled={isDisabled}
                isStatic={isStatic}
                logoLink={logoLinkModified}
                gtag={gtag}
                isBusinessPage={isBusinessPage}
                mobileButton={mobileButton}
                headerSocial={headerSocial}
                hideMenu={hideMenu}
                showSearchHandler={showSearchHandler}
                hideSearchHandler={hideSearchHandler}
                isSearchActive={isSearchActive}
                isTinaView={isTinaView}
                liteHeaderButton={liteHeaderButton}
                withTopPanel={withTopPanel}
            />
        );
    }

    return (
        <>
            {!isTabletSmall && (
                <>
                    <FeatureWrapper name="enableSearchYandex">
                        <div className={clsx('headerSearch', isSearchActive && 'headerSearchActive')}>
                            <HeaderSearchForm hideSearchHandler={hideSearchHandler} flag={isSearchActive} />
                            <BackOverlay showOverlay={isSearchActive} closeOverlay={hideSearchHandler} />
                        </div>
                    </FeatureWrapper>
                    <div className="headerTop" onMouseEnter={hideMenu}>
                        <div className="headerTopWrap">
                            <div className="headerTopGrid">
                                <div className="headerTopItem headerTopItemLeft">
                                    <MenuTop headerTopMenu={headerTopMenu} isBusinessPage={isBusinessPage} />
                                </div>
                                <div className="headerTopItem headerTopItemCenter">
                                    <PanelOldVersion gtag={gtag} />
                                </div>
                                <div className="headerTopItem headerTopItemRight">
                                    <HeaderTopGroup
                                        gtag={gtag}
                                        showSearchHandler={showSearchHandler}
                                        headerSocial={headerSocial}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <header
                ref={headerRef}
                onMouseLeave={hideMenu}
                className={clsx('headerBottom', isHeaderSticky && 'sticky', isTinaView && 'headerTina')}
            >
                <div className="headerBottomSticky">
                    <NotificationCashLoan />

                    <div className="headerBottomStickyWrap">
                        <HeaderBottom
                            hideMenu={hideMenu}
                            showMenu={showMenu}
                            gtag={gtag}
                            logoLink={logoLink}
                            menuConfig={menuConfig}
                            headerMainMenu={filteredMainMenu}
                            hiddenHeaderMenu={hiddenHeaderMenu}
                            mobileButton={mobileButton}
                            isBusinessPage={isBusinessPage}
                        />
                        {!isTabletSmall && (
                            <MenuCollapsed
                                menuConfig={menuConfig}
                                hideMenu={hideMenu}
                                headerCollapsedMenu={headerCollapsedMenu}
                            />
                        )}
                    </div>
                </div>
            </header>
        </>
    );
};
export default observer(Header);
