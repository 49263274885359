import clsx from 'clsx';
import React from 'react';
import { Media } from 'tinacms';

import { useTinaLibStore } from '@/hooks/useTina';

import cn from './style.module.sass';

interface IProps {
    className: string;
    name: string;
    customTag?: keyof JSX.IntrinsicElements;
    isTextArea?: boolean;
    htmlValue?: string;
    simpleField?: boolean;
    isHTML?: boolean;
    offset?: number | { x: number; y: number };
    text?: string;
}

interface ITinaTextFieldContent {
    name: string;
    isTextArea?: boolean;
    isHTML?: boolean;
    simpleField?: boolean;
    htmlValue?: string;
    offset?: number | { x: number; y: number };
    text?: string;
}

const TinaTextFieldContent: React.FC<ITinaTextFieldContent> = ({
    isTextArea,
    isHTML,
    simpleField,
    name,
    htmlValue,
    offset,
    text,
}) => {
    const { TinaInline, ReactTinacmsEditor } = useTinaLibStore();
    const { InlineWysiwyg } = ReactTinacmsEditor;
    const { InlineText, InlineTextarea } = TinaInline;

    if (simpleField && htmlValue?.length > 0) {
        return <span className={cn.simpleField} dangerouslySetInnerHTML={{ __html: htmlValue }} />;
    }

    if (simpleField) {
        return <span className={cn.simpleField} dangerouslySetInnerHTML={{ __html: text }} />;
    }

    if (isTextArea) {
        return <InlineTextarea name={name} />;
    }

    if (isHTML) {
        return (
            <InlineWysiwyg
                name={name}
                format="html"
                imageProps={{ parse: (media: Media & { src: string }) => media.src }}
                focusRing={{ offset }}
            >
                <div dangerouslySetInnerHTML={{ __html: htmlValue }} />
            </InlineWysiwyg>
        );
    }

    return <InlineText name={name} />;
};

const TinaTextField: React.FC<IProps> = ({
    className,
    name,
    htmlValue,
    isTextArea,
    isHTML,
    simpleField,
    customTag,
    offset,
    text,
    ...rest
}) => {
    if (customTag) {
        const Tag = customTag;

        return (
            <Tag className={clsx(className, cn.textField)} {...rest}>
                <TinaTextFieldContent
                    isTextArea={isTextArea}
                    isHTML={isHTML}
                    name={name}
                    simpleField={simpleField}
                    htmlValue={htmlValue}
                    offset={offset}
                />
            </Tag>
        );
    }

    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div className={className} {...rest}>
            <TinaTextFieldContent
                isTextArea={isTextArea}
                isHTML={isHTML}
                name={name}
                simpleField={simpleField}
                htmlValue={htmlValue}
                offset={offset}
            />
        </div>
    );
};

export default TinaTextField;
