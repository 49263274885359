import { useCallback, useEffect, useRef, useState } from 'react';

export const useWindowScroll = (throttle = 200, isActive = true) => {
    const scrollingTimer = useRef<ReturnType<typeof setTimeout>>();
    const [windowScroll, setWindowScroll] = useState<number>(0);

    const handleScroll = () => setWindowScroll(window.scrollY);

    const listenToScroll = useCallback(() => {
        window.removeEventListener('scroll', listenToScroll);
        clearTimeout(scrollingTimer.current);
        scrollingTimer.current = setTimeout(() => window.addEventListener('scroll', listenToScroll), throttle);
        handleScroll();
    }, [handleScroll, isActive]);

    useEffect(() => {
        if (!isActive) return null;

        handleScroll();
        window.addEventListener('scroll', listenToScroll, { passive: true });
        return () => {
            window.removeEventListener('scroll', listenToScroll);
            clearTimeout(scrollingTimer.current);
        };
    }, [isActive]);

    return windowScroll;
};
