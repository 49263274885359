import { useMedia } from 'react-use';

import { MOBILE_SM_WIDTH, MOBILE_WIDTH, TABLET_MOBILE, TABLET_SMALL_WIDTH, TABLET_WIDTH } from '@/utils/constants';

export const useMobileWidth = (defaultState?: boolean) => useMedia(`(max-width: ${MOBILE_WIDTH}px)`, defaultState);

export const useMobileSmallWidth = (defaultState?: boolean) =>
    useMedia(`(max-width: ${MOBILE_SM_WIDTH}px)`, defaultState);

export const useTabletWidth = (defaultState?: boolean) => useMedia(`(max-width: ${TABLET_WIDTH}px)`, defaultState);

export const useTabletMobileWidth = (defaultState?: boolean) =>
    useMedia(`(max-width: ${TABLET_MOBILE}px)`, defaultState);

export const useTabletSmallWidth = (defaultState?: boolean) =>
    useMedia(`(max-width: ${TABLET_SMALL_WIDTH}px)`, defaultState);
