import featureList from '../../../feature.json';

type TFeature = {
    [name: string]: boolean;
};

interface IFeature {
    isFeatureEnabled: (name: string) => boolean;
}

class Feature implements IFeature {
    private readonly featuresList: TFeature = null;

    constructor(featuresList: TFeature) {
        this.featuresList = featuresList;
    }

    public isFeatureEnabled = (name: string): boolean => Boolean(this.featuresList[name]);
}

export default new Feature(featureList);
