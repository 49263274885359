export const getScrollbarWidth = () => {
    try {
        const outer = document.createElement('div');
        outer.style.visibility = 'hidden';
        outer.style.overflow = 'scroll';
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        outer.style.msOverflowStyle = 'scrollbar';
        document.body.appendChild(outer);

        const inner = document.createElement('div');
        outer.appendChild(inner);

        const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

        outer.parentNode.removeChild(outer);

        return scrollbarWidth;
    } catch (e) {
        console.error('Ошибка, при расчете ширины скролбара.');

        return 0;
    }
};
