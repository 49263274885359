import Color from '@tiptap/extension-color';
import Highlight from '@tiptap/extension-highlight';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import Subscript from '@tiptap/extension-subscript';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import { StarterKit } from '@tiptap/starter-kit';

import { Anchor } from '@/components/UI/TipTap/AnchorNode/Extension';
import { Blockquote } from '@/components/UI/TipTap/BlockquoteNode/Extension';
import { BulletList } from '@/components/UI/TipTap/BulletListNode/Extension';
import { Indent } from '@/components/UI/TipTap/IndentNode/Extension';
import { OrderedList } from '@/components/UI/TipTap/OrderedListNode/Extension';
import QuestionMark from '@/components/UI/TipTap/QuestionMarkNode/Extension';

export const baseExtensions = [
    StarterKit.configure({
        heading: { levels: [1, 2, 3, 4, 5, 6] },
        hardBreak: { keepMarks: false },
        blockquote: false,
        bulletList: false,
        orderedList: false,
    }),
    OrderedList.configure({
        HTMLAttributes: {
            class: 'orderedList',
        },
    }),
    BulletList.configure({
        HTMLAttributes: {
            class: 'bulletList',
        },
    }),
    Blockquote,
    QuestionMark,
    Anchor,
    Indent,
    TextStyle,
    Color,
    Highlight.configure({ multicolor: true }),
    Image.configure({ inline: true, allowBase64: true }),
    Subscript,
    TextAlign.configure({
        types: ['heading', 'paragraph'],
    }),
    Underline,
    Link.configure({
        openOnClick: true,
        autolink: true,
    }),
];

export const getTableExtensions = async () => {
    const TableExtension = await import('@tiptap/extension-table');
    const TableRowExtension = await import('@tiptap/extension-table-row');
    const TableHeaderExtension = await import('@tiptap/extension-table-header');
    const TableCellExtension = await import('@tiptap/extension-table-cell');

    return [
        TableExtension.Table.configure({
            resizable: true,
        }),
        TableRowExtension.TableRow,
        TableHeaderExtension.TableHeader,
        TableCellExtension.TableCell,
    ];
};
