import React from 'react';

import Button from '@/components/UI/Button';
import { IButton } from '@/types/animatedPromo/item';
import { event } from '@/components/shared/utilities/analytics/metrics';

const AnimatedPromoButton: React.FC<{ button: IButton }> = ({ button }) => {
    if (button.type !== 'button') {
        return (
            <a
                href={button.link}
                className="animatedPromoSliderBtnlink"
                onClick={() => {
                    if (button?.gtag?.action) event(button?.gtag);
                }}
            >
                {button.text}
            </a>
        );
    }

    return (
        <>
            <div className="onlyTabletLg">
                <Button
                    variant="btnWhite"
                    type="link"
                    href={button.link}
                    label={button.text}
                    customClass="full slider-btn "
                    animation={false}
                    onClick={() => {
                        if (button?.gtag?.action) event(button?.gtag);
                    }}
                />
            </div>
            <div className="onlyDesktop">
                <Button
                    variant="btnBlue"
                    type="link"
                    href={button.link}
                    label={button.text}
                    customClass="full slider-btn onlyDesktop"
                    animation={false}
                    onClick={() => {
                        if (button?.gtag?.action) event(button?.gtag);
                    }}
                />
            </div>
        </>
    );
};

export default AnimatedPromoButton;
