import React from 'react';

const Arrow: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clipPath="url(#clip1)">
            <path
                d="M7.29071 12.1237L16.7155 11.8753"
                className="panelOldVersionStroke"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.8789 7.28711L16.7155 11.8753L12.1273 16.7119"
                className="panelOldVersionStroke"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip1">
                <rect width="16" height="16" fill="white" transform="translate(11.7031 0.689453) rotate(43.4901)" />
            </clipPath>
        </defs>
    </svg>
);

export default Arrow;
