import React, { FC, useEffect, useRef } from 'react';

export const withBlockLinks = <T,>(Component: FC<T>) => {
    const BlockLinksComponent: FC<T> = (props: T & { productMenuLink: string }) => {
        const { productMenuLink } = props;

        const isScrolled = useRef<boolean>(false);
        const blockLink = useRef<HTMLDivElement | null>(null);

        const searchParams = typeof window !== 'undefined' && new URLSearchParams(window.location.search);

        useEffect(() => {
            if (searchParams && !isScrolled.current) {
                const tab = searchParams?.get('tab');
                const hash = typeof window !== 'undefined' && window?.location?.hash;
                const id = hash?.slice(1);

                if (tab && hash && blockLink && blockLink?.current.id && id === productMenuLink) {
                    blockLink.current.scrollIntoView({ block: 'center', behavior: 'smooth' });

                    isScrolled.current = true;
                }
            }
        }, []);

        return (
            <div id={productMenuLink} ref={blockLink}>
                <Component {...(props as T)} />
            </div>
        );
    };

    return BlockLinksComponent;
};
