import React, { FC, useEffect, useRef, useState } from 'react';

import CustomImage from '@/components/CustomImage';
import { useForceUpdate } from '@/hooks/useForceUpdate';
import { useIsomorphicEffect } from '@/hooks/useIsomorphicEffect';

export type AnimationImageProps = {
    animationImage: string;
    animationImageWebm: string;
    isActive: boolean;
    priority?: boolean;
};

// @TODO: когда будет готов конвертер gif -> webm, передать сюда ссылку на видео и рендерить видео элемент

const AnimationImage: FC<AnimationImageProps> = ({
    animationImage,
    animationImageWebm,
    isActive,
    priority = false,
}) => {
    const [showVideo, setShowVideo] = useState(false);
    const forceUpdate = useForceUpdate();

    const videoRef = useRef<HTMLVideoElement>(null);

    useIsomorphicEffect(() => {
        const obj = document.createElement('video');
        const canPlayWebm = obj.canPlayType('video/webm');
        const isSafari = navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1;
        setShowVideo((canPlayWebm === 'probably' || canPlayWebm === 'maybe') && !isSafari);
    }, []);

    useEffect(() => {
        if (!videoRef.current) return;

        if (isActive) {
            videoRef.current.play().catch(console.error);
        } else {
            videoRef.current.pause();
        }
    }, [isActive]);

    useEffect(() => {
        forceUpdate();
    }, [animationImageWebm]);

    if (showVideo && animationImageWebm) {
        return (
            <video
                key={animationImageWebm}
                ref={videoRef}
                muted
                loop
                autoPlay
                controls={false}
                className="animatedPromoImageVideo"
            >
                <source src={animationImageWebm} />
            </video>
        );
    }

    return (
        <CustomImage
            sizes="(max-width: 768px) 35vw,
                   (max-width: 1200px) 40vw,
                   35vw"
            src={animationImage}
            alt="Анимация"
            fill
            loading="eager"
            priority={priority}
        />
    );
};

export default AnimationImage;
