import React, { FC, useEffect, useRef, useState } from 'react';

import CustomImage from '@/components/CustomImage';
import { useForceUpdate } from '@/hooks/useForceUpdate';
import { useIsomorphicEffect } from '@/hooks/useIsomorphicEffect';

export type PromoVideoProps = {
    animationImageWebm?: string;
    animationImage?: string;
};

const PromoVideo: FC<PromoVideoProps> = ({ animationImageWebm, animationImage }) => {
    const [showVideo, setShowVideo] = useState(false);
    const forceUpdate = useForceUpdate();

    const videoRef = useRef<HTMLVideoElement>(null);

    useIsomorphicEffect(() => {
        const obj = document.createElement('video');
        const canPlayWebm = obj.canPlayType('video/webm');
        const isSafari = navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1;
        setShowVideo((canPlayWebm === 'probably' || canPlayWebm === 'maybe') && !isSafari);
    }, []);

    useEffect(() => {
        if (!videoRef.current) return;

        videoRef.current.play().catch(console.error);
    }, []);

    useEffect(() => {
        forceUpdate();
    }, [animationImageWebm]);

    if (showVideo && animationImageWebm) {
        return (
            <video
                key={animationImageWebm}
                ref={videoRef}
                muted
                loop
                autoPlay
                controls={false}
                className="promoImageVideo"
            >
                <source src={animationImageWebm} />
            </video>
        );
    }

    return <CustomImage src={animationImage} alt="Анимация" fill loading="eager" priority />;
};

export default PromoVideo;
