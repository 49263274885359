import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

import { useCity } from '@/context/CityProvider';

import Icon from './icon';
import cn from './style.module.sass';

const Location: FC = observer(() => {
    const { currentCity } = useCity();
    return (
        <div className={clsx(cn.location)}>
            <span>{currentCity?.city || 'Город не определен'}</span>
            <Icon />
        </div>
    );
});

export default Location;
