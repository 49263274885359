import React, { FC } from 'react';

import IconHeaderSearch from '@/components/UI/IconHeaderSearch';

import cn from './style.module.sass';

interface IProps {
    showSearchHandler?: () => void;
}
const HeaderSearch: FC<IProps> = ({ showSearchHandler }) => (
    <div className={cn.wrapper} role="presentation" onClick={showSearchHandler}>
        <IconHeaderSearch />
        <span>Поиск</span>
    </div>
);

export default HeaderSearch;
