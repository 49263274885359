import { useEffect, useState } from 'react';

import Feature from '@/domain/feature/Feature';
/* eslint-disable */
export const useTinaDynamicImport = () => {
    const isNewEditor = Feature.isFeatureEnabled('isNewEditor');

    const [Tina, setTina] = useState<typeof import('tinacms')>(null);
    const [TinaInline, setTinaInline] = useState<typeof import('react-tinacms-inline')>(null);
    const [ReactTinacmsEditor, setReactTinacmsEditor] = useState<typeof import('react-tinacms-editor')>(null);
    const [DateFieldPlugin, setDateFieldPlugin] = useState<typeof import('react-tinacms-date').DateFieldPlugin>(null);
    const [loaded, setLoaded] = useState(false);

    const asyncImport = async () => {
        const importedTina = await import('tinacms');
        const importedTinaInline = await import('react-tinacms-inline');
        const importedDateFieldPlugin = await import('react-tinacms-date');
        setTina(importedTina);
        setTinaInline(importedTinaInline);
        setDateFieldPlugin(importedDateFieldPlugin.DateFieldPlugin);

        if (!isNewEditor) {
            const importedReactTinacmsEditor = await import('react-tinacms-editor');
            setReactTinacmsEditor(importedReactTinacmsEditor);
        }

        setLoaded(true);
    };

    useEffect(() => {
        asyncImport();
    }, []);

    return { Tina, TinaInline, ReactTinacmsEditor, DateFieldPlugin, loaded };
};
