import React from 'react';

import AnimatedComponent from '@/components/Animation';

import cn from './style.module.sass';

export function NewsPreloader() {
    return (
        <AnimatedComponent classNameActive={cn.animationInit}>
            <div className={cn.item} />
            <div className={cn.item} />
            <div className={cn.item} />
            <div className={cn.item} />
        </AnimatedComponent>
    );
}
