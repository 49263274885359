import $ from 'jquery/dist/jquery.slim.min';
import { usePathname, useSearchParams } from 'next/navigation';
import React, { useEffect, useState } from 'react';

import { GoogleScripts, YA_METRIKA_ID } from '@/components/shared/utilities/analytics/gtm';
import { MatomoScripts } from '@/components/shared/utilities/analytics/matomo';
import CityStore from '@/domain/city/store';
import MetricsStore from '@/domain/metrics/Store';

export type GTagEvent = {
    action: string;
    category: string;
    label: string;
    value?: number;
    context?: string;
    location?: string;
    eventName?: string;
    contextId?: string | number;
};

export const event = (gtag: GTagEvent): void => {
    if (typeof window === 'undefined') {
        return;
    }

    if (!gtag?.action) return;

    const { action, category, label, location, context } = gtag;

    const params = MetricsStore.additionalAnalyticsParams;

    const prefix = MetricsStore.pagePrefix;
    const correctLabel = prefix + label;

    window?.dataLayer?.push({
        event: 'OWOX',
        eventCategory: category,
        eventAction: action,
        eventLabel: correctLabel,
        eventContext: correctLabel,
        eventPosition: '1',
        eventPortal: null,
        eventProductId: null,
        eventProductName: 'cash',
        eventProductBrand: 'Почтабанк',
    });

    if (!window?.ga) return;

    window?.ga('send', {
        hitType: 'event',
        eventAction: action,
        eventCategory: category,
        eventLabel: correctLabel,
        eventLocation: location,
        eventContext: context,
        ...params,
    });
};

export const universalEvent = (gtag: GTagEvent, value?: string): void => {
    if (!gtag?.action) {
        console.error('Не передан action в universalEvent', gtag);
        return;
    }

    const payload = {
        event: gtag.eventName,
        eventCategory: gtag.category,
        eventAction: gtag.action,
        eventLabel: gtag.label,
        orderid2: value ?? undefined,
        contextId: gtag.contextId ?? undefined,
    };

    (window?.dataLayer ?? []).push(payload);
};

export const Metrics = () => {
    const [loaded, setLoaded] = useState(false);
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const sendVirtualPageView = () => {
        window.dataLayer = window.dataLayer || [];
        window?.dataLayer?.push({
            event: 'VirtualPageView',
            path: window.location.pathname,
            title: document.title,
            pageURL: window.location.href,
        });
    };

    const sendOriginalLocation = () => {
        window.dataLayer = window.dataLayer || [];
        window?.dataLayer?.push({
            originalLocation: `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`,
        });
    };

    const pageview = (url: string, needClean = true): void => {
        const params = MetricsStore.additionalAnalyticsParams;

        window.dataLayer = window?.dataLayer || [];

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const flag = window.dataLayer.filter(item => item.cityId === params?.cityId)?.length > 0;

        if (needClean) {
            window?.dataLayer?.push({
                cityId: null,
                cityName: null,
                pageType: null,
                portalName: null,
                categoryName: null,
            });
        }

        if (params?.cityId && !flag) {
            window?.dataLayer?.push({
                event: 'OWOX',
                eventCategory: 'Non-Interactions',
                eventAction: 'show',
                eventLabel: 'newsitePage',
                cityId: params?.cityId,
                cityName: params?.cityName,
            });

            window?.dataLayer?.push({
                cityId: params?.cityId,
                cityName: params?.cityName,
                eventLabel: 'newsitePage',
                eventCategory: 'Non-Interactions',
                ...MetricsStore?.pageParams,
            });
        }

        sendVirtualPageView();

        const { origin, pathname: page, search } = document.location;

        if (!window?.ga) return;

        window.ga('send', {
            hitType: 'pageview',
            location: `${origin}${page}${search}`,
            page,
            ...params,
        });
    };

    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const url = `${pathname}?${searchParams}`;

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        window.$ = $;
    }, []);

    useEffect(() => {
        if (CityStore?.currentCity?.city) {
            pageview(pathname, false);
        }
    }, [CityStore?.currentCity?.city]);

    useEffect(() => {
        sendOriginalLocation();
    }, []);

    useEffect(() => {
        if (window?.flocktory) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
            window.flocktory.reInit();
        }
    }, [pathname]);

    useEffect(() => {
        const handleRouteChange = () => {
            if (!loaded) return;

            if (window?.ym) {
                window?.ym?.(YA_METRIKA_ID, 'hit', url);
            }

            pageview(url);
        };
        handleRouteChange();
        return () => {
            handleRouteChange();
        };
    }, [pathname, loaded, searchParams]);

    const onLoadGoogleScripts = () => {
        setLoaded(true);
    };

    const strategy =
        pathname.includes('/service/schet_kopilka') || pathname.includes('/service/deposits/moy_vklad')
            ? 'beforeInteractive'
            : 'afterInteractive';

    return (
        <>
            <GoogleScripts strategy={strategy} funcCallback={onLoadGoogleScripts} />
            <MatomoScripts strategy={strategy} />
        </>
    );
};
