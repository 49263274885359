import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import React, { FC, useEffect } from 'react';

import Contacts from '@/components/layout/Header/Contacts';
import HeaderSearch from '@/components/layout/Header/HeaderSearch';
import HeaderSocial from '@/components/layout/Header/HeaderSocial';
import Location from '@/components/layout/Header/Location';
import ModalWrapper from '@/components/modal/ModalWrapper';
import { BtnSwitchTheme } from '@/components/UI/BtnSwitchTheme';
import FeatureWrapper from '@/components/utility/FeatureWrapper';
import { useCity } from '@/context/CityProvider';
import { useTheme } from '@/context/ThemeProvider';
import { useFixHtml } from '@/hooks/useFixHtml';
import { useResize } from '@/hooks/useResize';
import { IGtagHeader, IHeaderSocial } from '@/types/header';
import { TABLET_WIDTH } from '@/utils/constants';

interface IProps {
    handleOpen: () => void;
    handleClose: () => void;
    isOpen: boolean;
    gtag?: IGtagHeader;
    showSearchHandler?: () => void;
    headerSocial?: IHeaderSocial;
}

const DynamicCitySelector = dynamic(() => import('../../../city/CitySelector'));

const HeaderCityThemePart: FC<IProps> = observer(
    ({ handleOpen, handleClose, isOpen, gtag, showSearchHandler, headerSocial }) => {
        const { currentCity } = useCity();
        const { disableThemeSwitchers } = useTheme();
        const title = `Вы здесь: <span>${currentCity?.city || 'Город не определен'}</span>`;
        const { unfix } = useFixHtml();
        const width = useResize();
        const isShow = width >= TABLET_WIDTH;

        useEffect(() => {
            if (!isShow) {
                unfix();
                handleClose();
            }
        }, [width]);

        return (
            <div className="headerTopRight">
                <div className="headerTopRighGrid">
                    {!disableThemeSwitchers && (
                        <FeatureWrapper name="showThemeSwitchers">
                            <div className="headerTopRightItem">
                                <BtnSwitchTheme gtag={gtag?.theme} />
                            </div>
                        </FeatureWrapper>
                    )}

                    <div className="headerTopRightItem">
                        {isShow && (
                            <ModalWrapper
                                isOpen={isOpen}
                                handleClose={handleClose}
                                handleOpen={handleOpen}
                                title={title}
                                trigger={<Location />}
                            >
                                {isOpen && (
                                    <DynamicCitySelector successCallback={handleClose} gtag={gtag?.city?.choose} />
                                )}
                            </ModalWrapper>
                        )}
                    </div>
                    <div className="headerTopRightItem">
                        <Contacts gtag={gtag?.map} />
                    </div>

                    <FeatureWrapper name="enableSearchYandex">
                        <div className="headerTopRightItem">
                            <HeaderSearch showSearchHandler={showSearchHandler} />
                        </div>
                    </FeatureWrapper>

                    {headerSocial && (
                        <FeatureWrapper name="enableTGHeader">
                            <div className="headerTopRightItem">
                                <HeaderSocial
                                    gtag={headerSocial.gtag}
                                    link={headerSocial.link}
                                    image={headerSocial.image}
                                    text={headerSocial.text}
                                />
                            </div>
                        </FeatureWrapper>
                    )}
                </div>
            </div>
        );
    }
);

export default HeaderCityThemePart;
