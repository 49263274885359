import { mergeAttributes, Node, wrappingInputRule } from '@tiptap/core';
import TextStyle from '@tiptap/extension-text-style';

export interface BulletListOptions {
    itemTypeName: string;
    HTMLAttributes: Record<string, any>;
    keepMarks: boolean;
    keepAttributes: boolean;
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        bulletList: {
            toggleBulletList: () => ReturnType;
        };
    }
}

export const inputRegex = /^\s*([-+*])\s$/;

export const BulletList = Node.create<BulletListOptions>({
    name: 'bulletList',

    addOptions() {
        return {
            itemTypeName: 'listItem',
            HTMLAttributes: {
                class: 'indent',
            },
            keepMarks: true,
            keepAttributes: true,
        };
    },

    group: 'block list',

    content() {
        return `${this.options.itemTypeName}+`;
    },

    parseHTML() {
        return [
            {
                tag: 'ul',
                parent: {
                    tag: 'div',
                    class: 'indent',
                },
            },
        ];
    },

    renderHTML({ HTMLAttributes }) {
        return ['ul', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, { class: 'indent' }), 0];
    },

    addCommands() {
        return {
            toggleBulletList:
                () =>
                ({ commands, chain }) => {
                    if (this.options.keepAttributes) {
                        return chain()
                            .toggleList(this.name, this.options.itemTypeName, this.options.keepMarks, {
                                class: 'indent-1',
                            })
                            .run();
                    }
                    return commands.toggleList(this.name, this.options.itemTypeName, this.options.keepMarks, {
                        class: 'indent-1',
                    });
                },
        };
    },

    addKeyboardShortcuts() {
        return {
            'Mod-Shift-8': () => this.editor.commands.toggleBulletList(),
        };
    },

    addInputRules() {
        let inputRule = wrappingInputRule({
            find: inputRegex,
            type: this.type,
        });

        if (this.options.keepMarks || this.options.keepAttributes) {
            inputRule = wrappingInputRule({
                find: inputRegex,
                type: this.type,
                keepMarks: this.options.keepMarks,
                keepAttributes: this.options.keepAttributes,
                getAttributes: () => this.editor.getAttributes(TextStyle.name),
                editor: this.editor,
            });
        }
        return [inputRule];
    },
});
