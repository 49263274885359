import React, { Suspense } from 'react';

import Block from '@/components/layout/Block';
import PreloaderBlocks from '@/components/UI/PreloaderBlocks';
import { IDefaultBlock } from '@/types/tina/block';

interface IBlockList {
    blocks: IDefaultBlock[];
}

const BlockList: React.FC<IBlockList> = ({ blocks }) => (
    <div>
        {blocks?.map(block => (
            // <ErrorBoundary key={block.nanoId} FallbackComponent={ErrorFallback}>
            <Suspense key={block.nanoId} fallback={<PreloaderBlocks />}>
                <Block block={block} />
            </Suspense>
        ))}
    </div>
);

export default BlockList;
