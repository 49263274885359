import Cookies from 'js-cookie';
import { makeAutoObservable } from 'mobx';

import CityStore from '@/domain/city/store';
import { AnalyticsPageParams, IGAParams } from '@/types/gaParams';

class MetricsStore {
    public pageParams: AnalyticsPageParams;

    public pagePrefix = '';

    constructor() {
        makeAutoObservable(this);
    }

    get additionalAnalyticsParams() {
        const urlParams = new URLSearchParams(window.location.search);
        return {
            clientID: Cookies.get('_ga'),
            ym_uid: Cookies.get('_ym_uid'),
            yclid: urlParams.get('yclid'),
            gclid: urlParams.get('gclid'),
            cityId: CityStore.currentCity.cityID,
            cityName: CityStore.currentCity.city,
            pageType: this.pageParams?.pageType || '',
            portalName: this.pageParams?.portalName || '',
            categoryName: this.pageParams?.categoryName || '',
        };
    }

    public hydrate = (options?: IGAParams) => {
        this.pagePrefix = options?.pagePrefix || '';

        this.pageParams = {
            pageType: options?.gtag?.pageType || '',
            portalName: options?.gtag?.portalName || '',
            categoryName: options?.gtag?.categoryName || '',
        };
    };

    public clearPageParams = () => {
        this.pageParams = {};
    };
}

export default new MetricsStore();
