import { IPreloader } from '../IPreloader';
import { ArticlesPreloader } from './ArticlesPreloader';
import { DzenPreloader } from './DzenPreloader';
import { FullPreloader } from './FullPreloader';
import { NewsPreloader } from './NewsPreloader';

interface IPreloaderVariants {
    [key: string]: IPreloader;
}

const preloaderVariants: IPreloaderVariants = {
    news: NewsPreloader,
    dzen: DzenPreloader,
    full: FullPreloader,
    articles: ArticlesPreloader,
};

export const findPrelaoderVarianrtsByType = (type = 'news') => preloaderVariants[type] || preloaderVariants.news;
