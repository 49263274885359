import Script, { ScriptProps } from 'next/script';
import React from 'react';

export const MatomoScripts = ({ strategy }: { strategy: ScriptProps['strategy'] }) => {
    const onLoad = () => {
        window._mtm = window._mtm || [];
        window._mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
    };

    return <Script async src="https://s.mtm.pchtbnk.ru/js/container_fuW4z4uT.js" strategy={strategy} onLoad={onLoad} />;
};
