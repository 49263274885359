import React, { FC } from 'react';

import PromoButton from '@/components/blocks/Promo/PromoButton';
import { IBanner } from '@/types/promo/item';

const PromoButtons: FC<Pick<IBanner, 'buttons'>> = ({ buttons }) => {
    if (!buttons?.length) return null;

    return (
        <div className="promoBtnInnerWrapper">
            {buttons?.map((button, index) => (
                <PromoButton key={index} {...button} pos={index} />
            ))}
        </div>
    );
};

export default PromoButtons;
