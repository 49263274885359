import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import React, { FC } from 'react';

import ModalWrapper from '@/components/modal/ModalWrapper';
import Button from '@/components/UI/Button';
import { useCity } from '@/context/CityProvider';
import { useGeoStore } from '@/context/GeoStoreContext';
import { useFixHtml } from '@/hooks/useFixHtml';
import { IGtagCity } from '@/types/header';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

interface IProps {
    setIsOpen: (state: boolean) => void;
    isOpen: boolean;
    handleClose: () => void;
    handleOpen: () => void;
    gtag?: IGtagCity;
}

const DynamicCitySelector = dynamic(() => import('../CitySelector'));

const ChooseCityBlock: FC<IProps> = observer(({ handleOpen, handleClose, setIsOpen, isOpen, gtag }) => {
    const { currentCity, toggleDialog, setCityCookie } = useCity();
    const { geoPages } = useGeoStore();
    const { unfix } = useFixHtml();

    if (!geoPages.length) {
        return null;
    }

    const CITY_NOT_LOCATED = 'Город не определен';
    const str = currentCity?.city ? `Вы находитесь в городе ${currentCity?.city}?` : CITY_NOT_LOCATED;

    const getTriggerLabel = () => {
        if (currentCity?.city !== '') return 'Другой';

        // @todo зачем это?
        return 'Выбрать другой';
    };

    const handleSelectCity = () => {
        if (gtag?.choose?.action) event(gtag.choose);
        event({
            action: 'click',
            category: 'interactions',
            label: 'correct_city',
        });
        toggleDialog(false);
        setCityCookie(currentCity);
        document.location.reload();
    };

    const Trigger = (
        <Button
            variant="btnBlueBorder"
            type="button"
            label={getTriggerLabel()}
            animation={false}
            size="xsmall"
            onClick={() => {
                event({
                    action: 'click',
                    category: 'interactions',
                    label: 'incorrect_city',
                });
            }}
        />
    );

    return (
        <div className={cn.cityWrapper}>
            <div className={clsx(cn.row, getTriggerLabel() === 'Другой' && cn.other)}>
                <div className={cn.col}>
                    <div className={cn.text}>{str}</div>
                </div>
                <div className={cn.col}>
                    {currentCity?.city !== '' && (
                        <Button
                            variant="btnBlue"
                            type="button"
                            label="Да"
                            animation={false}
                            size="xsmall"
                            onClick={handleSelectCity}
                        />
                    )}

                    <ModalWrapper
                        isOpen={isOpen}
                        handleClose={handleClose}
                        handleOpen={handleOpen}
                        title={`Вы здесь: ${currentCity?.city || 'Город не определен'}`}
                        trigger={Trigger}
                    >
                        {isOpen && (
                            <DynamicCitySelector
                                successCallback={() => {
                                    setIsOpen(false);
                                    unfix();
                                }}
                                gtag={gtag?.choose}
                            />
                        )}
                    </ModalWrapper>
                </div>
            </div>
        </div>
    );
});

export default ChooseCityBlock;
