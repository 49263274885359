import React, { createContext, ReactNode, useContext, useMemo } from 'react';

import GeoStore from '@/domain/core/GeoStore';

const GeoContext = createContext<GeoStore | null>(null);

export const GeoProvider = ({ children }: { children: ReactNode }) => {
    const value = useMemo(() => new GeoStore(), []);
    return <GeoContext.Provider value={value}>{children}</GeoContext.Provider>;
};

export const useGeoStore = () => useContext(GeoContext);
