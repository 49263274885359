import clsx from 'clsx';
import React, { FC } from 'react';

import { BasicLink } from '@/components/UI/BasicLink';
import { IFooter } from '@/types/footer';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

const FooterUsers: FC<IFooter & { hideOnMobile?: boolean }> = ({ users, hideOnMobile }) => (
    <div className={clsx(cn.footerUsers, hideOnMobile && cn.hideOnMobile)}>
        <div className={cn.footerUsersWrap}>
            <div className={cn.footerUsersGrid}>
                {users?.map(({ link, text, gtag }, index) => (
                    <div className={cn.footerUsersItem} key={`${index}-${link}`}>
                        <BasicLink href={link} customClass={clsx(cn.footerUsersLink)} onClick={() => event(gtag)}>
                            {text}
                        </BasicLink>
                    </div>
                ))}
            </div>
        </div>
    </div>
);

export default FooterUsers;
