import { makeAutoObservable } from 'mobx';

import Feature from '@/domain/feature/Feature';
import { IPageParams } from '@/types/pageParams';
import { TTheme } from '@/types/theme';

export interface IThemeStore {
    theme: TTheme;
    get isDarkTheme(): boolean;
    setInitialTheme: (darkTheme: IPageParams['darkTheme'], asPath: string) => void;
    setTheme: (theme: TTheme) => void;
}

export class ThemeStore implements IThemeStore {
    public theme: TTheme = 'light';

    public disableThemeSwitchers = false;

    private darkThemeRoutes = ['/service/world-elite', '/service/debetcards/mir-supreme'];

    constructor() {
        makeAutoObservable(this);
    }

    public get isDarkTheme() {
        return this.theme === 'dark';
    }

    public setTheme = (theme: TTheme) => {
        this.theme = theme;
        window.localStorage?.setItem('POCHTA_BANK_THEME', theme);
    };

    public setInitialTheme = (darkTheme: IPageParams['darkTheme'], asPath: string) => {
        if (typeof window === 'undefined') {
            this.theme = 'light';
        }

        const { darkThemeEnd = '', darkThemeStart = '', isDarkThemeEnabled = false } = darkTheme || {};

        // Тема по времени
        if (isDarkThemeEnabled && darkThemeEnd && darkThemeStart) {
            const isInRange = this.checkTimeInRange(darkThemeStart, darkThemeEnd);

            this.theme = isInRange ? 'dark' : 'light';

            return;
        }

        // Исключения для роутов
        if (this.darkThemeRoutes.includes(asPath)) {
            this.theme = 'dark';

            return;
        }

        // Тема по фичефлагу
        if (Feature.isFeatureEnabled('onInitialTheme')) {
            this.theme = this.getInitialTheme();

            return;
        }

        this.theme = 'light';
    };

    private getInitialTheme = (): TTheme => {
        if (typeof window === 'undefined') return null;
        if (window.localStorage?.getItem('POCHTA_BANK_THEME'))
            return window.localStorage?.getItem('POCHTA_BANK_THEME') as TTheme;
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) return 'dark';

        return 'light';
    };

    private checkTimeInRange = (startTime: string, endTime: string) => {
        const now = new Date();
        const start = new Date();
        const end = new Date();

        const [startHour, startMinute = '0'] = startTime.split(':');
        const [endHour, endMinute = '0'] = endTime.split(':');

        start.setHours(+startHour, +startMinute);
        end.setHours(+endHour, +endMinute);

        return now >= start && now <= end;
    };
}
