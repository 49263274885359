import { getScrollbarWidth } from '@/utils/getScrollbarWidth';

export const useFixHtml = () => {
    const fixDocument = () => {
        const scrollbarWidth = getScrollbarWidth();

        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = `${scrollbarWidth}px`;
    };
    const unfixDocument = () => {
        document.body.style.overflow = 'visible';
        document.body.style.paddingRight = '0';
    };

    return {
        fix: fixDocument,
        unfix: unfixDocument,
    };
};
