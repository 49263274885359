export const getImage = (width: string | number, height: string | number) => {
    if (typeof document === 'undefined' || !width || !height) return null;

    const mockText = `${width}x${height}`;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = +width;
    canvas.height = +height;

    if (!ctx) return null;

    ctx.fillStyle = '#F2F2F7';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.textAlign = 'center';
    let fontsize = 300;

    do {
        fontsize -= 1;
        ctx.font = `${fontsize}px Roboto, sans-serif`;
    } while (ctx.measureText(mockText).width > canvas.width / 2);

    ctx.fillStyle = '#001E45';
    ctx.fillText(mockText, +width / 2, +height / 2 + fontsize / 2);

    return canvas.toDataURL('image/png');
};
