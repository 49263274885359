import clsx from 'clsx';
import React from 'react';

import cn from './style.module.sass';

interface IProps {
    direction: 'up' | 'down' | 'right' | 'left';
    color?: 'gray' | 'white';
}

const IconArrow: React.FC<IProps> = ({ direction, color = 'gray' }) => (
    <svg
        className={clsx(cn.svg, cn[direction], cn[color])}
        width="8"
        height="12"
        viewBox="0 0 8 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M1 1L6 6L1 11" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
);

export default IconArrow;
