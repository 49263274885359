import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import Arrow from '@/components/PanelOldVersion/Arrow';
import Feature from '@/domain/feature/Feature';
import { IGtagHeader } from '@/types/header';
import { Cookie } from '@/utils/cookie';
import { event } from '@/components/shared/utilities/analytics/metrics';

interface IProps {
    gtag?: IGtagHeader;
}

const PanelOldVersion: React.FC<IProps> = observer(({ gtag }) => {
    const [isActive, setIsActive] = useState(false);

    const onClick = () => {
        if (gtag?.oldSite?.action) event(gtag?.oldSite);
        Cookie.setOldSiteCookie();
        document.location.reload();
    };

    const initializePanel = () => {
        const isFeatureEnabled = Feature.isFeatureEnabled('showOldCitePanel');
        setIsActive(isFeatureEnabled);
    };

    useEffect(() => {
        initializePanel();
    }, []);

    if (!isActive) return null;

    return (
        <div className="panelOldVersion">
            <div role="presentation" className="panelGrid" onClick={onClick}>
                <div className="panelOldVersionText">Перейти на старый сайт</div>
                <div className="panelOldVersionArrow">
                    <Arrow />
                </div>
            </div>
        </div>
    );
});

export default PanelOldVersion;
