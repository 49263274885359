import clsx from 'clsx';
import React, { FC } from 'react';

import cn from './style.module.sass';

interface IArrow {
    swiperRef: React.RefObject<{ swiper: { slidePrev: () => void; slideNext: () => void } }>;
    isModal?: boolean;
    disabled?: boolean;
    isModalArrow?: boolean;
}

export const PrevArrow: FC<IArrow> = ({ swiperRef, disabled, isModal = false, isModalArrow }) => {
    const onClick = () => {
        swiperRef?.current?.swiper?.slidePrev();
    };

    return (
        <button
            type="button"
            aria-label="Left"
            onClick={onClick}
            id={isModal && 'prevArrow'}
            className={clsx(
                cn.customArrow,
                cn.customArrowLeft,
                disabled && cn.disabled,
                isModalArrow && cn.modalArrowLeft
            )}
            disabled={disabled}
        />
    );
};
export const NextArrow: FC<IArrow> = ({ swiperRef, disabled, isModal = false, isModalArrow }) => {
    const onClick = () => {
        swiperRef?.current?.swiper?.slideNext();
    };

    return (
        <button
            type="button"
            aria-label="Right"
            onClick={onClick}
            id={isModal && 'nextArrow'}
            className={clsx(
                cn.customArrow,
                cn.customArrowRight,
                disabled && cn.disabled,
                isModalArrow && cn.modalArrowRight
            )}
            disabled={disabled}
        />
    );
};
