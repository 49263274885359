import clsx from 'clsx';
import React, { FC } from 'react';

import Button from '@/components/UI/Button';
import { ScrollLink } from '@/components/UI/ScrollLink';
import { useResize } from '@/hooks/useResize';
import { IButtonPromo } from '@/types/promo/item';
import { TABLET_SMALL_WIDTH } from '@/utils/constants';
import { event } from '@/components/shared/utilities/analytics/metrics';

interface IProps extends IButtonPromo {
    pos?: number;
    animation?: boolean;
}

const PromoButton: FC<IProps> = ({
    pos,
    animation,
    gtag,
    link,
    type,
    text,
    color,
    isSetMobileAnimate,
    isSetDesktopAnimate,
}) => {
    const width = useResize();

    const isMobile = width < TABLET_SMALL_WIDTH;

    const scrollOffset = isMobile ? 60 : 90;

    const onButtonClick = () => {
        event(gtag);
    };

    if (!text) return null;

    if (color === 'supreme') {
        return (
            <div key={text} className={clsx('promoBtnItem', pos !== 0 && 'promoBtnItemMobailNo')}>
                <Button
                    variant="btnWhiteBlack"
                    type="link"
                    href={link}
                    label={text}
                    customClass={clsx(
                        'full',
                        'promoBtnCustom',
                        isSetMobileAnimate && 'promoBtnMobileAnimate',
                        isSetDesktopAnimate && 'promoBtnDesktopAnimate'
                    )}
                    animation={false}
                    onClick={onButtonClick}
                />
            </div>
        );
    }

    if (type === 'link') {
        return (
            <div key={text} className={clsx('promoBtnItem', pos !== 0 && 'promoBtnItemMobailNo')}>
                <a className="promoBtnLink" href={link}>
                    {text}
                </a>
            </div>
        );
    }

    if (type === 'anchor-link') {
        return (
            <div key={text} className={clsx('promoBtnItem', pos !== 0 && 'promoBtnItemMobailNo')}>
                <ScrollLink to={link} offset={-scrollOffset} activeClass="active" className="">
                    <span className="promoBtnLink">{text}</span>
                </ScrollLink>
            </div>
        );
    }

    if (type === 'anchor' && color === 'default') {
        return (
            <div key={text} className={clsx('promoBtnItem', pos !== 0 && 'promoBtnItemMobailNo')}>
                <ScrollLink to={link} offset={-scrollOffset} activeClass="active" className="">
                    <Button
                        variant="btnBlue"
                        type="button"
                        label={text}
                        customClass={clsx(
                            'full',
                            'promoBtnCustom',
                            isSetMobileAnimate && 'promoBtnMobileAnimate',
                            isSetDesktopAnimate && 'promoBtnDesktopAnimate'
                        )}
                        animation={false}
                    />
                </ScrollLink>
            </div>
        );
    }

    if (type === 'anchor' && color === 'white') {
        return (
            <div key={text} className={clsx('promoBtnItem', pos !== 0 && 'promoBtnItemMobailNo')}>
                <ScrollLink to={link} offset={-scrollOffset} activeClass="active" className="">
                    <Button
                        variant="btnWhiteBorder"
                        type="button"
                        label={text}
                        customClass={clsx(
                            'full',
                            'promoBtnCustom',
                            isSetMobileAnimate && 'promoBtnMobileAnimate',
                            isSetDesktopAnimate && 'promoBtnDesktopAnimate'
                        )}
                        animation={false}
                    />
                </ScrollLink>
            </div>
        );
    }

    if (type === 'anchor' && color === 'gray') {
        return (
            <div key={text} className={clsx('promoBtnItem', pos !== 0 && 'promoBtnItemMobailNo')}>
                <ScrollLink to={link} offset={-scrollOffset} activeClass="active" className="">
                    <Button
                        variant="btnWhite"
                        type="button"
                        label={text}
                        customClass={clsx(
                            'full',
                            'promoBtnCustom',
                            isSetMobileAnimate && 'promoBtnMobileAnimate',
                            isSetDesktopAnimate && 'promoBtnDesktopAnimate'
                        )}
                        animation={false}
                    />
                </ScrollLink>
            </div>
        );
    }

    if (type === 'anchor' && color === 'blue') {
        return (
            <div key={text} className={clsx('promoBtnItem', pos !== 0 && 'promoBtnItemMobailNo')}>
                <ScrollLink to={link} offset={-scrollOffset} activeClass="active" className="">
                    <Button
                        variant="btnColor"
                        animation={animation}
                        type="button"
                        label={text}
                        colorBtn="blue"
                        size="large"
                        onClick={onButtonClick}
                        customClass={clsx(
                            'full',
                            'promoBtnCustom',
                            isSetMobileAnimate && 'promoBtnMobileAnimate',
                            isSetDesktopAnimate && 'promoBtnDesktopAnimate'
                        )}
                    />
                </ScrollLink>
            </div>
        );
    }

    if (type === 'button' && color === 'white') {
        return (
            <div key={text} className={clsx('promoBtnItem', pos !== 0 && 'promoBtnItemMobailNo')}>
                <Button
                    variant="btnWhiteBorder"
                    type="link"
                    href={link}
                    label={text}
                    customClass="full"
                    animation={false}
                    onClick={onButtonClick}
                />
            </div>
        );
    }

    if (type === 'button' && color === 'gray') {
        return (
            <div key={text} className={clsx('promoBtnItem', pos !== 0 && 'promoBtnItemMobailNo')}>
                <Button
                    variant="btnWhite"
                    type="link"
                    href={link}
                    label={text}
                    customClass={clsx(
                        'full',
                        'promoBtnCustom',
                        isSetMobileAnimate && 'promoBtnMobileAnimate',
                        isSetDesktopAnimate && 'promoBtnDesktopAnimate'
                    )}
                    animation={false}
                    onClick={onButtonClick}
                />
            </div>
        );
    }

    if (type === 'button' && color === 'blue') {
        return (
            <div key={text} className={clsx('promoBtnItem', pos !== 0 && 'promoBtnItemMobailNo')}>
                <Button
                    variant="btnColor"
                    type="link"
                    href={link}
                    label={text}
                    colorBtn="blue"
                    size="large"
                    customClass={clsx(
                        'full',
                        'promoBtnCustom',
                        isSetMobileAnimate && 'promoBtnMobileAnimate',
                        isSetDesktopAnimate && 'promoBtnDesktopAnimate'
                    )}
                    onClick={onButtonClick}
                    animation={animation}
                />
            </div>
        );
    }

    return (
        <div key={text} className={clsx('promoBtnItem', pos !== 0 && 'promoBtnItemMobailNo')}>
            <Button
                variant="btnBlue"
                type="link"
                href={link}
                label={text}
                customClass={clsx(
                    'full',
                    'promoBtnCustom',
                    isSetMobileAnimate && 'promoBtnMobileAnimate',
                    isSetDesktopAnimate && 'promoBtnDesktopAnimate'
                )}
                animation={false}
                onClick={onButtonClick}
            />
        </div>
    );
};

export default PromoButton;
