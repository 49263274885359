import React from 'react';

import cn from './style.module.sass';

function Moon() {
    return (
        <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 0.615103C8.05153 1.69859 5.95977 4.42411 5.95977 7.61506C5.95977 10.6985 7.91296 13.3473 10.7049 14.5C9.82605 14.8229 8.87102 15 7.87259 15C3.52468 15 0 11.6421 0 7.5C0 3.35786 3.52468 0 7.87259 0C8.98387 0 10.0414 0.219358 11 0.615103Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 0.615103C10.5149 0.414841 10.0045 0.259748 9.47457 0.155391C8.95732 0.0535241 8.42152 0 7.87259 0C3.52468 0 0 3.35786 0 7.5C0 11.6421 3.52468 15 7.87259 15C8.31133 15 8.7417 14.9658 9.1609 14.9001C9.69566 14.8162 10.2123 14.681 10.7049 14.5C10.2394 14.3078 9.79725 14.074 9.38354 13.8036C7.31594 12.452 5.95977 10.1844 5.95977 7.61506C5.95977 4.9368 7.43336 2.58643 9.65026 1.25965C10.0746 1.00566 10.5263 0.789189 11 0.615103ZM8.11875 1.03997C8.03707 1.0372 7.95501 1.03581 7.87259 1.03581C4.12516 1.03581 1.08726 3.92992 1.08726 7.5C1.08726 11.061 4.10967 13.9494 7.84392 13.9641C6.02039 12.4022 4.87251 10.1369 4.87251 7.61506C4.87251 4.9688 6.13629 2.6051 8.11875 1.03997Z"
                className={cn.svgFill}
            />
        </svg>
    );
}

export default Moon;
