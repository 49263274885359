import Script, { ScriptProps } from 'next/script';
import React from 'react';

import MetricsStore from '@/domain/metrics/Store';

export const YA_METRIKA_ID = 19473172;

export type GoogleScriptsProps = {
    strategy: ScriptProps['strategy'];
    funcCallback?: () => void;
};

export const GoogleScripts = ({ strategy, funcCallback }: GoogleScriptsProps) => {
    const onLoad = () => {
        window.dataLayer = window.dataLayer || [];

        window.dataLayer.push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
            ...MetricsStore.pageParams,
        });

        window.addEventListener('load', () => {
            funcCallback();
            if (window?.ym) {
                window?.ym(YA_METRIKA_ID, 'init', {
                    clickmap: true,
                    webvisor: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                });
            }
        });
    };

    return (
        <Script async src="https://www.googletagmanager.com/gtm.js?id=GTM-PNW3V6" strategy={strategy} onLoad={onLoad} />
    );
};
