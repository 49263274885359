import clsx from 'clsx';
import React, { useRef, useState } from 'react';

import useOnClickOutside from '@/hooks/useOnClickOutside';
import { IHiddenMenu } from '@/types/menu/menu';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

export interface IButtonCollapsed {
    label: string;
    hiddenHeaderMenu: IHiddenMenu[];
    gtag?: GTagEvent;
    customClass?: string;
    isFullHeight?: boolean;
}

export const ButtonWithList: React.FC<IButtonCollapsed> = ({
    label,
    hiddenHeaderMenu,
    gtag,
    isFullHeight,
    customClass,
}) => {
    const className = clsx(cn.button);
    const [show, setShow] = useState<boolean>(false);

    const ref = useRef<HTMLDivElement>();

    const toggleList = () => {
        if (gtag?.action) event(gtag);
        setShow(prev => !prev);
    };

    const closeList = () => {
        setShow(false);
    };

    useOnClickOutside(ref, () => closeList());

    return (
        <div className={clsx(cn.wrapper, customClass)} onClick={toggleList} role="presentation" ref={ref}>
            <div className={clsx(className, isFullHeight && 'buttonFullHeight')}>{label}</div>

            {show && (
                <div className={cn.links}>
                    {hiddenHeaderMenu?.map(item => (
                        <a
                            key={item.label}
                            href={item.href}
                            className={cn.link}
                            onClick={() => {
                                if (item.gtag) event(item.gtag);
                            }}
                        >
                            {item.label}
                        </a>
                    ))}
                </div>
            )}
        </div>
    );
};
