import { AxiosError } from 'axios';
import React, { useLayoutEffect, useState } from 'react';

import SuccessGenerateCodeNotification from '@/components/blocks/forms/ConfirmEmailForm/notification';
import Button from '@/components/UI/Button';
import { Cookie } from '@/utils/cookie';
import { nextApiRequest } from '@/utils/request';

import cn from './style.module.sass';

interface IGenerateButton {
    userKey: string;
    handleFail: (val) => void;
    handleSuccess: (val) => void;
    setText: (val) => void;
}

const GenerateButton: React.FC<IGenerateButton> = ({ userKey, handleSuccess, handleFail, setText }) => {
    const [isCodeGenerate, setIsCodeGenerate] = useState<boolean>(false);

    const [isDisabledButton, setIsDisabledButton] = useState<boolean>(false);

    const [lockTime, setLockTime] = useState<number>(299000);

    const timerHandler = () => {
        setIsDisabledButton(false);
        setIsCodeGenerate(false);
    };

    const generatePasswordHandler = async () => {
        try {
            await nextApiRequest.post(`/email-validation/generate-code/${userKey}`);

            setIsDisabledButton(true);
            setIsCodeGenerate(true);

            document.cookie = `codeGenerate = ${Date.now()}; path=/; max-age=300`;
        } catch (error) {
            handleFail(true);

            const err = error as AxiosError<{ userMessage: string }>;
            setText(err?.response?.data?.userMessage || 'Произошла ошибка при отправке формы.');
            handleSuccess(true);
        }
    };

    useLayoutEffect(() => {
        if (Cookie.getCookie('codeGenerate')) {
            const time = Date.now() - +Cookie.getCookie('codeGenerate');

            setLockTime(time);
            setIsDisabledButton(true);
            setIsCodeGenerate(true);
        }
    }, []);

    return (
        <>
            <Button
                variant="btnBlue"
                buttonType="button"
                type="button"
                label="Сгенерировать код"
                customClass={cn.button}
                animation={false}
                disable={isDisabledButton}
                onClick={generatePasswordHandler}
            />
            {isCodeGenerate && <SuccessGenerateCodeNotification time={lockTime} handler={timerHandler} />}
        </>
    );
};
export default GenerateButton;
