import { NodeViewContent, NodeViewWrapper } from '@tiptap/react';
import React from 'react';

import QuestionMark from '@/components/UI/QuestionMark';

type QuestionMarkProps = {
    node: {
        attrs: {
            text: string;
            textTooltip: string;
            questionMarkColor: string;
        };
    };
};
export default ({ node }: QuestionMarkProps) => {
    const { textTooltip, text, questionMarkColor } = node.attrs;

    return (
        <NodeViewWrapper as="span" className="question-mark">
            <NodeViewContent as="span" className="content">
                {text}
            </NodeViewContent>
            <QuestionMark textTooltip={textTooltip} color={questionMarkColor} />
        </NodeViewWrapper>
    );
};
