import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import cn from './style.module.sass';

interface IProps {
    showOverlay: boolean;
    closeOverlay: () => void;
    className?: string;
}

const BackOverlay: FC<IProps> = ({ showOverlay, closeOverlay, className }) => {
    const [node, setNode] = useState<Element>(null);

    useEffect(() => {
        setNode(document.querySelector('div#wrapper'));
    }, []);
    return (
        <div>
            {node &&
                ReactDOM.createPortal(
                    <CSSTransition in={showOverlay} timeout={300} classNames={cn} unmountOnExit>
                        <div className={clsx(cn.overlay, className)} onClick={closeOverlay} role="presentation" />
                    </CSSTransition>,
                    node
                )}
        </div>
    );
};

export default BackOverlay;
