import clsx from 'clsx';
import React, { FC } from 'react';

import CustomImage from '@/components/CustomImage';

export type AnimatedPromoCircleProps = {
    color: 'red' | 'blue' | 'purple';
    isActive: boolean;
};

const config = {
    red: {
        cn: 'animatedPromoRedCircle',
        img: '/img-next/png/animatedPromo/elipse.png',
    },
    blue: {
        cn: 'animatedPromoBlueCircle',
        img: '/img-next/png/animatedPromo/circle.png',
    },
    purple: {
        cn: 'animatedPromoPurpleCircle',
        img: '/img-next/png/animatedPromo/purple.png',
    },
};

const AnimatedPromoCircle: FC<AnimatedPromoCircleProps> = ({ color, isActive }) => (
    <div className={clsx('animatedPromoCircle', config[color].cn)} style={{ display: isActive ? 'block' : 'none' }}>
        <CustomImage src={config[color].img} alt="Анимация" fill mockWidth={500} mockHeight={500} />
    </div>
);

export default AnimatedPromoCircle;
