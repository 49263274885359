import HCaptcha from '@hcaptcha/react-hcaptcha';
import * as Sentry from '@sentry/nextjs';
import { AxiosError } from 'axios';
import { useParams, useRouter } from 'next/navigation';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';

import GenerateButton from '@/components/blocks/forms/ConfirmEmailForm/item';
import SubscriptionModal from '@/components/SubscriptionModal';
import Button from '@/components/UI/Button';
import Input from '@/components/UI/Input';
import Preloader from '@/components/UI/Preloader';
import { InnerFormValues, useValidationSchema } from '@/hooks/useValidationSchema';
import { getFormErrors } from '@/utils/forms/getFormErrors';
import { nextApiRequest } from '@/utils/request';

import cn from './style.module.sass';

type TFormResult = Record<string, string>;

const ConfirmEmailForm: React.FC = () => {
    const [isDisabledButton, setIsDisabledButton] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isFail, setIsFail] = useState(false);
    const [modalText, setModalText] = useState('');

    const [dataForm, setDataForm] = useState<TFormResult>(null);

    const [token, setToken] = useState<string>(null);
    const captchaRef = useRef<HCaptcha>(null);

    const [isLoading, setIsLoading] = useState(false);

    const formResolver = useValidationSchema([{ name: 'code', type: 'code', required: true }]);

    const {
        register,
        handleSubmit,
        formState: { errors, dirtyFields },
    } = useForm<InnerFormValues>({ resolver: formResolver, mode: 'onChange' });

    const [isErrorCode, errorCodeText] = getFormErrors(errors, 'code');

    const params = useParams();
    const router = useRouter();

    const { key } = params;
    const userKey = key as string;

    const onSubmit = async (finalData: TFormResult) => {
        setIsDisabledButton(true);

        try {
            await nextApiRequest.post(`/email-validation/check-code/${userKey}`, {
                dataCode: finalData.code,
            });

            setModalText('Почта успешно подтверждена!');
        } catch (error) {
            const err = error as AxiosError<{ userMessage: string }>;

            setIsFail(true);
            setModalText(err?.response?.data?.userMessage || 'Произошла ошибка при отправке формы.');
            console.error(error);

            Sentry.captureMessage(
                `Произошла ошибка при отправке формы emailValidation -- ${err?.response?.data?.userMessage}`,
                'error'
            );
        }

        setIsModalOpen(true);
        setIsDisabledButton(false);
    };

    const captchaExecute = (data: TFormResult) => {
        setDataForm(data);

        if (token === null) {
            captchaRef.current.execute();
        } else {
            onSubmit(data).catch(e => console.error(e));
        }
    };

    const onExpire = () => {
        setToken(null);
    };

    const onError = async (error: string) => {
        try {
            await nextApiRequest.post(`/email-validation/send-error-log/${userKey}`, {
                action: 'Неверный ввод captcha',
                info: '26052022',
            });
        } catch (e) {
            console.error(e);
        }
    };

    const closeModalHandler = () => {
        setIsModalOpen(false);

        setIsLoading(true);
        router.push('/');
        setIsLoading(false);
    };

    useEffect(() => {
        if (token && dataForm) {
            onSubmit(dataForm).catch(e => console.error(e));
        }
    }, [token]);

    useEffect(() => {
        if (dirtyFields.code && !isErrorCode && token) {
            setIsDisabledButton(false);
        } else {
            setIsDisabledButton(true);
        }
    }, [token, dirtyFields, isErrorCode]);

    if (isLoading) return <Preloader />;

    return (
        <>
            <div className={cn.innerWrapper}>
                <form className={cn.form} onSubmit={handleSubmit(captchaExecute)} noValidate>
                    <div className={cn.title}>Подтверждение почты</div>
                    <div className={cn.description}>
                        Для подтверждения адреса Вашей электронной почты Вам нужно сделать следующее:
                    </div>
                    <div className={cn.row}>
                        <div className={cn.description}>
                            1) Нажмите на&nbsp;кнопку &laquo;Сгенерировать код&raquo;. Код будет выслан посредством PUSH
                            или СМС на&nbsp;Ваш мобильный номер.
                        </div>
                        <GenerateButton
                            userKey={userKey}
                            handleFail={setIsFail}
                            handleSuccess={setIsModalOpen}
                            setText={setModalText}
                        />
                    </div>
                    <div className={cn.row}>
                        <div className={cn.description}>2) Полученный код введите в&nbsp;поле ниже:</div>
                        <div className={cn.input}>
                            <Input
                                type="text"
                                label="Введите код"
                                shouldColored
                                isArrowed={dirtyFields.code && !isErrorCode}
                                customClass={cn.isFull}
                                isError={isErrorCode}
                                errorText={errorCodeText}
                                {...register('code')}
                            />
                        </div>
                    </div>

                    <div className={cn.row}>
                        <div className={cn.description}>
                            3) Нажмите на&nbsp;&laquo;Я&nbsp;человек&raquo; и&nbsp;выберите нужные варианты
                        </div>
                        <div className={cn.input}>
                            <HCaptcha
                                sitekey="d964803c-764e-4976-9563-53cc8cc4e631"
                                onVerify={setToken}
                                onError={onError}
                                onExpire={onExpire}
                                ref={captchaRef}
                            />
                        </div>
                    </div>

                    <Button
                        variant="btnBlue"
                        buttonType="submit"
                        type="button"
                        label="Отправить"
                        disable={isDisabledButton}
                        customClass={cn.button}
                        animation={false}
                    />
                </form>
            </div>
            <SubscriptionModal isOpen={isModalOpen} isFail={isFail} handleClose={closeModalHandler} title={modalText} />
        </>
    );
};

export default ConfirmEmailForm;
