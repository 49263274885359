/* eslint-disable */
import React, { FC, useState } from 'react';

import { useIsomorphicEffect } from '@/hooks/useIsomorphicEffect';
import { FRONT_ROOT_URL, protocol } from '@/utils/constants';

import cn from './style.module.sass';

interface IProps {
    flag?: boolean;
}

const YandexSearchForm: FC<IProps> = ({ flag = false }) => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [isLoadedScript, setIsLoadedScript] = useState<boolean>(false);
    const [isRefresh, setIsRefresh] = useState<boolean>(false);

    const initHandler = (w: any, d: any, c: any) => {
        const s = d.createElement('script');
        const h = d.getElementsByTagName('script')[0];
        const e = d.documentElement;
        if (` ${e.className} `.indexOf(' ya-page_js_yes ') === -1) {
            e.className += ' ya-page_js_yes';
        }
        s.type = 'text/javascript';
        s.async = true;
        s.charset = 'utf-8';
        s.preconnect = true;
        s.src = `${d.location.protocol === 'https:' ? 'https:' : 'http:'}//site.yandex.net/v2.0/js/all.js`;

        h.parentNode.insertBefore(s, h);

        s.onload = function () {
            setIsLoadedScript(true);
        };
    };

    useIsomorphicEffect(() => {
        window?.Ya?.Site?.Form?.init();
        setIsLoaded(true);
    }, [isLoadedScript]);

    useIsomorphicEffect(() => {
        if (flag && !isRefresh) {
            setIsRefresh(true);
            setTimeout(() => {
                initHandler(window, document, 'yandex_site_callbacks');
            }, 100);
        }
    }, [flag]);

    if (!isLoaded) return null;

    return (
        <div
            id="search"
            className={cn.search}
            dangerouslySetInnerHTML={{
                __html: `<div class="ya-site-form ya-site-form_inited_no" data-bem="{&quot;action&quot;:&quot;${protocol}${process.env.FRONT_ROOT_URL}/search&quot;,&quot;arrow&quot;:false,&quot;bg&quot;:&quot;transparent&quot;,&quot;fontsize&quot;:12,&quot;fg&quot;:&quot;#000000&quot;,&quot;language&quot;:&quot;ru&quot;,&quot;logo&quot;:&quot;rb&quot;,&quot;publicname&quot;:&quot;Поиск по сайту pochtabank.ru&quot;,&quot;suggest&quot;:true,&quot;target&quot;:&quot;_self&quot;,&quot;tld&quot;:&quot;ru&quot;,&quot;type&quot;:2,&quot;usebigdictionary&quot;:true,&quot;searchid&quot;:2749941,&quot;input_fg&quot;:&quot;#000000&quot;,&quot;input_bg&quot;:&quot;#ffffff&quot;,&quot;input_fontStyle&quot;:&quot;normal&quot;,&quot;input_fontWeight&quot;:&quot;normal&quot;,&quot;input_placeholder&quot;:&quot;Введите запрос для поиска&quot;,&quot;input_placeholderColor&quot;:&quot;#8B8B8B&quot;,&quot;input_borderColor&quot;:&quot;#ffffff&quot;}">

                        <form action="https://yandex.ru/search/site/" method="get" target="_self" accept-charset="utf-8">
                        <input type="hidden" name="searchid" value="2749941"/>
                        <input type="hidden" name="l10n" value="ru"/>
                        <input type="hidden" name="reqenc" value=""/>
                        <input type="search" name="text" value=""/>
                        <input type="submit" value="Найти"/>
                        </form>
                        </div>

                        <style type="text/css">.ya-page_js_yes .ya-site-form_inited_no { display: none; }</style>`,
            }}
        />
    );
};

export default YandexSearchForm;
