import { useRouter } from 'next/navigation';
import React, { FC, useMemo, useRef, useState } from 'react';
import SwiperCore, { EffectFade } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import AnimatedPromoDotList from '@/components/blocks/animatedPromo/AnimatedPromoDotList';
import AnimatedPromoSlide from '@/components/blocks/animatedPromo/AnimatedPromoSlide';
import CustomSwiper from '@/components/CustomSwiper';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';
import { useResize } from '@/hooks/useResize';
import { IAnimatedPromoItem, ISwiperRef } from '@/types/animatedPromo/item';
import { MOBILE_WIDTH } from '@/utils/constants';
import { event, GTagEvent } from '@/components/shared/utilities/analytics/metrics';

export interface IAnimatedPromo {
    /**
     * Необходимый параметр для обозначения блока в списке.
     */
    _template: 'animatedPromo';
    /**
     * Список кнопок с анонсом. Пока есть непонятки с синей плашкой, так как она располагаться может в любом месте.
     * Сделать markdown?
     */
    list: IAnimatedPromoItem[];
}

SwiperCore.use([EffectFade]);

const AnimatedPromo: FC<IAnimatedPromo> = ({ list }) => {
    const router = useRouter();
    const width = useResize();

    const [active, setActive] = useState<number>(0);

    const [observers, setObservers] = useState([]);

    const swiperRef = useRef<ISwiperRef>(null);

    const isEnabled = width <= MOBILE_WIDTH;

    const handleClick = (index: number) => {
        if (index !== active && !swiperRef.current.swiper.animating) {
            setActive(index);
            swiperRef.current.swiper.slideTo(index + 1, 300);
        }
    };

    const settings = {
        loop: true,
        effect: 'fade',
        slidesPerView: 1,
        fadeEffect: { crossFade: true },
        autoplay: false,
        allowTouchMove: false,
        onActiveIndexChange: (swiper: { activeIndex: number }) => {
            if (swiper.activeIndex === 0) {
                return setActive(list.length - 1);
            }

            if (swiper.activeIndex === list.length + 1) {
                return setActive(0);
            }

            return setActive(swiper.activeIndex - 1);
        },
    };

    const handleTitleClick = async (link: string, gtag: GTagEvent) => {
        if (isEnabled) {
            if (gtag?.action) event(gtag);
            await Promise.resolve(() => router.push(link));
        }
    };

    const preloaderBackground = useMemo(() => {
        if (list?.[0]?.banner?.animationType === 'blue')
            return 'linear-gradient(67.26deg, #EDF6FE 21.04%, #F8EFFF 90.51%)';
        if (list?.[0]?.banner?.animationType === 'red')
            return 'linear-gradient(79.88deg, #727BC8 -12.53%, #577CCE 20.05%, #6158A0 50.08%, #B4547E 87.2%, #61478E 128.05%)';
        return null;
    }, []);

    if (list?.length <= 0 || !list) return null;

    return (
        <div className="animatedPromoHead headPromo">
            <div className="animatedPromoSlider">
                <div className="animatedPromoPreloaderBg" style={{ background: preloaderBackground }}>
                    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                    {/* @ts-ignore */}
                    <CustomSwiper {...settings} ref={swiperRef}>
                        {list?.slice(0, 4).map(({ title, banner }, index) => (
                            <SwiperSlide key={`${index}-${title}`}>
                                <AnimatedPromoSlide
                                    isActive={active === index}
                                    pos={index}
                                    banner={banner}
                                    handleTitleClick={handleTitleClick}
                                />
                            </SwiperSlide>
                        ))}
                    </CustomSwiper>
                    <AnimatedPromoDotList
                        list={list.slice(0, 4)}
                        handleClick={handleClick}
                        active={active}
                        setActive={setActive}
                        swiperRef={swiperRef}
                    />
                </div>
            </div>
        </div>
    );
};

export default withBlockLinks(AnimatedPromo);
