import { MutableRefObject, useEffect, useState } from 'react';

import Feature from '@/domain/feature/Feature';

const useBeingInViewport = (element: MutableRefObject<Element | null>, rootMargin: number, needObserve?: boolean) => {
    const [isVisible, setState] = useState(false);

    useEffect(() => {
        if (!element?.current) return;
        if (!Feature.isFeatureEnabled('showAnimationWrap') && !needObserve) return;

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (!needObserve && entry.isIntersecting) {
                    setState(entry.isIntersecting);
                    if (element.current) observer.unobserve(element.current);
                } else {
                    setState(entry.isIntersecting);
                }
            },
            { rootMargin: `${rootMargin}px` }
        );

        observer.observe(element.current);
    }, []);

    return isVisible;
};

export default useBeingInViewport;
