import { useEffect } from 'react';

import { nextApiRequest } from '@/utils/request';

function usePerformanceObserver() {
    useEffect(() => {
        // сбор кастомных метрик
        try {
            // Создаем объект performance observer.
            const po = new PerformanceObserver(list => {
                // eslint-disable-next-line no-restricted-syntax
                for (const entry of list.getEntries()) {
                    if (entry.name === 'https://my.pochtabank.ru/sdk/v1/credit-issue.js') {
                        nextApiRequest
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            .get(`/cashloanMetrics?data=${entry}`)
                            .catch(e => console.log(e));
                    }
                    // Заносим в журнал все данные о времени сервера для этого ответа
                }
            });
            po.observe({ type: 'resource', buffered: true });
        } catch (e) {
            // Если браузер не поддерживает этот API, ничего не делаем.
        }
    }, []);
}

export default usePerformanceObserver;
