import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { FC, ReactNode, ReactNodeArray, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import { NextArrow, PrevArrow } from '@/components/blocks/TinyGradientCategory/Arrows';
import { ITinyGradientCategoryItem } from '@/components/blocks/TinyGradientCategory/types';
import BackOverlay from '@/components/modal/BackOverlay';
import { useFixHtml } from '@/hooks/useFixHtml';
import useOnClickOutside from '@/hooks/useOnClickOutside';

import cn from './style.module.sass';

interface IModalWrapper {
    children: ReactNode | ReactNodeArray;
    title?: string;
    trigger?: ReactNode;
    isOpen: boolean;
    handleClose?: () => void;
    handleOpen?: () => void;
    noToggles?: boolean;
    className?: string;
    bgImage?: string;
    bgClassName?: string;
    wrapperClassName?: string;
    clickOutsideExceptionsId?: string[];
    isModalContentCenterNeeded?: boolean;
    isStandartModalWidth?: boolean;
    isClosedOverlay?: boolean;
    whiteCross?: boolean;
    swiperRef?: React.RefObject<{ swiper: { slidePrev: () => void; slideNext: () => void } }>;
    activeSlideIndex?: number;
    list?: ITinyGradientCategoryItem[];
    crossIconClassName?: string;
    addToLocalStorage?: (index: number) => void;
}

interface IProps {
    isOpen: boolean;
    closeModal: () => void;
    title?: string;
    children: ReactNode | ReactNodeArray;
    noToggles?: boolean;
    className?: string;
    bgClassName?: string;
    wrapperClassName?: string;
    clickOutsideExceptionsId?: string[];
    isModalContentCenterNeeded?: boolean;
    isStandartModalWidth?: boolean;
    isClosedOverlay?: boolean;
    whiteCross?: boolean;
    swiperRef?: React.RefObject<{ swiper: { slidePrev: () => void; slideNext: () => void } }>;
    activeSlideIndex?: number;
    list?: ITinyGradientCategoryItem[];
    crossIconClassName?: string;
}

const Modal: FC<IProps> = ({
    children,
    isOpen,
    closeModal,
    title,
    noToggles,
    className,
    bgClassName,
    wrapperClassName,
    clickOutsideExceptionsId,
    isModalContentCenterNeeded = false,
    isStandartModalWidth = true,
    isClosedOverlay = true,
    whiteCross = false,
    swiperRef,
    activeSlideIndex,
    list,
    crossIconClassName,
}) => {
    const modalRef = useRef(null);

    const closeOverlay = () => {
        if (!isClosedOverlay) {
            return null;
        }
        return closeModal();
    };
    useOnClickOutside(modalRef, closeOverlay, clickOutsideExceptionsId);

    return (
        <>
            <CSSTransition
                mountOnEnter
                unmountOnExit
                in={isOpen}
                timeout={300}
                classNames={{
                    enterActive: cn.fadeIn,
                    exitActive: cn.fadeOut,
                }}
            >
                <div className={clsx(cn.wrapper, wrapperClassName)} id="modalWrapper">
                    <table className={clsx(cn.outer, isStandartModalWidth && cn.outerWidth)}>
                        {swiperRef && (
                            <PrevArrow swiperRef={swiperRef} isModal disabled={activeSlideIndex === 0} isModalArrow />
                        )}
                        {swiperRef && (
                            <NextArrow
                                swiperRef={swiperRef}
                                isModal
                                disabled={activeSlideIndex === list.length - 1}
                                isModalArrow
                            />
                        )}
                        <tbody>
                            <tr>
                                <td>
                                    <div className={clsx(cn.modalInner, className)} ref={modalRef}>
                                        {!noToggles && (
                                            <div
                                                className={clsx(cn.toggles, !title && cn.togglesRight, 'modalToggles')}
                                            >
                                                {title && (
                                                    <div
                                                        className={cn.title}
                                                        dangerouslySetInnerHTML={{
                                                            __html: title,
                                                        }}
                                                    />
                                                )}
                                                <div
                                                    className={clsx(
                                                        cn.crossIcon,
                                                        'crossIcon',
                                                        crossIconClassName && crossIconClassName
                                                    )}
                                                    onClick={closeModal}
                                                    role="presentation"
                                                >
                                                    <span
                                                        className={clsx(cn.cross, whiteCross && cn.whiteCross, 'cross')}
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        <div
                                            className={clsx(cn.content, isModalContentCenterNeeded && cn.center)}
                                            style={crossIconClassName && { paddingBottom: 0 }}
                                        >
                                            {children}
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </CSSTransition>
            <BackOverlay showOverlay={isOpen} closeOverlay={closeModal} className={bgClassName} />
        </>
    );
};

const ModalWrapper: FC<IModalWrapper> = observer(props => {
    const [node, setNode] = useState<Element>(null);
    const { trigger, isOpen, handleClose, handleOpen } = props;
    const { fix, unfix } = useFixHtml();

    const fixModal = () => {
        if (isOpen) {
            fix();
            return;
        }
        unfix();
    };

    useEffect(() => {
        setNode(document.querySelector('div#wrapper'));
    }, []);

    useEffect(() => {
        fixModal();
        return () => {
            unfix();
        };
    }, [isOpen]);
    return (
        <>
            {trigger && (
                <div onClick={handleOpen} role="presentation">
                    {trigger}
                </div>
            )}
            {node && ReactDOM.createPortal(<Modal {...props} isOpen={isOpen} closeModal={handleClose} />, node)}
        </>
    );
});

export default ModalWrapper;
